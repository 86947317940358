import React, { useEffect, useState, useRef, useContext } from "react";
import {
    Row,
    Col,
    Card,
    Form,
    Label,
    Input,
    Modal,
    Table,
    Button,
    NavLink,
    NavItem,
    TabPane,
    CardBody,
    FormGroup,
    Container,
    TabContent,
    ModalFooter,
    FormFeedback,
} from "reactstrap";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import classnames from 'classnames';
import FaceDetect from "./faceDetect";
import ReactSelect from 'react-select';
import ProfileImage from "./ProfileImage";
import RenderStatusIcon from "./RenderStatus";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../components/Common/Loader";
import { ToastContainer, toast } from "react-toastify";
import profile from "../../../images/profile_dummy.png";
import withRouter from "../../components/Common/withRouter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProfileContext } from "../../../js/common/data/context";
import { fetchUserData } from "../../common/data/commonFunction";
import RequiredAsterisk from "../../components/Common/RequiredAsterisk";
import { deleteApiData, getApiData, postApiData, putApiData } from "../../helpers/axiosHelper";
import { faArrowRight, faArrowLeft, faDownload, faEye, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { formatDateToUserTimeZone } from "../../common/data/FormateDateTime";

const Profile = () => {

    document.title = "Profile | Crescent Exchange";

    const navigate = useNavigate();
    const [saving, setSaving] = useState(false);
    const [modal1, setModal1] = useState(false);
    const [activeTab, setactiveTab] = useState(1);
    const [loading, setLoading] = useState(false);
    const [fileData, setFileData] = useState(null);
    const [docFormat, setDocFormat] = useState('');
    const [commentText, setCommentText] = useState("");
    const [salaryRange, setSalaryRange] = useState([]);
    const [commentData, setCommentData] = useState([]);
    const [viewDocName, setViewDocName] = useState("");
    const [statusData, setStatusData] = useState(null);
    const [docIsValid, setDocIsValid] = useState(true);
    const [passedSteps, setPassedSteps] = useState([1]);
    const [viewDocument, setViewDocument] = useState("");
    const [viewResponse, setViewResponse] = useState([]);
    const [nationalData, setNationalData] = useState([]);
    const [addressData, setAddressData] = useState(null);
    const { setProfileData } = useContext(ProfileContext);
    const [isAddingDoc, setIsAddingDoc] = useState(false);
    const [personalData, setPersonaldata] = useState(null);
    const [financialData, setFinancialData] = useState(null);
    const [modal_standard, setmodal_standard] = useState(false);

    const toggleViewModal = () => setModal1(!modal1);


    // For Web Cam Model

    const fileInputRef = useRef(null);

    const [modalOpen, setModalOpen] = useState(false);
    const [modal2Open, setModal2Open] = useState(false);

    const [showButtons, setShowButtons] = useState(false);
    const [profileImage, setProfileImage] = useState(null);
    const [capturedImage, setCapturedImage] = useState(null);

    const toggleModal = () => setModalOpen(!modalOpen);

    const toggle2Modal = () => setModal2Open(!modal2Open);

    const handleImageUpload = async (event) => {
        setLoading(true);
        if (!event.target.files || event.target.files.length === 0) {
            return;
        }

        const file = event.target.files[0];
        const allowedExtensions = /(\.jpg|\.png)$/i;

        if (!allowedExtensions.exec(file.name)) {
            alert('Please upload a JPG or PNG image.');
            event.target.value = null;
            return;
        }

        const reader = new FileReader();
        reader.onload = async () => {
            const capturedImageData = reader.result;
            setCapturedImage(capturedImageData);

            let normalImage = capturedImageData.split(',')[1];
            let grayScaleImage = (await convertToGrayscale(capturedImageData)).split(',')[1];
            const formData = new FormData();
            formData.append('normalImage', normalImage);
            formData.append('grayScaleImage', grayScaleImage);

            if (profileImage === null) {
                const response = await postApiData('api/ProfileImage/CreateProfileImage', formData);
                if (response.success) {
                    toast.success("Profile Image Added Successfully", {
                        position: "top-right",
                        autoClose: 3000,
                    });
                    setCapturedImage(null);
                    setShowButtons(false);
                    getProfileImage();
                    setLoading(false);
                } else {
                    toast.error("Error occurred while creating image.", {
                        position: "top-right",
                        autoClose: 3000,
                    });
                    setLoading(false);
                }
            } else {
                const response = await putApiData('api/ProfileImage/UpdateImage', formData);
                if (response.success) {
                    toast.success("Profile Image Updated Successfully", {
                        position: "top-right",
                        autoClose: 3000,
                    });
                    setCapturedImage(null);
                    setShowButtons(false);
                    getProfileImage();
                    setLoading(false);
                } else {
                    toast.error("Error occurred while updating image.", {
                        position: "top-right",
                        autoClose: 3000,
                    });
                    setLoading(false);
                }
            }
        };

        reader.readAsDataURL(file);
    };

    const convertToGrayscale = (imageData) => {
        return new Promise((resolve) => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            const image = new Image();
            image.src = imageData;

            image.onload = () => {
                canvas.width = image.width;
                canvas.height = image.height;
                context.drawImage(image, 0, 0);

                const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
                const data = imageData.data;

                for (let i = 0; i < data.length; i += 4) {
                    const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
                    data[i] = avg;
                    data[i + 1] = avg;
                    data[i + 2] = avg;
                }

                context.putImageData(imageData, 0, 0);
                const grayscaleImageData = canvas.toDataURL('image/png');
                resolve(grayscaleImageData);
            };
        });
    };

    const mapNationality = (nationality) => {
        if (!nationality) return [];
        return nationality.map((e) => ({
            label: e.nation,
            value: e.id
        }))
    }

    const getProfileImage = async () => {
        try {
            const imageResponse = await getApiData('api/ProfileImage/GetProfileImage');
            const image = await imageResponse.data;
            if (image === null) {
                setProfileImage(null);
            } else {
                setProfileImage(image);
            }
        } catch (error) {
            console.error("Error occurred while fetching data.", {
                position: "top-right",
                autoClose: 3000,
            });
        }
    }

    const getProfileComment = async () => {
        try {
            const commentResponse = await getApiData('api/ProfileComments/GetProfileComments');
            const comments = await commentResponse.data;

            setCommentData(comments);
        } catch (error) {
            console.error("Error occurred while fetching Commment data.", {
                position: "top-right",
                autoClose: 3000,
            });
        }

    }

    const fetchAddressData = async () => {
        const addressResponse = await getApiData('api/Address/GetAddressByUId');
        const address = await addressResponse;
        setAddressData(address);
        console.log(address);
    }

    const fetchNationalityData = async () => {
        const nationalResponse = await getApiData('api/Address/GetAllNationality');
        const national = await nationalResponse
        setNationalData(mapNationality(national));

    }

    const fetchDocumentData = async () => {
        const documentResponse = await getApiData('api/CustomerDoc/GetAllCustDocByCustomerId');
        const document = await documentResponse;
        setViewResponse(document);
        console.log(document)
    }

    const fetchFinancialData = async () => {
        const financialResponse = await getApiData('api/CustomerFinancial/GetAccountByUId');
        const financial = await financialResponse;
        setFinancialData(financial);
        console.log(financial);
    }

    const fetchSalaryrangeData = async () => {
        const salaryRangeResponse = await getApiData('api/SalaryRange/GetAllSalaryRange');
        const salaryRange = await salaryRangeResponse;
        setSalaryRange(salaryRange);

    }

    const fetchData = async () => {
        try {
            setLoading(true)
            //Personal Data
            const personal = await fetchUserData();
            setProfileData(personal);
            setStatusData(personal?.status);
            setPersonaldata(personal);

            fetchAddressData();

            fetchNationalityData();

            fetchDocumentData();

            fetchFinancialData();

            fetchSalaryrangeData();

            getProfileImage();

            getProfileComment();

            setLoading(false)
        } catch (error) {
            console.error("Error occurred while fetching data.", error);

        }
    };

    const handleCommentSubmit = async () => {
        if (commentText.trim() === '') {
            toast.error("Comment cannot be empty.", {
                position: "top-right",
                autoClose: 3000,
            });
        } else {
            try {
                const response = await postApiData('api/ProfileComments', commentText);
                if (response.success === true) {
                    setCommentText('');
                    getProfileComment();
                } else {
                    toast.error("Error occurred while adding comment.", {
                        position: "top-right",
                        autoClose: 3000,
                    });
                }
            } catch (error) {
                console.error("Error occurred while adding comment.", {
                    position: "top-right",
                    autoClose: 3000,
                });
            }
        }
    };
    function toggleTab(tab) {
        if (activeTab !== tab) {
            var modifiedSteps = [...passedSteps, tab]
            if (tab >= 1 && tab <= 5) {
                setactiveTab(tab)
                setPassedSteps(modifiedSteps)
            }
        }
    }

    function tog_standard() {
        setmodal_standard(!modal_standard);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    function handleSaveChange(e) {

        e.preventDefault();
        if (activeTab === 1) {
            personalvalidation.handleSubmit()
        }
        else if (activeTab === 2) {
            adrValidation.handleSubmit();
        } else if (activeTab === 3) {
            return;
        } else if (activeTab === 4) {
            financialValidation.handleSubmit();
        }

    }

    const personalvalidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstname: personalData?.firstName || '',
            middlename: personalData?.middleName || '',
            lastname: personalData?.lastName || '',
            email: personalData?.email || '',
            mobile: personalData?.mobile || '',
            dob: personalData?.dob?.split("T")[0] || null,
        },
        validationSchema: Yup.object({
            firstname: Yup.string()
                .max(50, "Max character's reached.")
                .min(3, "At least 3 letters required.")
                .required("Please Enter Your First name")
                .matches(/^[a-zA-Z\s]*$/, "Numbers cannot be in the Name"),
            middlename: Yup.string()
                .max(50, "Max character's reached.")
                .min(3, "At least 3 letters required.")
                .matches(/^[a-zA-Z\s]*$/, "Numbers cannot be in the Name"),
            lastname: Yup.string()
                .max(50, "Max character's reached.")
                .min(3, "At least 3 letters required.")
                .required("Please Enter Your Last name")
                .matches(/^[a-zA-Z\s]*$/, "Numbers cannot be in the Name"),
            email: Yup.string()
                .email("Invalid Email address.")
                .required("Please Enter Your Email"),
            mobile: Yup.string()
                .required("Please Enter Your Mobile Number")
                .matches(/^[0-9]*$/, 'Please enter only numbers in the Mobile field')
                .matches(/^\+?\d{8,14}$/, "Mobile Number must be within 8 - 14 digits"),
        }),

        onSubmit: async (values) => {
            setSaving(true);

            if (personalData !== null && personalData.uid >= 1) {
                const combinedValues = { ...values, uid: personalData.uid };
                const hasChanges = Object.keys(values).some(key => values[key] !== personalvalidation.initialValues[key]);

                if (hasChanges) {
                    if (values.email !== personalvalidation.initialValues.email) {
                        const emailConfirmation = await Swal.fire({
                            title: "Are you sure?",
                            text: "You are about to change your verified email. Do you want to proceed?",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonText: "Yes, I am sure",
                            cancelButtonText: "No",
                            dangerMode: true,
                        });

                        if (!emailConfirmation.isConfirmed) {
                            personalvalidation.setFieldValue("email", personalvalidation.initialValues.email);
                            setSaving(false);
                            return;
                        }
                    }

                    if (statusData === "4" && (values.firstname !== personalvalidation.initialValues.firstname ||
                        values.middlename !== personalvalidation.initialValues.middlename ||
                        values.lastname !== personalvalidation.initialValues.lastname)) {

                        const nameConfirmation = await Swal.fire({
                            title: "Are you sure?",
                            text: "Changing your name will change your status. Are you sure you want to proceed?",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonText: "Yes, I am sure",
                            cancelButtonText: "Close",
                            dangerMode: true,
                        });

                        if (!nameConfirmation.isConfirmed) {
                            personalvalidation.setValues(personalvalidation.initialValues);
                            setSaving(false);
                            return;
                        }
                    }

                    updateProfile(combinedValues);

                } else {
                    toast.info("No Changes to Update", {
                        position: "top-right",
                        autoClose: 3000,
                        onClose: () => setSaving(false)
                    });
                }
            } else {
                toast.error("Something went wrong, please try again later.", {
                    position: "top-right",
                    autoClose: 3000,
                    onClose: () => setSaving(false),
                });
                personalvalidation.setValues(personalvalidation.initialValues);
            }
        },
    });

    const updateProfile = async (values) => {
        const updatedResponse = await putApiData(`api/User/UpdateCustomerProfile`, values);

        if (updatedResponse.success) {
            setProfileData(updatedResponse.data);

            if (values.email !== personalvalidation.initialValues.email) {
                toast.success("Email changed successfully. Redirecting to verification...", {
                    position: "top-right",
                    autoClose: 3000,
                    onClose: () => {
                        sessionStorage.removeItem("custUser");

                        navigate(`/emailverification/${personalData?.uid}`, { replace: true });
                    },
                });
            } else {
                toast.success("Profile info updated successfully", {
                    position: "top-right",
                    autoClose: 3000,
                    onClose: () => setSaving(false),
                });
                toggleTab(activeTab + 1);
            }
        } else {
            toast.error(updatedResponse.message || "Error saving Profile info.", {
                position: "top-right",
                autoClose: 3000,
                onClose: () => setSaving(false),
            });
            personalvalidation.setValues(personalvalidation.initialValues);
        }
    };


    const adrValidation = useFormik({

        enableReinitialize: true,

        initialValues: {
            street: addressData?.street || '',
            city: addressData?.city || '',
            state: addressData?.state || '',
            country: addressData?.country || '',
            postalcode: addressData?.postalCode || '',
            nationality: (nationalData?.find(nation => nation.value === parseInt(addressData?.nationality))) || null,
        },

        validationSchema: Yup.object({
            street: Yup.string()
                .max(50, "Max character's reached.")
                .min(3, "Atleast 3 letter's required.")
                .required("Please Enter Your Street"),
            city: Yup.string()
                .max(50, "Max character's reached.")
                .min(1, "Atleast 2 letter's required.")
                .matches(/^[a-zA-Z\s]*$/, "Please enter only letters in the city field")
                .required("Please Enter Your City"),
            state: Yup.string()
                .max(50, "Max character's reached.")
                .min(2, "Atleast 2 letter's required.")
                .matches(/^[a-zA-Z\s]*$/, "Please enter only letters in the state field")
                .required("Please Enter Your State"),
            country: Yup.string()
                .max(50, "Max character's reached.")
                .min(3, "Atleast 3 letter's required.")
                .matches(/^[a-zA-Z\s]*$/, "Please enter only letters in the state field")
                .required("Please Enter Your Country"),
            postalcode: Yup.string()
                .max(10, 'Postal code cannot more than 10 characters.')
                .min(4, 'Postal code cannot be lower than 4 characters.')
                .required("Please enter your PIN code."),
            nationality: Yup.object().shape({
                label: Yup.string().required("Please Select Nationality"),
                value: Yup.string().required("Please Select Nationality"),
            })
                .required("Please Select you're nationality."),
        }),

        onSubmit: async (values) => {
            const nationalityValue = values.nationality && values.nationality.value

            if (addressData !== null && addressData.addressId > 1) {
                const combinedValues = { ...values, addressId: addressData.addressId, nationality: nationalityValue }
                const hasChanges = Object.keys(values).some(key => values[key] !== adrValidation.initialValues[key]);
                if (hasChanges) {
                    handleAddressUpdate(combinedValues);
                } else {
                    setSaving(true);
                    toast.info("No Changes to update.", {
                        position: "top-right",
                        autoClose: 3000,
                        onClose: () => {
                            setSaving(false);
                        }
                    });
                }
            } else {
                handleAddressCreate({ ...values, nationality: nationalityValue });
                fetchAddressData();
            }
        }

    });

    const handleAddressUpdate = async (values) => {

        if (statusData === "2") {
            const updatedResponse = await putApiData(`api/Address/UpdateAddress?AddressID=${addressData.addressId}`, values);
            if (updatedResponse.success === true) {
                setSaving(true);
                toast.success("Address Updated Successfully", {
                    position: "top-right",
                    autoClose: 3000,
                    onClose: () => {
                        setSaving(false);
                    }
                });
                toggleTab(activeTab + 1);
            } else {
                setSaving(true);
                toast.error("Error saving Address info.", {
                    position: "top-right",
                    autoClose: 3000,
                    onClose: () => {
                        setSaving(false);
                    }
                });
                adrValidation.setValues(adrValidation.initialValues);
            }
        } else if (statusData === "4") {
            toast.error("Verfied cannot change address", {
                position: "top-right",
                autoClose: 3000,
            });
            adrValidation.setValues(adrValidation.initialValues);
        } else {
            toast.error("Invalid Status. Cannot Update", {
                position: "top-right",
                autoClose: 3000,
            });
            adrValidation.setValues(adrValidation.initialValues);
        }

    }

    const handleAddressCreate = async (values) => {

        const response = await postApiData("api/Address/CreateAddress", JSON.stringify(values));
        if (response.success === true) {
            setSaving(true);
            toast.success("Address Added Successfully", {
                position: "top-right",
                autoClose: 3000,
                onClose: () => {
                    setSaving(false);
                }
            });

            fetchAddressData();
            toggleTab(activeTab + 1);
        } else {
            setSaving(true);
            toast.error("Error Adding address. Contact Admin", {
                position: "top-right",
                autoClose: 3000,
                onclose: () => {
                    setSaving(false);
                }
            });
            adrValidation.setValues(adrValidation.initialValues);
        }
    }

    const docValidation = useFormik({

        enableReinitialize: true,

        initialValues: {
            documentType: '',
            documentName: '',
            document: null,
            documentFormat: null,
        },

        validationSchema: Yup.object({
            documentType: Yup.string().required("Please Select the Document Type"),
            documentName: Yup.string().required("Please Enter the Document Name"),
            document: Yup.string().required("Please Upload the Document"),
        }),

        onSubmit: async (values) => {

            if (statusData === "2") {
                setSaving(true);
                addDocument(values);
                setSaving(false);
            } else {
                setSaving(true);
                toast.error("Not valid status to add doocument.", {
                    position: "top-right",
                    autoClose: 3000,
                    onClose: () => {
                        setSaving(false);
                    }
                });
            }

        }

    });

    const addDocument = async (values) => {

        setIsAddingDoc(true);
        if (viewResponse.length <= 5) {
            if (docIsValid) {
                const combinedValues = { ...values, document: fileData, documentFormat: docFormat }
                const response = await postApiData("api/CustomerDoc/InsertCustomerDoc", combinedValues);
                if (response.success === true) {
                    toast.success("Document Added Successfully", {
                        position: "top-right",
                        autoClose: 3000,
                    });
                    fetchDocumentData();
                    setIsAddingDoc(false);
                } else {
                    toast.error("Error Uploading Doc. Contact Admin", {
                        position: "top-right",
                        autoClose: 3000,
                    });
                    setIsAddingDoc(false);
                }

            } else {
                toast.warning("File Size Should be less than 5MB", {
                    position: "top-right",
                    autoClose: 3000,
                })
                setIsAddingDoc(false);
            }

        } else {
            toast.info("Max Doc space has been reached.", {
                position: "top-right",
                autoClose: 3000,
            });
            setIsAddingDoc(false);
        }
        docValidation.resetForm();
        tog_standard();

    }

    function handleFileChange(e) {
        setDocIsValid(true);
        const file = e.target.files[0];
        if (!file) return;
        if (file.size > 5000 * 1024) {
            toast.error("File Size Should be less than 5MB", {
                position: "top-right",
                autoClose: 3000
            });
            docValidation.resetForm();
            tog_standard();
            setDocIsValid(false);
            return;
        }
        const reader = new FileReader();
        reader.onloadend = () => {
            const type = reader.result.split(";")[0];
            const docType = type.split("/")[1];
            let base64String = '';
            const indexOfComma = reader.result.indexOf(',');
            if (indexOfComma !== -1) {
                base64String = reader.result.substring(indexOfComma + 1);
            }
            setDocFormat(docType)
            setFileData(base64String)
        };
        reader.readAsDataURL(file);
    }

    const getFileType = (documentFormat) => {
        switch (documentFormat) {
            case 'png':
                return 'image/png';
            case 'jpg':
                return 'image/jpg';
            case 'jpeg':
                return 'image/jpeg';
            case 'pdf':
                return 'application/pdf';
            case 'docx':
                return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            default:
                return 'application/octet-stream';
        }
    };

    const viewDoc = (index) => {
        const data = viewResponse[index].document;
        const name = viewResponse[index].documentName;
        const documentFormat = viewResponse[index].documentFormat;

        setViewDocument(data);
        setViewDocName(name);

        const imageTypes = ['png', 'jpg', 'jpeg'];

        if (imageTypes.includes(documentFormat)) {
            toggleViewModal();
        } else {
            handleDownloadFile(index);
        }
    };

    const handleDownloadFile = (index) => {
        const data = viewResponse[index];
        const byteChars = atob(data.document);
        const byteNumbers = new Array(byteChars.length);
        for (let i = 0; i < byteChars.length; i++) {
            byteNumbers[i] = byteChars.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: getFileType(data.documentFormat) });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${data.documentName}.${data.documentFormat !== null ? data.documentFormat : "png"}`;
        link.click();
        URL.revokeObjectURL(url);
    };

    const deleteDoc = async (index) => {
        const docId = viewResponse[index].docId;
        const response = await deleteApiData(`api/CustomerDoc/DeleteCustDoc?CustDocID=${docId}`);
        if (response.success === true) {
            toast.success("Document Deleted Successfully", {
                position: "top-right",
                autoClose: 3000,
            });
            fetchDocumentData();
        } else {
            toast.error("Error Deleting Document. Contact Admin", {
                position: "top-right",
                autoClose: 3000,
            });
        }
    };

    function getDocumentType(obj) {
        if (obj === "I") {
            return "Identity Proof"
        } else if (obj === "A") {
            return "Address Proof"
        } else if (obj === "O") {
            return "Others"
        } else {
            return ""
        }
    }

    const financialValidation = useFormik({

        enableReinitialize: true,

        initialValues: {
            profession: financialData?.profession || '',
            AnnualSalaryRangeId: financialData?.annualSalaryRangeId || '',
            networth: financialData?.netWorth || '',
            ExpectedTransAmtMth: financialData?.expectedTransAmtMth || '',
        },

        validationSchema: Yup.object({
            profession: Yup.string()
                .required('Please enter your profession name')
                .matches(/^[a-zA-Z\s]*$/, "Please enter only letters in the profession"),
            networth: Yup.string()
                .max(8, 'Too much value')
                .required("Please enter your Networth")
                .matches(/^[0-9]*$/, 'Please enter only numbers.'),
            ExpectedTransAmtMth: Yup.string()
                .matches(/^[0-9]*$/, 'Please enter only numbers.')
                .required("Please enter your Monthly Transaction Limit."),
            AnnualSalaryRangeId: Yup.string()
                .required("Please Select any one option"),

        }),


        onSubmit: async (values) => {
            if (financialData !== null && financialData?.custFinId) {
                const combinedValues = { ...values, custFinId: financialData.custFinId }
                const hasChanges = Object.keys(values).some(key => values[key] !== financialValidation.initialValues[key]);
                if (hasChanges) {
                    if (statusData === "2" || statusData === "4") {
                        setSaving(true);
                        updateFinancial(combinedValues);
                        setSaving(false);
                    } else if (statusData === "3") {
                        setSaving(true);
                        toast.warning("Cannot update financial when in this status.", {
                            position: "top-right",
                            autoClose: 3000,
                            onClose: () => {
                                setSaving(false);
                            }
                        });
                        financialValidation.setValues(financialValidation.initialValues);
                    } else {
                        setSaving(true);
                        toast.warning("Invalid Status can't make changes.", {
                            position: "top-right",
                            autoClose: 3000,
                            onClose: () => {
                                setSaving(false);
                            }
                        });
                        financialValidation.setValues(financialValidation.initialValues);
                    }
                } else {
                    setSaving(true);
                    toast.info("No Changes to Update", {
                        position: "top-right",
                        autoClose: 3000,
                        onClose: () => {
                            setSaving(false);
                        }
                    });
                }
            } else {
                setSaving(true);
                createFinancial(values);
                setSaving(false);
            }


        }


    });

    const createFinancial = async (values) => {
        const response = await postApiData("api/CustomerFinancial/CreateCustomerFinancial",
            JSON.stringify(values)
        );
        if (response.success === true) {
            setSaving(true);
            toast.success("Financial Detail's Added Successfully", {
                position: "top-right",
                autoClose: 3000,
                onClose: () => {
                    setSaving(false);
                }
            });
            fetchFinancialData();
            toggleTab(activeTab + 1);
        } else {
            setSaving(true);
            toast.error("Error Adding financial. Contact Admin", {
                position: "top-right",
                autoClose: 3000,
                onclose: () => {
                    setSaving(false);
                }
            });
            financialValidation.setValues(financialValidation.initialValues);
        }
    }

    const updateFinancial = async (values) => {
        const response = await putApiData(`api/CustomerFinancial/UpdateCustomerFinancial?CustFinID=${financialData.custFinId}`, JSON.stringify(values));
        if (response.success === true) {
            setSaving(true);
            toast.success("Financial Detail's Updated Successfully", {
                position: "top-right",
                autoClose: 3000,
                onClose: () => {
                    setSaving(false);
                }
            });
            toggleTab(activeTab + 1);
        } else {
            setSaving(true);
            toast.error("Error updating financial. Contact Admin", {
                position: "top-right",
                autoClose: 3000,
                onclose: () => {
                    setSaving(false);
                }
            });
            financialValidation.setValues(financialValidation.initialValues);
        }
    }

    const handleAllSubmit = async () => {
        setSaving(true);
        if (personalData && addressData && viewResponse && financialData != null) {
            if (statusData === "2") {
                if (profileImage.isRecognised === 1) {
                    try {
                        const response = await postApiData("api/User/SubmitProfileForApproval");
                        if (response.success === true) {
                            toast.success("Profile submitted for approval successfully", {
                                position: "top-right",
                                autoClose: 3000,
                                onclose: () => {
                                    setSaving(false);
                                }
                            });
                            fetchData();
                        } else {
                            setSaving(true);
                            toast.info(`Error occured ${response.message}`, {
                                position: "top-right",
                                autoClose: 3000,
                                onClose: () => {
                                    setSaving(false);
                                }
                            });
                        }
                    } catch (error) {
                        setSaving(true);
                        console.error("Error submitting profile for approval. Please try again later.", {
                            position: "top-right",
                            autoClose: 3000,
                            onClose: () => {
                                setSaving(false);
                            }
                        });
                    }
                } else {
                    setSaving(true);
                    toast.error("Profile Image isn't verified.", {
                        position: "top-right",
                        autoClose: 3000,
                        onClose: () => {
                            setSaving(false);
                        }
                    });
                }
            } else {
                setSaving(true);
                toast.error("Cannot get user status.", {
                    position: "top-right",
                    autoClose: 3000,
                    onClose: () => {
                        setSaving(false);
                    }
                });
            }

        } else {
            Swal.fire({
                text: "Missing required data. Please complete all 5 tabs before submitting.",
                icon: "warning",
                cancelButtonText: "Close",
                showConfirmButton: false,
                showCancelButton: true,
                confirmButtonColor: "#556ee6",
            }).then(() => {
                setSaving(false);
            });
        }

    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <React.Fragment>
            <ToastContainer closeButton={false} limit={1} />

            <div className="page-content">
                {loading ?
                    <Loader />
                    :
                    <>
                        <Container fluid>
                            <h3>
                                Profile<RenderStatusIcon statusData={statusData} loading={loading} />
                            </h3>

                            <Row>
                                <Col lg="12">
                                    <Card>
                                        <CardBody>
                                            <div className="wizard clearfix">
                                                <div className="steps clearfix">
                                                    <ul>
                                                        <NavItem
                                                            className={classnames({ current: activeTab === 1 })}
                                                        >
                                                            <NavLink
                                                                className={classnames({ current: activeTab === 1 })}
                                                                onClick={() => { setactiveTab(1) }}

                                                            >
                                                                <span className="number">1.</span> Personal Info
                                                                {personalData !== null ? (
                                                                    <i className="fa fa-check text-success ms-2" />
                                                                ) : (
                                                                    <i className="fa fa-times text-danger ms-2" />
                                                                )}
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem
                                                            className={classnames({ current: activeTab === 2 })}
                                                        >
                                                            <NavLink
                                                                className={classnames({ active: activeTab === 2 })}
                                                                onClick={() => { setactiveTab(2) }}

                                                            >
                                                                <span className="number">2.</span> Address
                                                                {addressData?.addressId ? (
                                                                    <i className="fa fa-check text-success ms-2" />
                                                                ) : (
                                                                    <i className="fa fa-times text-danger ms-2" />
                                                                )}
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem className={classnames({ current: activeTab === 3 })} >
                                                            <NavLink className={classnames({ active: activeTab === 3 })}
                                                                onClick={() => { setactiveTab(3) }}

                                                            >
                                                                <span className="number">3.</span> Document List
                                                                {viewResponse.length > 0 ? (
                                                                    <i className="fa fa-check text-success ms-2" />
                                                                ) : (
                                                                    <i className="fa fa-times text-danger ms-2" />
                                                                )}
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem className={classnames({ current: activeTab === 4 })}>
                                                            <NavLink className={classnames({ active: activeTab === 4 })}
                                                                onClick={() => { setactiveTab(4) }}
                                                            >
                                                                <span className="number">4.</span>Financial Details
                                                                {financialData !== null && financialData?.custFinId ? (
                                                                    <i className="fa fa-check text-success ms-2" />
                                                                ) : (
                                                                    <i className="fa fa-times text-danger ms-2" />
                                                                )}

                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem className={classnames({ current: activeTab === 5 })}>
                                                            <NavLink className={classnames({ active: activeTab === 5 })}
                                                                onClick={() => { setactiveTab(5) }}
                                                            >
                                                                <span className="number">5.</span>Profile Picture
                                                                {profileImage !== null && profileImage.isRecognised > 0 ? (
                                                                    <i className="fa fa-check text-success ms-2" />
                                                                ) : (
                                                                    <i className="fa fa-times text-danger ms-2" />
                                                                )}

                                                            </NavLink>
                                                        </NavItem>
                                                    </ul>
                                                </div>
                                                <div className="content clearfix">
                                                    <TabContent activeTab={activeTab} className="body">

                                                        <TabPane tabId={1} className="mb-1">

                                                            <Form className="needs-validation"
                                                                onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    personalvalidation.handleSubmit(e);
                                                                    return false;
                                                                }}>
                                                                <Row>
                                                                    <Col lg="6">
                                                                        <div className="mb-3">
                                                                            <Label for="firstname">First Name</Label>
                                                                            <RequiredAsterisk />
                                                                            <Input
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="firstname"
                                                                                placeholder="First Name"
                                                                                onChange={personalvalidation.handleChange}
                                                                                onBlur={personalvalidation.handleBlur}
                                                                                value={personalvalidation.values.firstname || ""}
                                                                                invalid={
                                                                                    personalvalidation.touched.firstname && personalvalidation.errors.firstname ? true : false
                                                                                } />
                                                                            {personalvalidation.touched.firstname && personalvalidation.errors.firstname ? (
                                                                                <FormFeedback type="invalid">{personalvalidation.errors.firstname}</FormFeedback>
                                                                            ) : null}
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg="6">
                                                                        <div className="mb-3">
                                                                            <Label for="state">Middle Name</Label>

                                                                            <Input
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="middlename"
                                                                                placeholder="Middle Name "
                                                                                onChange={personalvalidation.handleChange}
                                                                                onBlur={personalvalidation.handleBlur}
                                                                                value={personalvalidation.values.middlename || ""}
                                                                                invalid={
                                                                                    personalvalidation.touched.middlename && personalvalidation.errors.middlename ? true : false
                                                                                }
                                                                            />
                                                                            {personalvalidation.touched.middlename && personalvalidation.errors.middlename ? (
                                                                                <FormFeedback type="invalid">{personalvalidation.errors.middlename}</FormFeedback>
                                                                            ) : null}
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg="6">
                                                                        <div className="mb-3">
                                                                            <Label for="city">Last Name</Label>
                                                                            <RequiredAsterisk />
                                                                            <Input
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="lastname"
                                                                                placeholder="Last Name"
                                                                                onChange={personalvalidation.handleChange}
                                                                                onBlur={personalvalidation.handleBlur}
                                                                                value={personalvalidation.values.lastname || ""}
                                                                                invalid={personalvalidation.touched.lastname && personalvalidation.errors.lastname ? true : false} />
                                                                            {personalvalidation.touched.lastname && personalvalidation.errors.lastname ? (
                                                                                <FormFeedback type="invalid">{personalvalidation.errors.lastname}</FormFeedback>
                                                                            ) : null}
                                                                        </div>
                                                                    </Col>

                                                                    <Col lg="6">
                                                                        <div className="mb-3">
                                                                            <Label for="dob">Date Of Birth</Label>
                                                                            <RequiredAsterisk />
                                                                            <Input
                                                                                id="dob"
                                                                                type="date"
                                                                                onBlur={personalvalidation.handleBlur}
                                                                                onChange={personalvalidation.handleChange}
                                                                                value={personalvalidation.values.dob || null}
                                                                                invalid={
                                                                                    personalvalidation.touched.dob && personalvalidation.errors.dob ? true : false
                                                                                }
                                                                            />
                                                                            <FormFeedback type="invalid">{personalvalidation.errors.dob}</FormFeedback>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg="6">
                                                                        <div className="mb-3">
                                                                            <Label for="country">Email</Label>
                                                                            <RequiredAsterisk />
                                                                            <Input
                                                                                type="email"
                                                                                className="form-control"
                                                                                id="email"
                                                                                placeholder="Enter Email"
                                                                                onChange={personalvalidation.handleChange}
                                                                                onBlur={personalvalidation.handleBlur}
                                                                                value={personalvalidation.values.email || ""}
                                                                                invalid={
                                                                                    personalvalidation.touched.email && personalvalidation.errors.email ? true : false
                                                                                }
                                                                            />
                                                                            {personalvalidation.touched.email && personalvalidation.errors.email ? (
                                                                                <FormFeedback type="invalid">{personalvalidation.errors.email}</FormFeedback>
                                                                            ) : null}
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg="6">
                                                                        <div className="mb-3">
                                                                            <Label for="postalcode">Mobile</Label>
                                                                            <RequiredAsterisk />
                                                                            <Input
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="mobile"
                                                                                placeholder="Enter Mobile"
                                                                                onChange={personalvalidation.handleChange}
                                                                                onBlur={personalvalidation.handleBlur}
                                                                                value={personalvalidation.values.mobile || ""}
                                                                                invalid={
                                                                                    personalvalidation.touched.mobile && personalvalidation.errors.mobile ? true : false
                                                                                }
                                                                            />
                                                                            {personalvalidation.touched.mobile && personalvalidation.errors.mobile ? (
                                                                                <FormFeedback type="invalid">{personalvalidation.errors.mobile}</FormFeedback>
                                                                            ) : null}
                                                                        </div>
                                                                    </Col>

                                                                </Row>
                                                            </Form>

                                                        </TabPane>

                                                        <TabPane tabId={2}>

                                                            <Form className="needs-validation"
                                                                onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    adrValidation.handleSubmit();
                                                                    return false;
                                                                }}>

                                                                <Row>
                                                                    <Col lg="6">
                                                                        <div className="mb-3">
                                                                            <Label for="street">Street</Label>
                                                                            <RequiredAsterisk />
                                                                            <Input
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="street"
                                                                                placeholder="Enter street"
                                                                                onChange={adrValidation.handleChange}
                                                                                onBlur={adrValidation.handleBlur}
                                                                                value={adrValidation.values.street || ""}
                                                                                invalid={adrValidation.touched.street && adrValidation.errors.street ? true : false
                                                                                }
                                                                            />
                                                                            {adrValidation.touched.street && adrValidation.errors.street ? (
                                                                                <FormFeedback type="invalid">{adrValidation.errors.street}</FormFeedback>
                                                                            ) : null}
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg="6">
                                                                        <div className="mb-3">
                                                                            <Label for="city">City</Label>
                                                                            <RequiredAsterisk />
                                                                            <Input
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="city"
                                                                                placeholder="Enter City"
                                                                                onChange={adrValidation.handleChange}
                                                                                onBlur={adrValidation.handleBlur}
                                                                                value={adrValidation.values.city || ""}
                                                                                invalid={
                                                                                    adrValidation.touched.city && adrValidation.errors.city ? true : false
                                                                                }
                                                                            />
                                                                            {adrValidation.touched.city && adrValidation.errors.city ? (
                                                                                <FormFeedback type="invalid">{adrValidation.errors.city}</FormFeedback>
                                                                            ) : null}
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg="6">
                                                                        <div className="mb-3">
                                                                            <Label for="state">State</Label>
                                                                            <RequiredAsterisk />
                                                                            <Input
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="state"
                                                                                placeholder="Enter State"
                                                                                onChange={adrValidation.handleChange}
                                                                                onBlur={adrValidation.handleBlur}
                                                                                value={adrValidation.values.state || ""}
                                                                                invalid={
                                                                                    adrValidation.touched.state && adrValidation.errors.state ? true : false
                                                                                }
                                                                            />
                                                                            {adrValidation.touched.state && adrValidation.errors.state ? (
                                                                                <FormFeedback type="invalid">{adrValidation.errors.state}</FormFeedback>
                                                                            ) : null}
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg="6">
                                                                        <div className="mb-3">
                                                                            <Label for="country">Country</Label>
                                                                            <RequiredAsterisk />
                                                                            <Input
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="country"
                                                                                placeholder="Enter Country"
                                                                                onChange={adrValidation.handleChange}
                                                                                onBlur={adrValidation.handleBlur}
                                                                                value={adrValidation.values.country || ""}
                                                                                invalid={
                                                                                    adrValidation.touched.country && adrValidation.errors.country ? true : false
                                                                                }
                                                                            />
                                                                            {adrValidation.touched.country && adrValidation.errors.country ? (
                                                                                <FormFeedback type="invalid">{adrValidation.errors.country}</FormFeedback>
                                                                            ) : null}
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg="6">
                                                                        <div className="mb-3">
                                                                            <Label for="postalcode">Postal Code</Label>
                                                                            <RequiredAsterisk />
                                                                            <Input
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="postalcode"
                                                                                placeholder="Enter Postal Code"
                                                                                onChange={adrValidation.handleChange}
                                                                                onBlur={adrValidation.handleBlur}
                                                                                value={adrValidation.values.postalcode || ""}
                                                                                invalid={
                                                                                    adrValidation.touched.postalcode && adrValidation.errors.postalcode ? true : false
                                                                                }
                                                                            />
                                                                            {adrValidation.touched.postalcode &&
                                                                                adrValidation.errors.postalcode ? (
                                                                                <FormFeedback type="invalid">{adrValidation.errors.postalcode}</FormFeedback>
                                                                            ) : null}
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg="6">
                                                                        <FormGroup className="mb-3">
                                                                            <Label for="nationality">Nationality</Label>
                                                                            <RequiredAsterisk />
                                                                            <ReactSelect
                                                                                name="nationality"
                                                                                placeholder="Select Nationality"
                                                                                type="text"
                                                                                id="nationality"
                                                                                options={nationalData}
                                                                                value={adrValidation.values.nationality || null}
                                                                                onChange={(selectedOption) => adrValidation.setFieldValue('nationality', selectedOption)}
                                                                                onBlur={adrValidation.handleBlur}
                                                                                invalid={
                                                                                    adrValidation.touched.nationality && adrValidation.errors.nationality ? true : false
                                                                                }
                                                                            />
                                                                            {
                                                                                adrValidation.errors.nationality && adrValidation.touched.nationality ? (
                                                                                    <span className="text-danger" style={{ "font-size": "80%" }}>{adrValidation.errors.nationality}</span>
                                                                                ) : null
                                                                            }
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            </Form>

                                                        </TabPane>

                                                        <TabPane tabId={3}>
                                                            <Col lg="12">
                                                                <div className="table-responsive mb-2 mt-0" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                                                    {viewResponse.length > 0 ? (
                                                                        <Table className="table table-striped table-bordered mt-0 mb-0">
                                                                            <thead className="table-light mt-0 mb-0">
                                                                                <tr>
                                                                                    <th>No</th>
                                                                                    <th>Document Type</th>
                                                                                    <th>Document Name</th>
                                                                                    <th>View Details</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {viewResponse.map((arr, index) => (
                                                                                    <tr key={index}>
                                                                                        <td>{index + 1}</td>
                                                                                        <td>{getDocumentType(arr.documentType)}</td>
                                                                                        <td>{arr.documentName}</td>
                                                                                        <td>
                                                                                            <Button
                                                                                                type="button"
                                                                                                color="primary"
                                                                                                className="btn-sm btn-rounded me-2"
                                                                                                onClick={() => viewDoc(index)}
                                                                                            >
                                                                                                <FontAwesomeIcon icon={faEye} />
                                                                                            </Button>
                                                                                            {statusData === "2" && (
                                                                                                <Button
                                                                                                    type="button"
                                                                                                    color="danger"
                                                                                                    className="btn-sm btn-rounded"
                                                                                                    onClick={() => deleteDoc(index)}
                                                                                                >
                                                                                                    <FontAwesomeIcon icon={faTrashAlt} />
                                                                                                </Button>
                                                                                            )}
                                                                                        </td>
                                                                                    </tr>
                                                                                ))
                                                                                }
                                                                            </tbody>
                                                                        </Table>
                                                                    ) : (
                                                                        <div className="text-muted d-flex justify-content-center align-items-center" style={{ minHeight: "100px" }}>
                                                                            <h3>No document to show</h3>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                {statusData === "2" && (
                                                                    <Button
                                                                        className="btn btn-success"
                                                                        data-toggle="modal"
                                                                        data-target="#myModal"
                                                                        disabled={viewResponse.length >= 5}
                                                                        onClick={() => {
                                                                            tog_standard();
                                                                        }}
                                                                    >
                                                                        {viewResponse.length >= 5
                                                                            ? "Max Limit"
                                                                            : "+ Add Document"
                                                                        }
                                                                    </Button>
                                                                )}
                                                            </Col>
                                                            {/* View Document Modal */}
                                                            <Modal
                                                                size="lg"
                                                                isOpen={modal1}
                                                                toggle={toggleViewModal}
                                                            >
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title" id="myModalLabel">
                                                                        {viewDocName}
                                                                    </h5>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => {
                                                                            setModal1(false);
                                                                        }}
                                                                        className="close"
                                                                        data-dismiss="modal"
                                                                        aria-label="Close">
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                                <Row>
                                                                    <Col>
                                                                        <Card>
                                                                            <CardBody>
                                                                                <img src={`data:image/png;base64,${viewDocument}`} alt="Document.png"
                                                                                    height="100%" width="100%" />
                                                                            </CardBody>
                                                                        </Card>
                                                                    </Col>
                                                                </Row>
                                                                <ModalFooter>
                                                                    <Button
                                                                        type="button"
                                                                        color="success"
                                                                        className="btn-sm btn-rounded mb-2 me-1"
                                                                        onClick={() => handleDownloadFile(viewResponse.findIndex(doc => doc.documentName === viewDocName))}
                                                                    >
                                                                        <FontAwesomeIcon icon={faDownload} /> Download
                                                                    </Button>
                                                                </ModalFooter>

                                                            </Modal>
                                                            {/* Add Document Modal */}
                                                            <Modal
                                                                isOpen={modal_standard}
                                                                toggle={() => { tog_standard() }}
                                                            >
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title mt-0" id="myModalLabel">
                                                                        Manage Documents
                                                                    </h5>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => {
                                                                            docValidation.resetForm();
                                                                            setmodal_standard(false);
                                                                            return false;
                                                                        }}
                                                                        className="close"
                                                                        data-dismiss="modal"
                                                                        aria-label="Close"
                                                                    >
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                                <Row>
                                                                    <Col lg={12}>
                                                                        <Card>
                                                                            <CardBody>
                                                                                <Row className="mb-4">
                                                                                    <Col sm={6}>
                                                                                        <div className="mt-3">
                                                                                            <Label for="documentType">Document Type</Label>
                                                                                            <div className="col-md-12">
                                                                                                <Input
                                                                                                    className="form-select"
                                                                                                    type="select"
                                                                                                    id="documentType"
                                                                                                    name="documentType"
                                                                                                    onChange={docValidation.handleChange}
                                                                                                    onBlur={docValidation.handleBlur}
                                                                                                    value={docValidation.values.documentType}
                                                                                                    invalid={docValidation.touched.documentType &&
                                                                                                        docValidation.errors.documentType ? true : false}
                                                                                                >
                                                                                                    <option value="" disabled hidden>Choose Type</option>
                                                                                                    <option value="I">ID Proof</option>
                                                                                                    <option value="A">Address Proof</option>
                                                                                                    <option value="O">Others</option>
                                                                                                </Input>
                                                                                                {docValidation.touched.documentType &&
                                                                                                    docValidation.errors.documentType ? (
                                                                                                    <FormFeedback type="invalid">{docValidation.errors.documentType}</FormFeedback>
                                                                                                ) : null}
                                                                                            </div>
                                                                                        </div>
                                                                                    </Col>
                                                                                    <Col sm={6}>
                                                                                        <div className="mt-3">
                                                                                            <Label for="documentName">Document Name</Label>
                                                                                            <div className="col-md-12">
                                                                                                <Input
                                                                                                    type="text"
                                                                                                    placeholder="Document Name"
                                                                                                    id="documentName"
                                                                                                    name="documentName"
                                                                                                    onChange={docValidation.handleChange}
                                                                                                    onBlur={docValidation.handleBlur}
                                                                                                    value={docValidation.values.documentName}
                                                                                                    invalid={docValidation.touched.documentName &&
                                                                                                        docValidation.errors.documentName ? true : false}
                                                                                                />
                                                                                                {docValidation.touched.documentName &&
                                                                                                    docValidation.errors.documentName ? (
                                                                                                    <FormFeedback type="invalid">{docValidation.errors.documentName}</FormFeedback>
                                                                                                ) : null}
                                                                                            </div>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col sm={12}>
                                                                                        <div className="mt-1">
                                                                                            <Label for="document">Select Document</Label>
                                                                                            <div className="col-md-12">
                                                                                                <Input
                                                                                                    type="file"
                                                                                                    id="document"
                                                                                                    aria-label="Upload"
                                                                                                    accept=".png, .jpg, .jpeg, .pdf"
                                                                                                    aria-describedby="inputGroupFileAddon04"
                                                                                                    onChange={(e) => {
                                                                                                        handleFileChange(e);
                                                                                                        docValidation.handleChange(e);
                                                                                                    }}
                                                                                                    onBlur={docValidation.handleBlur}
                                                                                                    invalid={
                                                                                                        docValidation.touched.document &&
                                                                                                            docValidation.errors.document
                                                                                                            ? true
                                                                                                            : false
                                                                                                    }
                                                                                                />
                                                                                                {docValidation.touched.document &&
                                                                                                    docValidation.errors.document ? (
                                                                                                    <FormFeedback type="invalid">{docValidation.errors.document}</FormFeedback>
                                                                                                ) : null}
                                                                                            </div>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </CardBody>
                                                                        </Card>
                                                                    </Col>
                                                                </Row>
                                                                <div className="bg-grey mt-0 mb-4 me-3 text-end">
                                                                    <Button
                                                                        className="btn btn-primary"
                                                                        color="primary"
                                                                        disabled={isAddingDoc}
                                                                        onClick={docValidation.handleSubmit}>
                                                                        {isAddingDoc ? 'Adding Document...' : 'Submit'}
                                                                    </Button>
                                                                </div>
                                                            </Modal>
                                                        </TabPane>

                                                        <TabPane tabId={4}>
                                                            <div className="mb-5 pb-4">
                                                                <Form >
                                                                    <Row className="mt-3">
                                                                        <Col lg="6">
                                                                            <div className="mb-3">
                                                                                <Label for="profession">Profession</Label>
                                                                                <Input
                                                                                    type="text"
                                                                                    id="profession"
                                                                                    placeholder="Enter Profession"
                                                                                    className="form-control"
                                                                                    value={financialValidation.values.profession}
                                                                                    onChange={financialValidation.handleChange}
                                                                                    onBlur={financialValidation.handleBlur}
                                                                                    invalid={
                                                                                        financialValidation.touched.profession && financialValidation.errors.profession ? true : false
                                                                                    }
                                                                                />
                                                                                {financialValidation.touched.profession && financialValidation.errors.profession ? (
                                                                                    <FormFeedback type="invalid">{financialValidation.errors.profession}</FormFeedback>
                                                                                ) : null}
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg="6">
                                                                            <div className="mb-3">
                                                                                <Label for="AnnualSalaryRange">Annual Salary Range</Label>
                                                                                <div className="position-relative">
                                                                                    <select
                                                                                        id="AnnualSalaryRangeId"
                                                                                        placeholder="Select Annual Salary Range"
                                                                                        className={`form-select ${financialValidation.touched.AnnualSalaryRangeId && financialValidation.errors.AnnualSalaryRangeId ? 'is-invalid' : ''}`}
                                                                                        defaultValue=""
                                                                                        onBlur={() => financialValidation.setFieldTouched('AnnualSalaryRangeId', true)}
                                                                                        value={financialValidation.values.AnnualSalaryRangeId}
                                                                                        onChange={financialValidation.handleChange}
                                                                                    >
                                                                                        <option value="" disabled hidden>Select salary range</option>
                                                                                        {salaryRange.map((e) => (
                                                                                            <option key={e.salaryRangeId} value={e.salaryRangeId}>{e.rangeText}</option>
                                                                                        ))}
                                                                                    </select>
                                                                                    {financialValidation.touched.AnnualSalaryRangeId && financialValidation.errors.AnnualSalaryRangeId && (
                                                                                        <div className="invalid-feedback">
                                                                                            {financialValidation.errors.AnnualSalaryRangeId}
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </Col>

                                                                        <Col lg="6">
                                                                            <div className="mb-3">
                                                                                <Label for="networth">Net Worth</Label>
                                                                                <Input
                                                                                    type="text"
                                                                                    id="networth"
                                                                                    placeholder="Enter Net Worth"
                                                                                    className="form-control"
                                                                                    value={financialValidation.values.networth || ""}
                                                                                    onChange={financialValidation.handleChange}
                                                                                    onBlur={financialValidation.handleBlur}
                                                                                    invalid={
                                                                                        financialValidation.touched.networth && financialValidation.errors.networth ? true : false
                                                                                    }
                                                                                />
                                                                                {financialValidation.touched.networth && financialValidation.errors.networth ? (
                                                                                    <FormFeedback type="invalid">{financialValidation.errors.networth}</FormFeedback>
                                                                                ) : null}
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg="6">
                                                                            <div className="mb-3">
                                                                                <Label for="ExpectedTransAmtMth">Expected Transaction Amount</Label>
                                                                                <RequiredAsterisk />
                                                                                <Input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    id="ExpectedTransAmtMth"
                                                                                    placeholder="Enter ExpectedTransAmtMth"
                                                                                    onChange={financialValidation.handleChange}
                                                                                    onBlur={financialValidation.handleBlur}
                                                                                    value={financialValidation.values.ExpectedTransAmtMth || ""}
                                                                                    invalid={
                                                                                        financialValidation.touched.ExpectedTransAmtMth && financialValidation.errors.ExpectedTransAmtMth ? true : false
                                                                                    }
                                                                                />
                                                                                {financialValidation.touched.ExpectedTransAmtMth && financialValidation.errors.ExpectedTransAmtMth ? (
                                                                                    <FormFeedback type="invalid">{financialValidation.errors.ExpectedTransAmtMth}</FormFeedback>
                                                                                ) : null}
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Form>
                                                            </div>

                                                        </TabPane>

                                                        <TabPane tabId={5}>
                                                            <div className="d-flex flex-column align-items-center">
                                                                {/* Profile image display logic */}
                                                                {capturedImage || profileImage?.normalImage ? (
                                                                    <div className="mb-3">
                                                                        <div className="d-flex justify-content-center mb-3">
                                                                            <div className="rounded-circle overflow-hidden"
                                                                                style={{
                                                                                    width: '200px',
                                                                                    height: '200px',
                                                                                    border: `5px solid ${profileImage === null
                                                                                        ? 'red'
                                                                                        : profileImage.isRecognised === null
                                                                                            || profileImage.isRecognised === 0
                                                                                            ? 'yellow'
                                                                                            : 'green'
                                                                                        }`
                                                                                }}>
                                                                                <img
                                                                                    src={capturedImage || `data:image/png;base64,${profileImage.normalImage}`}
                                                                                    alt="Profile"
                                                                                    className="w-100 h-100"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className="mb-3">
                                                                        <Container
                                                                            className="bg-light rounded-circle d-flex justify-content-center align-items-center"
                                                                            style={{ width: '200px', height: '200px' }}
                                                                        >
                                                                            <i className="fas fa-user-circle" style={{ fontSize: '12rem' }} />
                                                                        </Container>
                                                                    </div>
                                                                )}

                                                                {statusData !== 3 && (
                                                                    <div className="text-center mb-3">
                                                                        {profileImage !== null && !showButtons && (
                                                                            <>
                                                                                <Button
                                                                                    color="primary"
                                                                                    className="me-2"
                                                                                    onClick={() => setShowButtons(true)}
                                                                                >
                                                                                    <i className="fa fa-edit me-1" />
                                                                                    Change
                                                                                </Button>
                                                                                {(profileImage.isRecognised === null || profileImage.isRecognised === 0) && (
                                                                                    <Button
                                                                                        color="primary"
                                                                                        onClick={() => {
                                                                                            setModal2Open(true);
                                                                                        }}
                                                                                    >
                                                                                        <i className="fa fa-check me-1" />
                                                                                        Verify
                                                                                    </Button>
                                                                                )}
                                                                            </>
                                                                        )}

                                                                        {(capturedImage === null && profileImage === null) && (
                                                                            <>
                                                                                <Button color="success" className="me-2"
                                                                                    onClick={() => {
                                                                                        setModalOpen(true);
                                                                                    }}
                                                                                >
                                                                                    <i className="fa fa-camera me-2" />
                                                                                    Live Capture
                                                                                </Button>
                                                                                <Button color="primary" onClick={() => fileInputRef.current.click()}>
                                                                                    <i className="fa fa-upload me-2" />
                                                                                    Upload Image
                                                                                </Button>
                                                                                <input
                                                                                    type="file"
                                                                                    accept="image/png, image/jpeg"
                                                                                    onChange={handleImageUpload}
                                                                                    ref={fileInputRef}
                                                                                    style={{ display: 'none', width: '10px', height: '100px' }}
                                                                                />
                                                                            </>
                                                                        )}

                                                                        {showButtons && (
                                                                            <>
                                                                                <Button
                                                                                    className="me-2"
                                                                                    onClick={() => setShowButtons(false)}>
                                                                                    <FontAwesomeIcon icon={faArrowLeft} className="fa-lg" />
                                                                                </Button>
                                                                                <Button color="success" className="me-2"
                                                                                    onClick={() => {
                                                                                        setModalOpen(true);
                                                                                    }}
                                                                                >
                                                                                    <i className="fa fa-camera me-2" />
                                                                                    Re-Capture
                                                                                </Button>
                                                                                <Button color="primary" onClick={() => fileInputRef.current.click()}>
                                                                                    <i className="fa fa-upload me-2" />
                                                                                    Re-Upload
                                                                                </Button>
                                                                                <input
                                                                                    type="file"
                                                                                    accept="image/png, image/jpeg"
                                                                                    onChange={handleImageUpload}
                                                                                    ref={fileInputRef}
                                                                                    style={{ display: 'none', width: '10px', height: '100px' }}
                                                                                />
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>

                                                            {modalOpen && (
                                                                <ProfileImage
                                                                    profileImage={profileImage}
                                                                    getProfileImage={getProfileImage}
                                                                    toggle={toggleModal}
                                                                    isOpen={modalOpen}
                                                                />
                                                            )}

                                                            {modal2Open && (
                                                                <FaceDetect
                                                                    getProfileImage={getProfileImage}
                                                                    toggle={toggle2Modal}
                                                                    isOpen={modal2Open}
                                                                />
                                                            )}
                                                        </TabPane>

                                                    </TabContent>

                                                </div>

                                            </div>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            <Row className="mb-5">
                                <Col lg={6} md={6}>

                                    {statusData === "2" && (

                                        <Button
                                            color="success"
                                            disabled={saving}
                                            onClick={handleAllSubmit}>
                                            <i className="fa fa-check me-1" />
                                            Submit For Verification
                                        </Button>

                                    )}

                                </Col>

                                <Col lg={6} md={6} className="ms-text-end text-end">

                                    <Button
                                        color="secondary"
                                        className="me-1"
                                        disabled={activeTab === 1 ? true : false}
                                        onClick={() => {
                                            toggleTab(activeTab - 1);
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faArrowLeft} className="fa-lg" />
                                    </Button>

                                    <Button
                                        color="secondary"
                                        className="me-1"
                                        disabled={activeTab === 5 ? true : false}
                                        onClick={() => {
                                            toggleTab(activeTab + 1);
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faArrowRight} className="fa-lg" />
                                    </Button>

                                    <Button
                                        color="primary"
                                        disabled={
                                            activeTab === 3 ||
                                            activeTab === 5 ||
                                            saving}
                                        onClick={handleSaveChange}
                                    >
                                        Save
                                    </Button>

                                </Col>
                            </Row>
                        </Container>

                        <Container fluid className="bg-light mb-4 p-3">
                            <div className="d-flex align-items-center mb-3">
                                <img
                                    src={profile}
                                    alt="crescent.png"
                                    className="rounded-circle"
                                    style={{ width: '45px', height: '45px' }}
                                />
                                <textarea
                                    rows="1"
                                    value={commentText}
                                    style={{ width: '500px' }}
                                    className="form-control ms-2 me-2"
                                    placeholder="Write your comment here..."
                                    onChange={(e) => setCommentText(e.target.value)}
                                />
                                <Button
                                    color="primary"
                                    onClick={handleCommentSubmit}
                                >
                                    Submit
                                </Button>
                            </div>

                            <div className="p-4" style={{ maxHeight: '410px', overflowY: 'auto', backgroundColor: "white" }}>
                                {commentData.length > 0 ? (
                                    commentData.map((comment, index) => (
                                        <div key={index} className="mb-3">
                                            <div className="d-flex align-items-center">
                                                <img
                                                    src={profile}
                                                    alt={`${comment.fromName}'s profile`}
                                                    className="rounded-circle"
                                                    style={{ width: '25px', height: '25px' }}
                                                />
                                                <h5 className="ms-3 mb-0">
                                                    {(comment.forUid === comment.fromUid)
                                                        ? "You"
                                                        : <span>{comment.fromName} <span style={{ fontSize: 'smaller' }}>(Admin)</span></span>}
                                                </h5>
                                            </div>
                                            <div className="ms-5">
                                                <p className="mt-1 mb-0"><b>Comment:</b> {comment.comment}</p>
                                                <small className="text-muted">{formatDateToUserTimeZone(comment.createdAt)}</small>
                                            </div>
                                            <hr className="bg-dark broder-4" />
                                        </div>
                                    ))
                                ) : (
                                    <div className="text-muted d-flex justify-content-center align-items-center" style={{ minHeight: "100px" }}>
                                        <h3>No comments to show</h3>
                                    </div>
                                )}
                            </div>
                        </Container>

                    </>

                }
            </div>

        </React.Fragment >
    );
};
export default (withRouter(Profile));
