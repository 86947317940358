/*import React from 'react';
import * as moment from "moment";
import { Badge } from 'reactstrap';
*/
/*const formateDate = (date, format) => {
    const dateFormat = format ? format : "DD MMM Y";
    const date1 = moment(new Date(date)).format(dateFormat);
    return date1;
};
const toLowerCase1 = str => {
    return (
      str === "" || str === undefined ? "" : str.toLowerCase()
    );
  };*/

const CustomerName = (cell) => {
    return cell.value ? cell.value : '';
};

const TransactionId = (cell) => {
    return cell.value ? cell.value : '';
};

const Branch = (cell) => {
    return cell.value ? cell.value : '';
};

const CurrencyType = (cell) => {
    return cell.value ? cell.value : '';
};

const CurrencyValue = (cell) => {
    return cell.value ? cell.value : '';
};

const Date = (cell) => {
    return cell.value ? cell.value : '';
};

const TransactionDate = (cell) => {
    return cell.value ? cell.value : '';
};

const Status = (cell) => {
    return cell.value ? cell.value : '';
};

/*const Total = (cell) => {
    return cell.value ? cell.value : '';
};*/

/*const PaymentStatus = (cell) => {
    return (
        <Badge
          className={"font-size-11 badge-soft-" + 
          (cell.value === "Paid" ? "success" : "danger" && cell.value === "Refund" ? "warning" : "danger")}          
        >
          {cell.value}
        </Badge>
    )
};
const PaymentMethod = (cell) => {
    return (
        <span>
        <i
        className={
          (cell.value === "Paypal" ? "fab fa-cc-paypal me-1" : "" || 
          cell.value === "COD" ? "fab fas fa-money-bill-alt me-1" : "" ||
          cell.value === "Mastercard" ? "fab fa-cc-mastercard me-1" : "" ||
          cell.value === "Visa" ? "fab fa-cc-visa me-1" : ""
          )}
          />{" "}
            {cell.value}
        </span>
    )
};*/

export{
    Date,
    Status,
    Branch,
    CustomerName,
    CurrencyType,
    CurrencyValue,
    TransactionDate,
    TransactionId,
}