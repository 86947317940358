import React, { useEffect, useState, useContext } from "react";
import ReactSelect from "react-select";
import { useNavigate } from "react-router-dom";
import { Form, Row, Col, Label, Input, Card, Button } from "reactstrap";
import { toast } from "react-toastify";
import { BaseCurrencyContext } from "../../common/data/context";
import { getApiData, postApiData } from "../../helpers/axiosHelper";
import Loader from "../../components/Common/Loader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faEquals, faX, faDivide, faXmark, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from "reactstrap";
import { SelectStyle } from "../../common/data/SelectStyle";
import AVEBeneficiary from "../Beneficiary/AVEBeneficiary";

const DashboardTransfer = () => {

    const navigate = useNavigate();

    const [currency, setCurrency] = useState([]);
    const [currencyIcons, setCurrencyIcons] = useState({});
    const { currencyData } = useContext(BaseCurrencyContext);

    const [searchTerm, setSearchTerm] = useState("");
    const [beneficiary, setBeneficiary] = useState([]);
    const [beneficiaryError, setBeneficiaryError] = useState("");
    const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
    const [typedBeneficiaryName, setTypedBeneficiaryName] = useState("");

    const [globalTransferData, setGlobalTransferData] = useState(null);

    const [fromCurrencyTransfer, setFromCurrencyTransfer] = useState([]);
    const [fromCurrencyValueTransfer, setFromCurrencyValueTransfer] = useState(0);
    const [selectedFromCurrencyTransfer, setSelectedFromCurrencyTransfer] = useState([]);

    const toCurrencyTransfer = selectedBeneficiary?.currencyCode;
    const [toCurrencyValueTransfer, setToCurrencyValueTransfer] = useState(0);

    const [buyRate, setBuyRate] = useState(null);
    const [contextBuyRate, setContextBuyRate] = useState(null);

    const [errors, setErrors] = useState({});
    const [modal1, setModal1] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const handleHelpClick = () => {
        window.open('https://sitapi.crescentexchange.com/help/customer/DashboardTransfer.pdf');
    };

    const transferData = globalTransferData?.find((x) => {
        const targetCurrency = toCurrencyTransfer

        return x.currencyCode === targetCurrency;
    });

    const transferRate = transferData ? parseFloat(transferData.rate) : 0;

    const calculateFee = (fee, fromCurrency, toCurrency, buyRate) => {

        if (fromCurrency === "SGD") {
            return fee;
        } else if (toCurrency === "SGD") {
            return fee;
        } else {
            return fee / buyRate;
        }

    };

    let wireFee = parseFloat(transferData ? transferData.wireTransferFee : 0);
    let commissionFee = parseFloat(transferData ? transferData.commissionCharges : 0);

    const convertedWireFee = calculateFee(wireFee, selectedFromCurrencyTransfer?.value, toCurrencyTransfer, buyRate);
    const convertedCommissionFee = calculateFee(commissionFee, selectedFromCurrencyTransfer?.value, toCurrencyTransfer, buyRate);

    console.log({ transferData, transferRate, currencyData, selectedBeneficiary, fromCurrencyTransfer, toCurrencyTransfer, wireFee, commissionFee });

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch currencies
                const currencyResponse = await getApiData("api/Currency/GetAllCurrencies");
                if (currencyResponse.success) {
                    const icons = {};
                    currencyResponse.data.forEach(currency => {
                        const flag = `data:image/png;base64,${currency.currencyFlag}`;
                        const normalizedCurrencyName = currency.currencyName.split('(')[0].trim();
                        icons[currency.currencyCode.trim()] = flag;
                        icons[normalizedCurrencyName] = flag;
                    });
                    setCurrencyIcons(icons);

                    const mappedResponse = currencyResponse.data.map((item, index) => ({
                        index: index + 1,

                        currencyId: item.currencyId,
                        currencyName: item.currencyName,
                        currencyCode: item.currencyCode,
                    }));
                    setCurrency(mappedResponse);
                } else {
                    console.error("Error fetching currencies");
                }

                // Fetch transfer currencies
                const avaCurrency = await getApiData(`api/RateMaster/GetBranchForCurrencyAll?rateType=3`);

                const distinctCurrencies = Array.from(
                    new Set(avaCurrency.data.map(currency => currency.currencyCode))
                ).map(currencyCode => avaCurrency.data.find(currency => currency.currencyCode === currencyCode));

                const formattedCurrency = distinctCurrencies
                    .map(currency => ({
                        label: currency.currencyName,
                        value: currency.currencyCode,
                        currencyCode: currency.currencyCode,
                    }))

                setFromCurrencyTransfer(formattedCurrency);

                // Fetch transfer rates
                const rateResponse = await getApiData("api/RateMaster/GetGlobalTransferRate");
                if (rateResponse.success) {
                    const mappedTransferRates = rateResponse.data.map(rate => ({
                        rateHistoryId: rate.rateHistoryId,
                        currencyCode: rate.currencyCode,
                        currencyName: rate.currencyName,
                        rate: rate.value,
                        worldRate: parseFloat(rate.worldRate),
                        transferRate: parseFloat(rate.transferRate),
                        wireTransferFee: parseFloat(rate.wireTransferFee),
                        agentCharges: parseFloat(rate.agentCharges),
                        commissionCharges: parseFloat(rate.commissionCharges),
                        totalFeesAdmin: parseFloat(rate.totalFeesAdmin),
                    }));
                    setGlobalTransferData(mappedTransferRates);

                } else {
                    console.error("Error fetching global transfer rates");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (currencyData?.value && fromCurrencyTransfer.length > 0) {
            const initialCurrency = fromCurrencyTransfer.find(
                currency => currency.value === currencyData?.value
            );
            if (initialCurrency) {
                setSelectedFromCurrencyTransfer(initialCurrency);
            }
        }
    }, [currencyData, fromCurrencyTransfer]);

    useEffect(() => {

        const fetchContextBuyRate = async () => {
            setIsLoading(true);
            const resp = await getApiData(`api/RateMaster/GetTransactionRate?fromCurr=${currencyData?.value}`);
            if (resp.success === true) {
                setContextBuyRate(parseFloat(resp.data.buyRate));
            } else {
                setContextBuyRate(null);
            }
            setIsLoading(false);
        }
        console.log(contextBuyRate)
        fetchContextBuyRate();
    }, [currencyData, contextBuyRate]);

    const fetchTransfer = async (fromCurr) => {
        setIsLoading(true);
        const resp = await getApiData(`api/RateMaster/GetTransactionRate?fromCurr=${fromCurr}`);
        if (resp.success === true) {
            setBuyRate(parseFloat(resp.data.buyRate));
        } else {
            setBuyRate(null);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (selectedFromCurrencyTransfer?.value !== "SGD" &&
            selectedFromCurrencyTransfer?.value !== undefined) {
            fetchTransfer(selectedFromCurrencyTransfer?.currencyCode);
        }
        setFromCurrencyValueTransfer(0);
        setToCurrencyValueTransfer(0);
    }, [selectedFromCurrencyTransfer]);

    const fetchBeneficiaries = async () => {
        const response = await getApiData("api/Customerbeneficiary/GetAllBeneficiaryByCustomer");
        const filteredResponse = response.data.filter(item => item.status === 2 && item.active === 1);
        const mappedBeneficiaries = filteredResponse.map(item => ({
            label: item.beneficiaryName,
            value: item.custBenId,
            currencyName: item.currencyName.split('(')[0].trim(),
            currencyCode: item.currencyName.split('(')[1]?.replace(')', '') || "",
        }));
        setBeneficiary(mappedBeneficiaries)
    };

    useEffect(() => {
        fetchBeneficiaries();
    }, []);

    const handleSubmitTransfer = async (e) => {
        e.preventDefault();

        const TransferData = {

            toCustBenId: selectedBeneficiary.value,

            fromCurrType: selectedFromCurrencyTransfer.value,
            fromCurrValue: fromCurrencyValueTransfer,

            toCurrType: toCurrencyValueTransfer,
            toCurrValue: toCurrencyValueTransfer,

            exchangeRate: transferRate,

            transactionType: 3,
            // fromBranchId: branchID,
        };

        try {
            console.log(TransferData);
            return;
            setIsLoading(true);
            const response = await postApiData("api/Transaction/InsertTransaction", TransferData);
            if (response.success) {
                toast.success("Transaction request completed", { autoClose: 3000 });
            } else {
                toast.error(response.message, { autoClose: 3000 });
            }
        } catch (error) {
            console.error("Transaction Error:", error);

        } finally {
            setIsLoading(false);
        }
    };

    const toggleAddModal = () => setModal1(!modal1);

    const openADDBeneficiary = () => {
        navigate("/CustomerBeneficiary");
    }

    const filteredBeneficiaries = beneficiary.filter(b =>
        b.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (typedBeneficiaryName) {
        filteredBeneficiaries.push({
            label: `Create "${typedBeneficiaryName}" Beneficiary`,
            value: "create-new",
        });
    }

    if (filteredBeneficiaries.length === 0) {
        filteredBeneficiaries.push({
            label: `Create New Beneficiary for ${toCurrencyTransfer} currency`,
            value: "create-new",
        });
    }

    const convertFromTransferCurrency = (e) => {
        const inputValue = e.target.value;

        if (inputValue === "") {
            setFromCurrencyValueTransfer("");
            setToCurrencyValueTransfer("");
            setErrors({
                fromCurrency: "",
                toCurrency: "",
            });
            return;
        }

        if (inputValue.length > 10) return;

        const amount = parseFloat(inputValue) || 0;
        const totalFee = convertedWireFee + convertedCommissionFee || 0;

        setFromCurrencyValueTransfer(inputValue);

        if (amount <= 0 || amount <= 10 || amount < totalFee) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: `Amount must be greater than 10, total fees (${totalFee.toFixed(2)}), and cannot be negative.`,
                toCurrency: ""
            }));
            setToCurrencyValueTransfer(0);
            return;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            fromCurrency: "",
        }));

        let convertedValue = 0;

        if (selectedFromCurrencyTransfer?.value === "SGD") {
            if (transferRate > 0) {
                convertedValue = (amount - totalFee) / transferRate;
            }
        } else if (toCurrencyTransfer === "SGD") {
            if (!buyRate || buyRate <= 0) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    fromCurrency: "Invalid rates for foreign currency transfer. Please check Transfer rate.",
                    toCurrency: ""
                }));
                setToCurrencyValueTransfer(0);
                return;
            }
            convertedValue = (amount * buyRate) - totalFee;
        } else {
            if (!buyRate || buyRate <= 0 || transferRate <= 0) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    fromCurrency: "Invalid rates for foreign currency transfer. Please check buy and sell rates.",
                    toCurrency: ""
                }));
                setToCurrencyValueTransfer(0);
                return;
            }
            const subAmount = amount - totalFee;
            const convertedSGD = subAmount * buyRate;
            convertedValue = convertedSGD / transferRate;
        }

        if (convertedValue <= 0) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: `Converted value must be greater than 10 and cannot be negative.`,
            }));
            setToCurrencyValueTransfer(0);
            return;
        }
        console.log(convertedValue);
        setToCurrencyValueTransfer(convertedValue);
    };

    const convertToTransferCurrency = (e) => {
        const inputValue = e.target.value;

        if (inputValue === "") {
            setFromCurrencyValueTransfer("");
            setToCurrencyValueTransfer("");
            setErrors({
                fromCurrency: "",
                toCurrency: "",
            });
            return;
        }

        if (inputValue.length > 10) return;

        const amount = parseFloat(inputValue) || 0;

        const totalFee = convertedWireFee + convertedCommissionFee || 0;

        setToCurrencyValueTransfer(inputValue);

        if (amount <= 0 || amount < 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: `Amount must be greater than 10 and cannot be negative.`,
            }));
            setFromCurrencyValueTransfer(0);
            return;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            toCurrency: "",
        }));

        let convertedValue = 0;

        if (selectedFromCurrencyTransfer?.value === "SGD") {
            if (transferRate > 0) {
                convertedValue = (amount * transferRate) + totalFee;
            }
        } else if (toCurrencyTransfer === "SGD") {
            if (!buyRate || buyRate <= 0) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    fromCurrency: "",
                    toCurrency: "Invalid rates for foreign currency transfer. Please check Transfer rates.",
                }));
                setFromCurrencyValueTransfer(0);
                return;
            }
            convertedValue = (amount + totalFee) / buyRate;
        } else {
            if (!buyRate || buyRate <= 0 || transferRate <= 0) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    fromCurrency: "",
                    toCurrency: "Invalid rates for foreign currency transfer. Please check buy and sell rates.",
                }));
                setFromCurrencyValueTransfer(0);
                return;
            }
            const convertedSGD = amount * transferRate;
            convertedValue = (convertedSGD / buyRate) + totalFee;
        }
        if (convertedValue <= 0) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: `Converted value must be greater than 10 and cannot be negative.`,
                toCurrency: ""
            }));
            setFromCurrencyValueTransfer(0);
            return;
        }
        console.log(convertedValue);
        setFromCurrencyValueTransfer(convertedValue);
    };

    console.log({ buyRate, contextBuyRate });

    return (
        <>
            <AVEBeneficiary
                editMode={3}
                isOpen={modal1}
                currencies={currency}
                toggle={toggleAddModal}
            />
            {
                isLoading ? (
                    <Loader />
                ) : (
                    <Card className="shadow-sm p-4">
                        <div className="ms-3 d-flex justify-content-end  ">
                            <FontAwesomeIcon
                                icon={faQuestionCircle}
                                onClick={handleHelpClick}
                                color="#556ee6"
                                id="helpIcon"

                                style={{ fontSize: '1.2em', cursor: "pointer" }}
                            />
                            <Tooltip
                                placement="top"
                                isOpen={tooltipOpen}
                                target="helpIcon"
                                toggle={() => setTooltipOpen(!tooltipOpen)}
                            >
                                Help
                            </Tooltip>
                        </div>
                        {isLoading
                            ? <Loader />
                            : (

                                <Form onSubmit={handleSubmitTransfer}>

                                    <div className="mb-3">
                                        <Label>Select Beneficiary</Label>
                                        <ReactSelect
                                            styles={SelectStyle}
                                            style={{ border: '1px solid #000' }}
                                            value={selectedBeneficiary}
                                            onInputChange={(inputValue) => {
                                                setSearchTerm(inputValue);
                                                setTypedBeneficiaryName(inputValue);
                                            }}
                                            onChange={(selectedOption) => {
                                                if (selectedOption?.value === "create-new") {
                                                    openADDBeneficiary();
                                                } else {
                                                    setSelectedBeneficiary(selectedOption);
                                                }
                                            }}
                                            options={filteredBeneficiaries}
                                        />

                                        {beneficiaryError && <div className="text-danger mt-1">{beneficiaryError}</div>}
                                    </div>

                                    <Row>
                                        <Col md={6}>
                                            <Label>You Give</Label>
                                            <ReactSelect
                                                styles={SelectStyle}
                                                value={selectedFromCurrencyTransfer}
                                                formatOptionLabel={({ value, label }) => (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <img
                                                            src={currencyIcons[value]}
                                                            alt={`${value} flag`}
                                                            style={{ width: '20px', marginRight: '5px' }}
                                                        />
                                                        <span>{label}</span>
                                                    </div>
                                                )}
                                                onChange={setSelectedFromCurrencyTransfer}
                                                options={fromCurrencyTransfer}
                                            />
                                        </Col>

                                        <Col md={6}>
                                            <Label>Enter {selectedFromCurrencyTransfer?.currencyCode} Amount </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Amount"
                                                value={fromCurrencyValueTransfer}
                                                onChange={convertFromTransferCurrency}
                                                onFocus={(e) => (e.target.style.border = '2px solid #556ee6')}
                                            />
                                            {errors.fromCurrency && <div className="text-danger">{errors.fromCurrency}</div>}
                                        </Col>
                                    </Row>

                                    {selectedBeneficiary !== null
                                        ?
                                        <>
                                            {selectedFromCurrencyTransfer?.value !== currencyData?.value &&
                                                <Row className="mb-3 text-end mt-2">
                                                    <Col lg={6} className="text-start">
                                                        <span>Converted {currencyData?.value}</span>
                                                    </Col>

                                                    <Col lg={6}>
                                                        <span>
                                                            <FontAwesomeIcon icon={faEquals} />
                                                            {" "}
                                                            {selectedFromCurrencyTransfer?.currencyCode === "SGD"
                                                                ? (fromCurrencyValueTransfer / contextBuyRate).toFixed(3)
                                                                : 0
                                                            }
                                                            {" "}
                                                            {currencyData?.value}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            }

                                            <Row className="mb-3 text-end mt-2">
                                                <Col lg={6} className="text-start">
                                                    <span>Wire Transfer Fee</span>
                                                </Col>

                                                <Col lg={6}>
                                                    <span>
                                                        <FontAwesomeIcon icon={faMinus} />
                                                        {" "}
                                                        {selectedFromCurrencyTransfer?.currencyCode === "SGD"
                                                            ? (wireFee / contextBuyRate).toFixed(3)
                                                            : 0
                                                        }
                                                        {" "}
                                                        {currencyData?.value}
                                                    </span>
                                                </Col>
                                            </Row>

                                            <Row className="mb-3 text-end">
                                                <Col lg={6} className="text-start">
                                                    <span>Commission Fee</span>
                                                </Col>
                                                <Col lg={6}>
                                                    <span>
                                                        <FontAwesomeIcon icon={faMinus} />
                                                        {" "}
                                                        {selectedFromCurrencyTransfer?.currencyCode === "SGD"
                                                            ? (commissionFee / contextBuyRate).toFixed(3)
                                                            : 0
                                                        }
                                                        {" "}
                                                        {currencyData?.value}
                                                    </span>
                                                </Col>
                                            </Row>

                                            <Row className="mb-3 text-end">
                                                <Col lg={6} className="text-start">
                                                    <span>Total {selectedFromCurrencyTransfer?.value}</span>
                                                </Col>
                                                <Col lg={6}>
                                                    <span>
                                                        <FontAwesomeIcon icon={faEquals} />
                                                        {" "}
                                                        {selectedFromCurrencyTransfer?.currencyCode === "SGD"
                                                            ? (fromCurrencyValueTransfer - ((wireFee / contextBuyRate) + (commissionFee / contextBuyRate))).toFixed(3)
                                                            : 0
                                                        }
                                                        {" "}
                                                        {currencyData?.value}
                                                    </span>
                                                </Col>
                                            </Row>

                                            <Row className="mb-3 text-end">
                                                <Col lg={6} className="text-start">
                                                    <span>Transfer Rate</span>
                                                </Col>
                                                <Col lg={6}>
                                                    <span>
                                                        <FontAwesomeIcon icon={faEquals} />
                                                        {" "}
                                                        {selectedFromCurrencyTransfer?.currencyCode === "SGD"
                                                            ? transferRate.toFixed(3)
                                                            : 0
                                                        }
                                                        {" "}
                                                        {currencyData?.value}
                                                    </span>
                                                </Col>
                                            </Row>

                                        </>
                                        :
                                        <div className="text-center mt-3 mb-3">
                                            <h4>Please Select a Beneficiary</h4>
                                        </div>
                                    }

                                    <Row>
                                        <Col md={6}>
                                            <Label>Beneficiary Receive</Label>
                                            <div style={{ display: 'flex', alignItems: 'center', border: '1px solid #adb5bd', borderRadius: '2px', background: '#fff', padding: '2px' }}>
                                                {(selectedBeneficiary?.currencyCode || selectedBeneficiary?.currencyName) &&
                                                    currencyIcons[selectedBeneficiary.currencyCode || selectedBeneficiary.currencyName] && (
                                                        <img
                                                            src={
                                                                currencyIcons[selectedBeneficiary.currencyCode] ||
                                                                currencyIcons[selectedBeneficiary.currencyName]
                                                            }
                                                            alt="Currency flag"
                                                            style={{ width: "20px", }}
                                                        />
                                                    )}
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    value={selectedBeneficiary?.currencyName || ""}
                                                    disabled style={{ border: 'none', background: '#fff', }}
                                                    placeholder="Select a Beneficiary"
                                                />
                                            </div>
                                        </Col>

                                        <Col md={6}>
                                            <Label>{toCurrencyTransfer} Amount</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Amount"
                                                value={toCurrencyValueTransfer}
                                                onChange={convertToTransferCurrency}
                                                onFocus={(e) => (e.target.style.border = "2px solid #556ee6")}
                                                disabled={!selectedBeneficiary}
                                            />
                                            {errors.toCurrency && <div className="text-danger">{errors.toCurrency}</div>}
                                        </Col>
                                    </Row>

                                    <div className="d-flex justify-content-between mt-4">
                                        <Button type="button" color="danger" onClick={() => setFromCurrencyValueTransfer("")}>
                                            Cancel
                                        </Button>
                                        <Button type="submit" color="primary">
                                            Submit
                                        </Button>
                                    </div>

                                </Form>
                            )}
                    </Card>
                )}
        </>
    );
};

export default DashboardTransfer;


