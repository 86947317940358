import "./js/i18n";
import React from 'react';
import App from './js/setup';
import store from "./js/store";
import { Provider } from "react-redux";
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { CurrencyProvider, ProfileProvider } from "./js/common/data/context";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <React.Fragment>
            <BrowserRouter>
                <GoogleOAuthProvider clientId="634933862226-38n3hlga09231vesmnnubf1jg73b0ru7.apps.googleusercontent.com">
                    <ProfileProvider>
                        <CurrencyProvider>

                            <App />

                        </CurrencyProvider>
                    </ProfileProvider>
                </GoogleOAuthProvider>
            </BrowserRouter>
        </React.Fragment>
    </Provider>
);


