export const drawBoundingBox = (prediction, ctx) => {
    if (!prediction) return;
    const keyPoints = prediction.keypoints;
    if (!keyPoints) return;

    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    // Calculate the bounding box
    let minX = keyPoints[0].x;
    let maxX = keyPoints[0].x;
    let minY = keyPoints[0].y;
    let maxY = keyPoints[0].y;

    for (let i = 1; i < keyPoints.length; i++) {
        const { x, y } = keyPoints[i];
        if (x < minX) minX = x;
        if (x > maxX) maxX = x;
        if (y < minY) minY = y;
        if (y > maxY) maxY = y;
    }

    const boxWidth = maxX - minX;
    const boxHeight = maxY - minY;

    // Draw the square (use the smaller dimension for a square frame)
    const sideLength = Math.min(boxWidth, boxHeight);
    ctx.beginPath();
    ctx.rect(minX, minY, sideLength, sideLength);
    ctx.strokeStyle = "green";
    ctx.lineWidth = 3;
    ctx.stroke();
};
