import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import {
    Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Nav, NavItem, NavLink, TabContent, TabPane,
} from "reactstrap";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import withRouter from "../../Common/withRouter";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircleUser
} from "@fortawesome/free-solid-svg-icons";
import { getApiData } from "../../../helpers/axiosHelper";
import Notifications from "./Notifications";
import ReactSelect from "react-select";
import { SelectStyle } from "../../../common/data/SelectStyle";
import { BaseCurrencyContext, ProfileContext } from "../../../common/data/context";
import Swal from "sweetalert2";

const ProfileMenu = (props) => {

    const [menu, setMenu] = useState(false);
    const { profileData } = useContext(ProfileContext);
    const [profileImage, setProfileImage] = useState(null);
    const [currencyIcons, setCurrencyIcons] = useState({});
    const [currencyOptions, setCurrencyOptions] = useState([]);
    const { setCurrencyData } = useContext(BaseCurrencyContext);
    const [selectedCurrency, setSelectedCurrency] = useState(null);

    useEffect(() => {
        const fetchProfileImage = async () => {
            try {
                const { data: image } = await getApiData("api/ProfileImage/GetProfileImage");
                setProfileImage(image?.isRecognised ? image : null);
            } catch {
                toast.error("Error fetching profile image.", { position: "top-right", autoClose: 3000 });
            }
        };
        fetchProfileImage();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [currenciesResponse, branchResponse] = await Promise.all([
                    getApiData("api/Currency/GetAllCurrencies"),
                    getApiData("api/RateMaster/GetBranchForCurrencyAll?rateType=3"),
                ]);

                const icons = currenciesResponse.data.reduce((acc, { currencyCode, currencyFlag }) => {
                    acc[currencyCode.trim()] = `data:image/png;base64,${currencyFlag}`;
                    return acc;
                }, {});

                const options = Array.from(
                    new Map(
                        branchResponse.data
                            .filter(({ currencyCode }) => currencyCode.trim() !== "SGD")
                            .map(({ currencyCode, currencyName }) => {
                                const [currencyNameShort] = currencyName.split("(");
                                return [
                                    currencyCode.trim(),
                                    {
                                        value: currencyCode.trim(),
                                        label: currencyNameShort.trim(), 
                                        currencyName: currencyNameShort.trim(),
                                    },
                                ];
                            })
                    ).values()
                );

                options.unshift({
                    value: "SGD",
                    label: "Singapore Dollar", 
                    currencyName: "Singapore Dollar",
                });

                setCurrencyIcons(icons);
                setCurrencyOptions(options);

                const defaultOption = { value: "SGD", label: "SGD" };
                setSelectedCurrency(defaultOption);
                setCurrencyData({
                    ...defaultOption,
                    currencyName: "Singapore Dollar",
                });
            } catch (error) {
                console.error("Error fetching currencies", error);
            }
        };

        fetchData();
    }, [setCurrencyData]);

    const handleCurrencySelect = async (option) => {
        if (option.value !== "SGD") {
            const result = await Swal.fire({
                text: `You are attempting to change Currency. All the conversions will now be based on ${option.value} currency, and you will only be able to perform the Transfer Operation. Do you wish to proceed?`,
                title: "Warning",
                icon: "warning",
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: "Ok",
                confirmButtonColor: "#556ee6",
            });

            if (!result.isConfirmed) return;
        }

        setSelectedCurrency({
            ...option,
            label: option.value, 
        });
        setCurrencyData(option);
    };

    const toggleMenu = () => setMenu(!menu);

    return (
        <>
            <ToastContainer />
            <div className="d-flex mt-3  gap-2" style={{ marginRight: '40px' }}>

                <p className="d-none d-sm-flex align-items-center" style={{ color: '#556ee6' }}>
                    We Support
                </p>

                <ReactSelect
                    value={
                        selectedCurrency && selectedCurrency.value
                            ? { ...selectedCurrency, label: selectedCurrency.value }
                            : null 
                    }
                    options={currencyOptions || []} 
                    onChange={handleCurrencySelect}
                    components={{ IndicatorSeparator: () => null }}
                    styles={{
                        container: (provided) => ({
                            ...provided,
                            maxWidth: "100%",
                        }),
                        control: (provided) => ({
                            ...provided,
                            minWidth: "100%",
                        }),
                        menu: (provided) => ({
                            ...provided,
                            width: "100%",
                            minWidth: "300px",
                        }),
                        option: (provided) => ({
                            ...provided,
                            display: "flex",
                            alignItems: "center",
                        }),
                    }}
                    formatOptionLabel={(option) =>
                        option && (
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <img
                                    src={currencyIcons[option.value]}
                                    alt={option.label}
                                    style={{
                                        width: "20px",
                                        height: "20px",
                                        marginRight: "8px",
                                    }}
                                />
                                {option.label}
                            </div>
                        )
                    }
                />


            </div>
            {/* Profile Dropdown */}
            <Dropdown isOpen={menu} toggle={toggleMenu} className="d-flex align-items-center">
                <DropdownToggle className="btn header-item d-flex align-items-center gap-2" tag="button">
                    {profileImage ? (
                        <div className="rounded-circle border border-dark" style={{ width: 35, height: 35, overflow: "hidden" }}>
                            <img
                                src={`data:image/png;base64,${profileImage.normalImage}`}
                                alt="Profile"
                                className="w-100 h-100"
                                style={{ objectFit: "cover", borderRadius: "50%" }}
                            />
                        </div>
                    ) : (
                        <FontAwesomeIcon icon={faCircleUser} size="lg" />
                    )}
                    <span className="d-none d-xl-inline-block">
                        {profileData?.firstName ? `${profileData.firstName} ${profileData.lastName}` : ""}
                    </span>
                    <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem tag="a" href="/profile">
                        <i className="bx bx-user font-size-16 me-1" /> {props.t("Profile")}
                    </DropdownItem>
                    <DropdownItem tag="a" href="/changepassword">
                        <i className="bx bx-key font-size-16 me-1" /> {props.t("Change password")}
                    </DropdownItem>
                    <div className="dropdown-divider" />
                    <Link to="/logout" className="dropdown-item">
                        <i className="bx bx-power-off font-size-16 me-1 text-danger" /> {props.t("Logout")}
                    </Link>
                </DropdownMenu>
            </Dropdown>

            {/* Notification Bell Dropdown */}
            <Notifications />
        </>
    );
};

ProfileMenu.propTypes = {
    t: PropTypes.any,
};

export default withRouter(withTranslation()(ProfileMenu));
