import React from "react"
import { Row, Col } from "reactstrap"

//Import Images
import logolight from "./../../../../../images/logo-light.png"

const FooterLink = () => {
  return (
    <React.Fragment>
      <Row>
        <Col lg="6">

          <p className="mb-2">
            {new Date().getFullYear()} © Cresent Exchange.
          </p>
          
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default FooterLink
