import axios from "axios";
import config from "../../config";
import { forceLogout } from "../common/data/commonFunction";
function initializeHeader() {


    var custUser = sessionStorage.getItem("custUser");
    var authObj = JSON.parse(custUser);
    // Set up default config for axios
    axios.defaults.baseURL = config.apiUrl;
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["Authorization"] = "Bearer " + authObj?.token;

}


// Create a function to make a GET request
export const getApiData = async (endpoint, params = {}) => {
    try {
        initializeHeader()
        const response = await axios.get(axios.defaults.baseURL + endpoint, {
            params,
        });
        return response.data;
    } catch (error) {
        // Handle error
        forceLogout(error)
        console.error("Error in getApiData:", error);
        throw error;
    }
};

// Create a function to make a POST request
export const postApiData = async (endpoint, data = {}) => {
    try {
        initializeHeader()
        const response = await axios.post(axios.defaults.baseURL + endpoint, data);
        return response.data;
    } catch (error) {
        // Handle error
        forceLogout(error)
        console.error("Error in postApiData:", error);
        throw error;
    }
};

// Create a function to make a PUT request
export const putApiData = async (endpoint, data = {}) => {
    try {
        initializeHeader()
        const response = await axios.put(axios.defaults.baseURL + endpoint, data);
        return response.data;
    } catch (error) {
        // Handle error
        forceLogout(error)
        console.error("Error in putApiData:", error);
        throw error;
    }
};

// Create a function to make a DELETE request
export const deleteApiData = async (endpoint, data = {}) => {
    try {
        initializeHeader()
        const response = await axios.delete(
            axios.defaults.baseURL + endpoint,
            data
        );
        return response.data;
    } catch (error) {
        // Handle error
        forceLogout(error)
        console.error("Error in deleteApiData:", error);
        throw error;
    }
};

export default axios;
