import React from "react";
import {
    Container,
    Row,
} from "reactstrap";

//Import Components
import CardUser from "./card-user"
import AddCurrency from "./AddCurrency";
import { ProfileProvider } from "../../common/data/context";
//import AddTransaction from "./AddTransaction";
//import ReceivedTime from './ReceivedTime';

//import ViewRates from "./view-rates"
//import Promotions from "./promotions"
//import Advertisment from "./advertisment"
//import Breadcrumbs from "../../components/Common/Breadcrumb"

const DashboardSaas = props => {

    document.title = "Crescent Exchange | Dashboard"

    return (
        <React.Fragment>
            <div className="page-content" >
                <Container fluid>
                    {/*<Breadcrumbs title="Dashboards" breadcrumbItem="Dashboard" />*/}

                    {/* Card User */}
                    <CardUser />
                    <AddCurrency />
                    {/*<AddTransaction />*/}

                    {/*  <Row>

                        <ReceivedTime />
                    </Row>
*/}
                    <Row>

                        {/* tasks */}
                        {/*<Promotions />*/}

                        {/* chat box */}
                        {/* <Advertisment />*/}

                    </Row>


                </Container>
            </div>
        </React.Fragment>
    )
}

export default DashboardSaas
