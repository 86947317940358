import Webcam from 'react-webcam';
import { Modal } from 'reactstrap';
import ReactSelect from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { Button, Container, Row, Col } from 'reactstrap';
import React, { useState, useEffect, useRef } from 'react';
import { postApiData, putApiData } from '../../helpers/axiosHelper';
import Swal from "sweetalert2";
import Loader from '../../components/Common/Loader';

const ProfileImage = ({ profileImage, getProfileImage, isOpen, toggle }) => {
    const videoRef = useRef(null);
    const streamRef = useRef(null);

    const [devices, setDevices] = useState([]);
    const [deviceId, setDeviceId] = useState('');
    const [loading, setLoading] = useState(false);

    const convertToGrayscale = (imageData) => {
        return new Promise((resolve) => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            const image = new Image();
            image.src = imageData;

            image.onload = () => {
                canvas.width = image.width;
                canvas.height = image.height;
                context.drawImage(image, 0, 0);

                const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
                const data = imageData.data;

                for (let i = 0; i < data.length; i += 4) {
                    const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
                    data[i] = avg;
                    data[i + 1] = avg;
                    data[i + 2] = avg;
                }

                context.putImageData(imageData, 0, 0);
                const grayscaleImageData = canvas.toDataURL('image/png');
                resolve(grayscaleImageData);
            };
        });
    };

    const handleCapture = async () => {
        setLoading(true);
        const video = videoRef.current.getScreenshot();
        const capturedImageData = video;
        toggle();

        const videoStream = streamRef.current;
        if (videoStream) {
            const tracks = videoStream.getTracks();
            tracks.forEach((track) => track.stop());
        }

        let normalImage = capturedImageData.split(',')[1];
        let grayScaleImage = (await convertToGrayscale(capturedImageData)).split(',')[1];

        const formData = new FormData();
        formData.append('normalImage', normalImage);
        formData.append('grayScaleImage', grayScaleImage);

        if (profileImage === null) {
            const response = await postApiData('api/ProfileImage/CreateProfileImage', formData);
            if (response.success) {
                toast.success("Profile Image Added Successfully", {
                    position: "top-right",
                    autoClose: 3000,
                });
                getProfileImage();
            } else {
                toast.error("Error occurred while creating image.");
            }
        } else {
            const response = await putApiData('api/ProfileImage/UpdateImage', formData);
            if (response.success) {
                toast.success("Profile Image Updated Successfully", {
                    position: "top-right",
                    autoClose: 3000,
                });
                getProfileImage();
            } else {
                toast.error("Error occurred while updating image.");
            }
        }
        setLoading(false);
    };

    const handleDevices = (mediaDevices) => {
        const videoDevices = mediaDevices.filter(({ kind }) => kind === 'videoinput');
        setDevices(videoDevices);
        if (videoDevices.length > 0) {
            setDeviceId(videoDevices[0].deviceId);
        }
    };

    const checkCameraAvailability = async () => {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter((device) => device.kind === 'videoinput');

        if (videoDevices.length === 0) {
            Swal.fire({
                title: "No Camera Available",
                text: "Please connect a camera and try again.",
                icon: "warning",
                confirmButtonText: "OK",
            });
            return false;
        }

        setDevices(videoDevices);
        if (videoDevices.length > 0) {
            setDeviceId(videoDevices[0].deviceId);
        }

        return true;
    };

    useEffect(() => {
        navigator.mediaDevices.enumerateDevices().then(handleDevices);
    }, []);

    useEffect(() => {
        if (isOpen) {
            checkCameraPermissions().then((hasPermission) => {
                if (hasPermission) {
                    checkCameraAvailability().then((isAvailable) => {
                        if (isAvailable) {
                            handleLiveCapture();
                        } else {
                            Swal.fire({
                                title: "No Camera Available",
                                text: "Please connect a camera or check your permissions.",
                                icon: "warning",
                                confirmButtonText: "OK",
                            });
                            toggle();
                        }
                    });
                } else {
                    Swal.fire({
                        title: "Camera Permission Denied",
                        text: "Please allow camera access in your browser settings and try again.",
                        icon: "warning",
                        confirmButtonText: "OK",
                    });
                    toggle();
                }
            });
        }
    }, [isOpen]);

    const checkCameraPermissions = async () => {
        const permissions = await navigator.permissions.query({ name: 'camera' });

        if (permissions.state === 'denied') {
            Swal.fire({
                title: "Camera Permission Denied",
                text: "Please allow camera access in your browser settings.",
                icon: "warning",
                confirmButtonText: "OK",
            });
            return false;
        }

        return true;
    };

    const handleLiveCapture = () => {
        navigator.mediaDevices.getUserMedia({ video: true })
            .then((stream) => {
                streamRef.current = stream;
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                }
            })
            .catch((error) => {
                if (error.name === 'NotAllowedError') {
                    Swal.fire({
                        title: "Camera Access Denied",
                        text: "Please allow camera access in your browser settings and try again.",
                        icon: "warning",
                        confirmButtonText: "OK",
                    });
                } else if (error.name === 'NotFoundError') {
                    Swal.fire({
                        title: "No Camera Found",
                        text: "No camera was found on this device. Please connect a camera and try again.",
                        icon: "warning",
                        confirmButtonText: "OK",
                    });
                } else {
                    Swal.fire({
                        title: "Camera Error",
                        text: "An unexpected error occurred while accessing the camera. Please check your camera and try again.",
                        icon: "error",
                        confirmButtonText: "OK",
                    });
                }
                console.error("Error accessing the camera:", error);
                toggle();
            });
    };

    const handleCaptureCancel = () => {
        const videoStream = streamRef.current;
        if (videoStream) {
            console.log("Stopping video stream...");
            videoStream.getTracks().forEach((track) => {
                track.stop();
            });
            streamRef.current = null;
        } else {
            console.log("No active video stream to stop.");
        }

        toggle();
    };

    return (
        <>
            <ToastContainer closeButton={false} limit={1} />
            <Modal
                isOpen={isOpen}
                toggle={handleCaptureCancel}
                size="md"
                centered
                style={{ height: '500px' }}
            >
                <div>
                    {loading ? (
                        <Loader />
                    ) : (
                        <Container fluid className="p-2" style={{ height: '100%' }}>
                            <Col>
                                <Webcam
                                    ref={videoRef}
                                    style={{
                                        width: '100%',
                                        height: '90%',
                                    }}
                                />
                            </Col>
                            <Col className="mt-2">
                                {devices.length > 0 && (
                                    <ReactSelect
                                        onChange={(e) => setDeviceId(e.value)}
                                        value={devices.find((device) => device.deviceId === deviceId) || null}
                                        options={devices.map((device) => ({
                                            value: device.deviceId,
                                            label: device.label,
                                        }))}
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                backgroundColor: 'white',
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                backgroundColor: 'white',
                                            }),
                                        }}
                                    />
                                )}
                            </Col>
                            <Row className="mt-2">
                                <Col >
                                    <Button color="success" onClick={handleCapture} style={{ width: '100%' }}>
                                        Capture
                                    </Button>
                                </Col>
                                <Col>
                                    <Button color="danger" onClick={handleCaptureCancel} style={{ width: '100%' }}>
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    )}
                </div>
            </Modal>
        </>
    );
};

export default ProfileImage;
