import PropTypes from "prop-types";
import React, { useState } from "react";
import { Collapse } from "reactstrap";
import { Link } from "react-router-dom";
import withRouter from "../Common/withRouter";
import classname from "classnames";

//i18n
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";

const Navbar = ({ leftMenu, t }) => {
    const [dropdownStates, setDropdownStates] = useState({
        transaction: false,
    });

    const openDropdown = (dropdown) => {
        setDropdownStates({ ...dropdownStates, [dropdown]: true });
    };

    const closeDropdown = (dropdown) => {
        setDropdownStates({ ...dropdownStates, [dropdown]: false });
    };

    const handleMouseEnter = (dropdown) => {
        openDropdown(dropdown);
    };

    const handleMouseLeave = (dropdown) => {
        closeDropdown(dropdown);
    };

    const handleOptionClick = (dropdown) => {
        closeDropdown(dropdown);
    };

    return (
        <React.Fragment>
            <div className="topnav">
                <div className="container-fluid">
                    <nav
                        className="navbar navbar-light navbar-expand-lg topnav-menu"
                        id="navigation"
                    >
                        <Collapse
                            isOpen={leftMenu}
                            className="navbar-collapse"
                            id="topnav-menu-content"
                        >
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link
                                        to="/dashboard"
                                        className="nav-link arrow-none"
                                    >
                                        <i className="bx bx-home-circle me-2"></i>
                                        {t("Dashboard")}
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link
                                        to="/CustomerBeneficiary"
                                        className="nav-link arrow-none"
                                    >
                                        <FontAwesomeIcon icon={faUserPlus} style={{ marginRight: "8px" }} />
                                        {t("Beneficiary")}
                                    </Link>
                                </li>

                                <li className="nav-item dropdown">
                                    <Link
                                        to="#"
                                        onMouseEnter={() => handleMouseEnter("transaction")}
                                        onMouseLeave={() => handleMouseLeave("transaction")}
                                        className="nav-link arrow-none"
                                    >
                                        <i className="bx bx-window me-2"></i>
                                        {t("Transaction")}
                                        <div className={classname("dropdown-menu", { show: dropdownStates.transaction })}>
                                            {/*<Link*/}
                                            {/*    to="/latesttransaction"*/}
                                            {/*    className="dropdown-item"*/}
                                            {/*    onClick={() => handleOptionClick("transaction")}*/}
                                            {/*>*/}
                                            {/*    {t("Latest Transaction")}*/}
                                            {/*</Link>*/}
                                            <Link
                                                to="/addtransaction"
                                                className="dropdown-item"
                                                onClick={() => handleOptionClick("transaction")}
                                            >
                                                {t("Add Transaction")}
                                            </Link>
                                            <Link
                                                to="/managetransaction"
                                                className="dropdown-item"
                                                onClick={() => handleOptionClick("transaction")}
                                            >
                                                {t("Manage Transaction")}
                                            </Link>
                                            <Link
                                                to="/transactionhistory"
                                                className="dropdown-item"
                                                onClick={() => handleOptionClick("transaction")}
                                            >
                                                {t("Transaction History")}
                                            </Link>
                                        </div>
                                    </Link>
                                </li>

                            </ul>
                        </Collapse>
                    </nav>
                </div>
            </div>
        </React.Fragment>
    );
};

Navbar.propTypes = {
    leftMenu: PropTypes.bool,
    t: PropTypes.func.isRequired,
};

const mapStatetoProps = (state) => {
    const { leftMenu } = state.Layout;
    return { leftMenu };
};

export default withRouter(
    connect(mapStatetoProps, {})(withTranslation()(Navbar))
);
