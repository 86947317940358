import React, { useState, useEffect } from "react";
import PropTypes from "prop-types"
import {
    Modal,
    Label,
    Table,
    Badge,
    Button,
    Collapse,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap"
import { toast } from "react-toastify";
import { postApiData } from "../../helpers/axiosHelper";
import { mapStatus } from "../../common/data/StatusLabels";
import { formatDateToUserTimeZone } from "../../common/data/FormateDateTime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faEquals, faX, faDivide } from '@fortawesome/free-solid-svg-icons';

const ViewTransaction = (props) => {

    const { isOpen, toggle, optime, branchData, commentData, sendTransactionDetails, showAction, chargesData, transactionType } = props;
    console.log(props)

    const [col_1, setCol_1] = useState(true);
    const [col_2, setCol_2] = useState(false);
    const [col_3, setCol_3] = useState(false);
    const [col_4, setCol_4] = useState(false);

    const [commentText, setCommentText] = useState("");
    const [selectedStatus, setSelectedStatus] = useState(0);
    const [isCommentOpen, setIsCommentOpen] = useState(false);
    const [showBeneficiary, setShowBeneficiary] = useState(false);

    const UserTypes = {
        1: "System User",
        2: "Customer",
        3: "Agent",
    };

    const openCommentPopup = (status) => {
        setSelectedStatus(status);
        setIsCommentOpen(true);
    };

    const closeCommentPopup = () => {
        setIsCommentOpen(false);
        setCommentText("");
    };

    const handleCommentSubmit = async () => {

        const response = await postApiData("api/Transaction/AcknowledgeOrReject", {
            transactionId: sendTransactionDetails.transactionId,
            comment: commentText,
            status: selectedStatus,
        });
        if (response.success === true) {
            toggle();
            closeCommentPopup();
            toast.success(response.message, {
                position: "top-right",
                autoClose: 3000,
            });
        } else {
            toast.error("Somthing went wrong, Cannot comment.", {
                position: "top-right",
                autoClose: 3000,
            });
            toggle();
            closeCommentPopup();
        }

    };

    const calculateDateDifference = () => {
        const approvedDate = new Date(sendTransactionDetails.approvedDate);
        const currentDate = new Date();

        const approvedDateOnly = approvedDate.toISOString().split("T")[0];
        const currentDateOnly = currentDate.toISOString().split("T")[0];

        const diffInDays = Math.floor((currentDate - approvedDate) / (1000 * 60 * 60 * 24));

        return approvedDateOnly === currentDateOnly ? 0 : diffInDays;
    };

    const calculateCharges = () => {
        if (!sendTransactionDetails || !chargesData) return {};

        const { fromCurrValue, toCurrValue, exchangeRate } = sendTransactionDetails;
        const { agentCharges, commissionCharges, wireTransferFee } = chargesData;

        const totalCharges = parseFloat(agentCharges || 0) + parseFloat(commissionCharges || 0) + parseFloat(wireTransferFee || 0);

        if (transactionType === 1) {
            // Sell transaction
            const totalFromCurrency = fromCurrValue - totalCharges;
            const toCurrency = totalFromCurrency * exchangeRate;
            return { totalFromCurrency, toCurrency, totalCharges };
        } else if (transactionType === 2) {
            // Buy transaction
            const totalFromCurrency = fromCurrValue - totalCharges;
            const toCurrency = totalFromCurrency / exchangeRate;
            return { totalFromCurrency, toCurrency, totalCharges };
        }

        return {};
    };

    const { totalFromCurrency, toCurrency, totalCharges } = calculateCharges();

    const beneficiary = sendTransactionDetails?.beneficiaryDetails?.[0]; 

    return (
        <>

            <Modal
                isOpen={isOpen}
                toggle={toggle}
                centered={true}
                autoClose={true}
            >

                <ModalHeader toggle={toggle}>Transaction Details - {sendTransactionDetails?.transactionId}</ModalHeader>

                <ModalBody style={{ maxHeight: '440px' }}>
                    <div className="mb-2">
                        <Button
                            className="w-100 bg-white border-0 shadow-none text-dark fs-3 text-start d-flex justify-content-between align-items-center"
                            onClick={() => {
                                setCol_1(!col_1);
                                setCol_2(false);
                                setCol_3(false);
                            }}
                        >
                            <span>Currency</span>
                            <i className="fa fa-chevron-down" />
                        </Button>

                        <Collapse isOpen={col_1}>
                            <div style={{ maxHeight: '235px', overflowY: 'hidden' }}>
                                <Table className="table-bordered table-nowrap border border-2 mt-2">
                                    <tbody>
                                        {sendTransactionDetails && transactionType !== 3 && (
                                            <>
                                                <tr>
                                                    <th scope="row">From currency :</th>
                                                    <td>{sendTransactionDetails.fromCurrValue}</td>
                                                </tr>
                                                {totalCharges !== 0 && (
                                                    <tr>
                                                        <th scope="row">Charges :</th>
                                                        <td>
                                                            <FontAwesomeIcon icon={faMinus} className="me-1" />
                                                            {totalCharges}
                                                        </td>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <th scope="row">Total from currency :</th>
                                                    <td style={{ color: "#556ee6", fontWeight: 'bold' }}>
                                                        <FontAwesomeIcon
                                                            icon={faEquals}
                                                            className="me-1"
                                                            style={{ color: "#556ee6" }}
                                                        />
                                                        {totalFromCurrency} {sendTransactionDetails.fromCurrType}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Exchange rate :</th>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={transactionType === 1 ? faX : faDivide}
                                                            className="me-1"
                                                        />
                                                        {sendTransactionDetails?.exchangeRate}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">To currency :</th>
                                                    <td>{toCurrency} {sendTransactionDetails.toCurrType}</td>
                                                </tr>
                                            </>
                                        )}
                                        {transactionType === 3 && sendTransactionDetails && (
                                            <>
                                                <tr>
                                                    <th scope="row">From currency :</th>
                                                    <td>
                                                        {sendTransactionDetails.fromCurrType} - {sendTransactionDetails.fromCurrValue}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">To currency :</th>
                                                    <td>
                                                        {sendTransactionDetails.toCurrType} - {sendTransactionDetails.toCurrValue}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Conversion rate :</th>
                                                    <td>
                                                        <Label>{sendTransactionDetails?.exchangeRate}</Label>
                                                    </td>
                                                </tr>
                                            </>
                                        )}
                                    </tbody>

                                </Table>
                            </div>
                        </Collapse>
                    </div>
                    <div className="mb-2">
                        <Button
                            className="w-100 bg-white border-0 shadow-none  text-dark fs-3 text-start d-flex justify-content-between align-items-center"
                            onClick={() => {
                                setCol_1(false);
                                setCol_2(!col_2)
                                setCol_3(false);

                            }}
                        >
                            Branch  <i className="fa fa-chevron-down " />
                        </Button>

                        <Collapse
                            isOpen={col_2}
                        >
                            <div style={{ maxHeight: '225px', overflowY: 'auto' }}>
                                <Table
                                    className="table-bordered table-nowrap border border-2 mt-2"
                                    style={{ maxWidth: '200px' }}
                                >
                                    <tbody>
                                        {branchData && branchData !== null && (
                                            <tr>
                                                <th>Branch :</th>
                                                <td>{branchData.branchName}</td>
                                            </tr>
                                        )}
                                    </tbody>

                                    <tbody>
                                        {sendTransactionDetails && (
                                            <tr>
                                                <th scope="row">Booked Date :</th>
                                                <td>{formatDateToUserTimeZone(sendTransactionDetails.transactionDate)}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>

                                <Label>Working Hours:</Label>

                                <Table
                                    className="table-bordered table-nowrap border border-2"
                                    style={{ maxWidth: '200px' }}
                                >
                                    <thead>
                                        <tr>
                                            <th>Day</th>
                                            <th>Start Time</th>
                                            <th>End Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {optime && optime.length > 0 ? (
                                            optime.map((operationalTime, index) => (
                                                <tr key={index}>
                                                    <td>{operationalTime.day}</td>
                                                    <td>{operationalTime.startTime}</td>
                                                    <td>{operationalTime.endTime}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="3" className="text-center">No operational time available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>

                                <Label>Branch Address:</Label>

                                <Table
                                    className="table-bordered table-nowrap border border-2"
                                    style={{ maxWidth: '200px' }}
                                >
                                    <tbody >
                                        {branchData && branchData !== null ? (
                                            <>
                                                <tr>
                                                    <th>Street :</th>
                                                    <td>{branchData.street}</td>
                                                </tr>
                                                <tr>
                                                    <th>City :</th>
                                                    <td>{branchData.city}</td>
                                                </tr>
                                                <tr>
                                                    <th>State :</th>
                                                    <td>{branchData.state}</td>
                                                </tr>
                                                <tr>
                                                    <th>Country :</th>
                                                    <td>{branchData.country}</td>
                                                </tr>
                                                <tr>
                                                    <th>Postal Code :</th>
                                                    <td>{branchData.postalCode}</td>
                                                </tr>
                                            </>
                                        ) : (
                                            <tr>
                                                <th className="text-center">No Address Added.</th>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </Collapse>
                    </div>

                    <div className="mb-2">

                        {transactionType === 3 && (
                            <>
                                <Button
                                    className="w-100 bg-white border-0 shadow-none text-dark fs-3 text-start d-flex justify-content-between align-items-center mt-2"
                                    onClick={() => {
                                        setCol_1(false);
                                        setCol_2(false);
                                        setCol_3(!col_3);
                                        setCol_4(false);

                                    }}
                                >
                                    <span>Beneficiary</span>
                                    <i className={`fa fa-chevron-${showBeneficiary ? "up" : "down"}`} />
                                </Button>

                                <Collapse isOpen={col_3}>
                                    <Table
                                        className="table-bordered table-nowrap border border-2"
                                        style={{ maxWidth: '200px' }}
                                    >
                                        <tbody>
                                            <tr>
                                                <th style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>Name:</th>
                                                <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{beneficiary?.beneficiaryName || "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>Account Number:</th>
                                                <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{beneficiary?.accountNumber || "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th style={{ padding: "8px", }}>Currency Name:</th>
                                                <td style={{ padding: "8px" }}>{beneficiary?.currencyName || "N/A"}</td>
                                            </tr>
                                        </tbody>
                                    </Table>


                                </Collapse>
                            </>
                        )}

                    </div>

                    <div className="mb-2">
                        <Button
                            className="w-100 bg-white border-0 shadow-none text-dark fs-3 text-start d-flex justify-content-between align-items-center"
                            onClick={() => {
                                setCol_1(false);
                                setCol_2(false);
                                setCol_3(false);
                                setCol_4(!col_4)

                            }}
                        >
                            Comment <i className="fa fa-chevron-down" />
                        </Button>

                        <Collapse
                            isOpen={col_4}

                        >
                            <div>
                                <div className="mt-1" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                    {commentData?.length > 0 ? (
                                        commentData.map((comment, index) => (
                                            <div key={index}>
                                                <hr />
                                                <span>
                                                    <h6>
                                                        {UserTypes[comment.userType]}
                                                    </h6>
                                                    <p>
                                                        Status:{"  "}
                                                        <Badge
                                                            className={
                                                                "font-size-11 badge-soft-" + mapStatus(comment.transactionStatus).color
                                                            }
                                                        >
                                                            {mapStatus(comment.transactionStatus).label}
                                                        </Badge>
                                                    </p>
                                                </span>
                                                <span>
                                                    <h6>
                                                        Comment:{"  "}
                                                        <b>
                                                            {comment.comments}
                                                        </b>
                                                    </h6>
                                                </span>
                                                <hr />
                                            </div>
                                        ))
                                    ) : (
                                        <div className="text-muted d-flex justify-content-center align-items-center" style={{ minHeight: "100px" }}>
                                            <h3>No comments to show</h3>
                                        </div>
                                    )}
                                </div>

                                {showAction === true && sendTransactionDetails?.status !== 13 && (
                                    <Button
                                        type="button"
                                        color="primary"
                                        className="btn-primary text-start mt-2"
                                        onClick={() => openCommentPopup(sendTransactionDetails.status)} >
                                        Comment
                                    </Button>
                                )}
                            </div>

                        </Collapse>
                    </div>

                </ModalBody>

                {showAction === true && (

                    <ModalFooter>

                        {sendTransactionDetails != null &&
                            sendTransactionDetails.status === 2

                            && (
                                <>
                                    <Button
                                        type="button"
                                        color="primary"
                                        className="btn-primary"
                                        onClick={() => openCommentPopup(8)}>
                                        Acknowledge
                                    </Button>
                                    <Button
                                        type="button"
                                        color="warning"
                                        className="btn-primary"
                                        onClick={() => openCommentPopup(3)}>
                                        Review
                                    </Button>
                                </>
                            )}

                        {sendTransactionDetails && sendTransactionDetails.status === 4 && (
                            <Button
                                type="button"
                                color="danger"
                                className="btn-primary"
                                disabled={calculateDateDifference() === 0 ? false : true}
                                onClick={() => openCommentPopup(9)}
                            >
                                Decline
                            </Button>
                        )}

                        {sendTransactionDetails != null && (
                            sendTransactionDetails.status === 1 ||
                            sendTransactionDetails.status === 2 ||
                            sendTransactionDetails.status === 3 ||
                            sendTransactionDetails.status === 8

                        ) && (
                                <Button
                                    type="button"
                                    color="danger"
                                    className="btn-primary"
                                    onClick={() => openCommentPopup(6)}>
                                    Decline
                                </Button>
                            )}

                        {sendTransactionDetails != null &&
                            sendTransactionDetails.status === 10
                            && (
                                <Button
                                    type="button"
                                    color="danger"
                                    className="btn-primary"
                                    onClick={() => openCommentPopup(9)}>
                                    Decline
                                </Button>

                            )}

                    </ModalFooter>

                )}
            </Modal>

            {/*Comment Modal*/}
            <Modal isOpen={isCommentOpen} toggle={closeCommentPopup}>
                <ModalHeader toggle={closeCommentPopup}>Add Comment</ModalHeader>
                <ModalBody>
                    <textarea
                        className="form-control"
                        rows="5"
                        value={commentText}
                        onChange={(e) => setCommentText(e.target.value)}
                        placeholder="Write your comment here..."
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleCommentSubmit}>
                        Submit
                    </Button>
                    <Button color="secondary" onClick={closeCommentPopup}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>

        </>
    );
};

ViewTransaction.propTypes = {
    toggle: PropTypes.func,
    isOpen: PropTypes.bool,
    commentData: PropTypes.array,
    sendTransactionDetails: PropTypes.object,
}

export default ViewTransaction