import React, { useEffect } from "react";
import PropTypes from "prop-types";
import withRouter from "../../components/Common/withRouter";
import { googleLogout } from '@react-oauth/google';

//redux
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const Logout = () => {
    const history = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        sessionStorage.removeItem("custUser");
        history("/login", { replace: true });
        googleLogout();
    }, [dispatch,history]);

    return <></>;
};

Logout.propTypes = {
    history: PropTypes.object,
};

export default withRouter(Logout);