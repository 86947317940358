import React from 'react';
import '../../../scss/custom/components/_loader.scss';

const Loader = () => {
    return (
        <div className="background-overlay" style={{ backgroundColor: "rgb(0, 0, 0, 0.1)" }}>
            <div className="overlay-content">
                <div className="loader">
                    <h3 className="loading-text">Loading</h3>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                </div>
            </div>
        </div>
    );
};

export default Loader;
