import React from "react";
import Loader from "../../components/Common/Loader";

const RenderStatusIcon = ({ statusData, loading }) => {
    function renderStatusIcon() {
        if (statusData != null) {
            if (statusData === "1") {
                return (
                    <span>
                        <i className="fa fa-exclamation-circle text-danger fa-lg ms-1 me-1" aria-hidden="true"></i>
                        <span className="tooltip-text text-danger fs-5">No data available</span>
                    </span>
                );
            } else if (statusData === "2") {
                return (
                    <span>
                        <i className="fa fa-info-circle text-warning fa-lg ms-1 me-1" aria-hidden="true"></i>
                        <span className="tooltip-text text-warning fs-5">Submit for verification</span>
                    </span>
                );
            } else if (statusData === "3") {
                return (
                    <span>
                        <i className="fa fa-check-circle text-info fa-lg ms-1 me-1" aria-hidden="true"></i>
                        <span className="tooltip-text text-info fs-5">Approval Pending</span>
                    </span>
                );
            } else if (statusData === "4") {
                return (
                    <span>
                        <i className="fa fa-check-circle text-success fa-lg ms-1 me-1" aria-hidden="true"></i>
                        <span className="tooltip-text text-primary fs-5">Verified</span>
                    </span>
                );
            }
        } else {
            return (
                <span>
                    <i className="fa fa-spinner fa-spin ms-1 me-1"></i> 
                </span>
            );
        }
    }

    return (
        <React.Fragment>
            {loading ? (
                <Loader />
            ) : (
                renderStatusIcon()
            )}
        </React.Fragment>
    );
};

export default RenderStatusIcon;
