import { drawBoundingBox } from "./drawMesh";
import * as faceLandmarksDetection from "@tensorflow-models/face-landmarks-detection";

export const runDetector = async (video, canvas, noFacedetect) => {
    const model = faceLandmarksDetection.SupportedModels.MediaPipeFaceMesh;
    const detectorConfig = { runtime: "tfjs" };
    const detector = await faceLandmarksDetection.createDetector(model, detectorConfig);

    const detect = async (net) => {
        const ctx = canvas.getContext("2d");

        if (video.videoWidth > 0 && video.videoHeight > 0) {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

            const faces = await net.estimateFaces(canvas);

            console.log(faces);
            if (faces.length !== 0) {
                requestAnimationFrame(() => drawBoundingBox(faces[0], ctx));
                detect(detector);
            } else {
                noFacedetect();
            }

        } else {
            requestAnimationFrame(() => detect(detector)); 
        }
    };

    detect(detector);
};
