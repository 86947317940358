import React, { createContext, useState } from 'react';

export const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
    const [profileData, setProfileData] = useState();

    return (
        <ProfileContext.Provider value={{
            profileData,
            setProfileData,
        }}>
            {children}
        </ProfileContext.Provider>
    );
};

export const BaseCurrencyContext = createContext();

export const CurrencyProvider = ({ children }) => {
    const [currencyData, setCurrencyData] = useState();

    return (
        <BaseCurrencyContext.Provider value={{
            currencyData,
            setCurrencyData,
        }}>
            {children}
        </BaseCurrencyContext.Provider>
    );
};