import React, { useState } from "react";
import {
    Col,
    Row,
    Card,
    Form,
    Label,
    Input,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button,
    CardBody,
    Container,
    FormFeedback,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { postApiData } from "../../helpers/axiosHelper";
import RequiredAsterisk from "../../components/Common/RequiredAsterisk";

//Redux"
import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Images
import logoImg from "../../../images/crescent_logo.png";
import profileImg from "../../../images/profile-img.png";

//Other Authentication
import { GoogleLogin } from '@react-oauth/google';

const Register = () => {

    document.title = "Crescent Exchange | Register";

    const navigate = useNavigate();
    const [isAuth, setIsAuth] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [modal, setModal] = useState(false);

    const toggleModal = () => setModal(!modal);


    const handleSuccess = (credentialResponse) => {
        const authorizationCode = credentialResponse.credential;

        postApiData('api/User/RegisterWithGoogle',
            JSON.stringify({ idToken: authorizationCode }),
        )
            .then(response => response)
            .then(data => {
                console.log('Register successful, backend response:', data);
                sessionStorage.setItem("authUser", JSON.stringify(data));
                if (data.success === true) {
                    toast.success("Register Successfully", {
                        position: "top-right",
                        autoClose: 3000,
                        onClose: () => {
                            navigate("/login");
                        },
                    });
                }
                else {
                    toast.warning("Registration Failed", {
                        position: "top-right",
                        autoClose: 3000,

                    });
                }
            })
            .catch(error => {
                console.error('Error exchanging authorization code:', error);
            });
    };

    const handleError = (errorResponse) => {
        console.error('Google login failed', errorResponse);
    };
    const validation = useFormik({

        enableReinitialize: true,

        initialValues: {
            dob: "",
            email: "",
            mobile: "",
            password: "",
            lastname: "",
            firstname: "",
            middlename: "",
            confirmPassword: "",

            policy: false,
        },

        validationSchema: Yup.object({
            firstname: Yup.string()
                .max(50, "Max character's reached.")
                .min(3, "Atleast 3 letter's required.")
                .required("Please Enter Your First name")
                .matches(/^[a-zA-Z\s]*$/, "Number's cannot be a Name"),
            middlename: Yup.string()
                .max(50, "Max character's reached.")
                .min(3, "Atleast 3 letter's required.")
                .matches(/^[a-zA-Z\s]*$/, "Number's cannot be a Name"),
            lastname: Yup.string()
                .max(50, "Max character's reached.")
                .min(3, "Atleast 3 letter's required.")
                .required("Please Enter Your Last name")
                .matches(/^[a-zA-Z\s]*$/, "Number's cannot be a Name"),
            email: Yup.string()
                .email("Invalid Email address.")
                .required("Please Enter Your Email"),
            mobile: Yup.string()
                .required("Please Enter Your Mobile Number")
                .matches(/^\+?\d{8,14}$/, "Mobile Number must be 8-14 digits")
                .matches(/^[0-9]*$/, 'Please enter only numbers in the Mobile field'),
            password: Yup.string()
                .required("Please Enter Your Password")
                .min(8, 'Password must be 8 characters long')
                .matches(/[^\w]/, 'Password requires a symbol')
                .matches(/[0-9]/, 'Password requires a number')
                .matches(/[a-z]/, 'Password requires a lowercase letter')
                .matches(/[A-Z]/, 'Password requires an uppercase letter'),
            confirmPassword: Yup.string()
                .required("Please Enter Your Password to Confirm")
                .required("Confirm password is required")
                .oneOf([Yup.ref("password"), null], "Passwords must match"),
            policy: Yup.boolean()
                .oneOf([true], "You must agree to the terms and conditions"),
        }),

        onSubmit: async (values, { resetForm }) => {
            setIsLoading(true);
            values.dob = `${values.dob}T00:00:00.000Z`;
            const response = await postApiData(
                "api/User/Register",
                JSON.stringify(values)
            );
            if (response.success === true) {
                if (response.data.uid) {
                    values.uid = response.data.uid;
                    values.email = response.data.email;
                    toast.success(
                        "User Registered Successful, redirecting to verification",
                        {
                            position: "top-right",
                            autoClose: 3000,
                            onClose: () => {
                                navigate(`/emailverification/${response.data.uid}`);
                                setIsLoading(false);
                            },
                        }
                    );
                }

            } else {
                toast.error(response.message, {
                    position: "top-right",
                    autoClose: 3000,
                });
                resetForm({ values: { ...values, policy: false } });
                setIsLoading(false);
            }
        },
    });

    return (
        <React.Fragment>
            <ToastContainer closeButton={false} limit={1} />

            <div className="home-btn d-none d-sm-block">
                <Link to="/home" className="text-dark">
                    <i className="bx bx-home h2" />
                </Link>
            </div>

            <div className="account-pages my-3 pt-sm-3">
                <Container>
                    <Row className="justify-content-center">

                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary-subtle">
                                    <Row>
                                        <Col className="col-7">
                                            <div className="text-primary p-3">
                                                <h5 className="text-primary">Registration</h5>
                                                <p>Get your Crescent Exchange account now.</p>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-end">
                                            <img src={profileImg} alt="Banner.png" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">

                                    <div className="avatar-md profile-user-wid mb-2">
                                        <Link to="/" >
                                            <span className="avatar-title rounded-circle bg-light w-75 h-75 border border-dark border-2">
                                                <img
                                                    src={logoImg}
                                                    alt="crescent.png"
                                                    className="rounded-circle w-75"

                                                />
                                            </span>
                                        </Link>
                                    </div>

                                    <div className="p-0">
                                        <Form
                                            className="form-horizontal"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >

                                            <Row>

                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label">First Name</Label>
                                                        <RequiredAsterisk />
                                                        <Input
                                                            id="firstname"
                                                            name="firstname"
                                                            className="form-control"
                                                            placeholder="Enter First Name"
                                                            type="text"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.firstname || ""}
                                                            disabled={isLoading || isAuth}
                                                            invalid={
                                                                validation.touched.firstname &&
                                                                    validation.errors.firstname
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validation.touched.firstname &&
                                                            validation.errors.firstname ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.firstname}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label"> Middle Name</Label>
                                                        <Input
                                                            id="middlename"
                                                            name="middlename"
                                                            className="form-control"
                                                            placeholder="Optional"
                                                            type="input"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.middlename || ""}
                                                            disabled={isLoading || isAuth}
                                                            invalid={
                                                                validation.touched.middlename &&
                                                                    validation.errors.middlename
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validation.touched.middlename &&
                                                            validation.errors.middlename ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.middlename}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                            </Row>

                                            <Row>

                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Last Name</Label>
                                                        <RequiredAsterisk />
                                                        <Input
                                                            id="lastname"
                                                            name="lastname"
                                                            className="form-control"
                                                            placeholder="Enter Last Name"
                                                            type="input"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.lastname || ""}
                                                            disabled={isLoading || isAuth}
                                                            invalid={
                                                                validation.touched.lastname &&
                                                                    validation.errors.lastname
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validation.touched.lastname &&
                                                            validation.errors.lastname ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.lastname}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Mobile</Label>
                                                        <RequiredAsterisk />
                                                        <Input
                                                            id="mobile"
                                                            name="mobile"
                                                            className="form-control"
                                                            placeholder="Enter Number"
                                                            type="input"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.mobile || ""}
                                                            disabled={isLoading || isAuth}
                                                            invalid={
                                                                validation.touched.mobile &&
                                                                    validation.errors.mobile
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validation.touched.mobile &&
                                                            validation.errors.mobile ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.mobile}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>

                                                </Col>

                                            </Row>

                                            <Row>

                                                <Col lg={7}>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Email</Label>
                                                        <RequiredAsterisk />
                                                        <Input
                                                            id="email"
                                                            type="email"
                                                            className="form-control"
                                                            placeholder="example@gmail.com"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.email || ""}
                                                            disabled={isLoading || isAuth}
                                                            invalid={
                                                                validation.touched.email && validation.errors.email ? true : false
                                                            }
                                                        />
                                                        {validation.touched.email && validation.errors.email ? (
                                                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                        ) : null}
                                                    </div>

                                                </Col>

                                                <Col lg={5}>
                                                    <div className="mb-3">
                                                        <Label for="dob">Date of Birth</Label>
                                                        <RequiredAsterisk />
                                                        <Input
                                                            id="dob"
                                                            name="dob"
                                                            type="date"
                                                            pattern="\d{4}-\d{2}-\d{2}"
                                                            className="form-control"
                                                            disabled={isLoading || isAuth}
                                                            onBlur={validation.handleBlur}
                                                            onChange={validation.handleChange}
                                                            value={validation.values.dob || null}
                                                            invalid={
                                                                validation.touched.dob && validation.errors.dob ? true : false
                                                            }
                                                        />
                                                        <FormFeedback type="invalid">{validation.errors.dob}</FormFeedback>

                                                    </div>
                                                </Col>

                                            </Row>


                                            <Row>

                                                <Col>
                                                    <div className="mb-2">
                                                        <Label className="form-label">Password</Label>
                                                        <RequiredAsterisk />
                                                        <Input
                                                            name="password"
                                                            type="password"
                                                            placeholder="Enter Password"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.password || ""}
                                                            disabled={isLoading || isAuth}
                                                            invalid={
                                                                validation.touched.password &&
                                                                    validation.errors.password
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validation.touched.password &&
                                                            validation.errors.password ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.password}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                                <Col>
                                                    <div className="mb-2">
                                                        <Label className="form-label"> Confirm Password</Label>
                                                        <RequiredAsterisk />
                                                        <Input
                                                            name="confirmPassword"
                                                            type="password"
                                                            placeholder="Password"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.confirmPassword || ""}
                                                            disabled={isLoading || isAuth}
                                                            invalid={
                                                                validation.touched.confirmPassword &&
                                                                    validation.errors.confirmPassword
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validation.touched.confirmPassword &&
                                                            validation.errors.confirmPassword ? (
                                                            <FormFeedback type="invalid">
                                                                <div>{validation.errors.confirmPassword}</div>
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>

                                                </Col>

                                            </Row>
                                            <div className="mt-2 text-center">
                                                <p>
                                                    <Input
                                                        id="policy"
                                                        name="policy"
                                                        type="checkbox"
                                                        initialValues={validation.initialValues.policy || false}
                                                        // checked={validation.values.policy === true || false}
                                                        onChange={validation.handleChange || null}
                                                        onBlur={validation.handleBlur}
                                                        disabled={isLoading || isAuth}
                                                        invalid={validation.touched.policy &&
                                                            validation.errors.policy
                                                            ? true
                                                            : false}
                                                    />{" "}
                                                    Click here to agree to the terms and conditions <br /> of Crescent Exchange{" "}
                                                    <Link to="#" className="text-primary" onClick={toggleModal}>
                                                        Terms of Use
                                                    </Link>
                                                </p>
                                                {validation.touched.policy && validation.errors.policy ? (
                                                    <div className="invalid-feedback h-5" style={{ display: 'block' }}>
                                                        {validation.errors.policy}
                                                    </div>
                                                ) : null}
                                            </div>

                                            {/* Modal for Terms of Use */}
                                            <Modal isOpen={modal} toggle={toggleModal}>
                                                <ModalHeader toggle={toggleModal}>Terms of Use</ModalHeader>
                                                <ModalBody>
                                                    <p className="sub-header">
                                                        1. By using our money-changing and remittance services, you agree to comply with the following terms and conditions.
                                                    </p>
                                                    <p>2. We reserve the right to modify or terminate our services at any time without prior notice.</p>
                                                    <p>3. You are responsible for providing accurate and up-to-date information during the registration and transaction process.</p>
                                                    <p>4. Our services are subject to applicable laws and regulations regarding money-changing and remittance.</p>
                                                    <p>5. The exchange rates provided are based on real-time market conditions and may be subject to fluctuations.</p>
                                                    <p className="mb-0">
                                                        6. We strive to ensure the security and confidentiality of your personal information as outlined in our Privacy Policy.
                                                    </p>
                                                </ModalBody>

                                            </Modal>

                                            <Row>

                                                <div className="mt-2 text-end">
                                                    <button
                                                        type="submit"
                                                        disabled={isLoading || isAuth}
                                                        className="btn btn-primary"
                                                    >
                                                        {isLoading ? "Registering..." : "Register"}

                                                    </button>
                                                </div>

                                            </Row>

                                            <hr />

                                            <div className="mt-2 text-center">

                                                <h5 className="font-size-14 mb-2">Register with</h5>

                                                <Row>

                                                    <Col className="lg-6 sm-6">
                                                        <GoogleLogin
                                                            onSuccess={handleSuccess}
                                                            onError={handleError}
                                                        />
                                                    </Col>

                                                    <Col className="lg-6 sm-6">
                                                        <Button
                                                            className="singpass-button"
                                                            disabled={isLoading || isAuth}
                                                        >
                                                            Register with Singpass
                                                        </Button>
                                                    </Col>

                                                </Row>

                                            </div>

                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>

                            <div className="mt-3 text-center">
                                <p>
                                    Already have an account ?{" "}
                                    <Link to="/login" className="font-weight-medium text-primary">
                                        {" "}
                                        <b>
                                            LOGIN
                                        </b>
                                    </Link>{" "}
                                </p>
                            </div>

                        </Col>

                    </Row>
                </Container>
            </div>

        </React.Fragment>
    );
};

export default withRouter(Register);