import React, { useEffect, useState } from "react";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBell,
    faBellSlash,
    faExchangeAlt,
    faClock,
    faCalendarDay,
    faCalendarAlt,
    faTasks,
    faInfoCircle,
    faSyncAlt,
    faCheckCircle,
    faQuestionCircle
} from "@fortawesome/free-solid-svg-icons";
import { getApiData, putApiData } from "./../../../helpers/axiosHelper";
import { Tooltip } from "reactstrap";
export const NotificationType = { Action: 1, Info: 2 };

const isMobile = window.innerWidth <= 768;



const handleHelpClick = () => {
    window.open('https://sitapi.crescentexchange.com/help/customer/Notifications.pdf');
};
const Notifications = () => {
    const [notification, setNotification] = useState({
        bellMenu: false,
        activeTab: 1,
        totalCount: 0,
        actionCount: 0,
        infoCount: 0,
        actionNotifications: { today: [], yesterday: [], older: [] },
        infoNotifications: { today: [], yesterday: [], older: [] },
    });

    const [loading, setLoading] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const handleRefresh = async () => {
        try {
            setLoading(true); 
            await getNotifications(); 
        } finally {
            setLoading(false); 
        }
    };

    const toggleBellMenu = () =>
        setNotification((prev) => ({ ...prev, bellMenu: !prev.bellMenu }));

    const toggleTab = (tab) =>
        setNotification((prev) => ({ ...prev, activeTab: tab }));

    const categorizeNotifications = (notifications) => {
        const today = new Date();
        const startOfToday = new Date(today.setHours(0, 0, 0, 0));
        const startOfYesterday = new Date(today.setDate(today.getDate() - 1));

        return notifications.reduce(
            (acc, notification) => {
                const date = new Date(notification.date);
                if (date >= startOfToday) acc.today.push(notification);
                else if (date >= startOfYesterday) acc.yesterday.push(notification);
                else acc.older.push(notification);
                return acc;
            },
            { today: [], yesterday: [], older: [] }
        );
    };

    const getNotificationTitle = (id) => ({
        1: "Transaction",
        2: "Profile",
    }[id] || `Title ${id}`);

    const getNotifications = async () => {
        const resp = await getApiData("api/CustomerNotification/GetNotification");

        const notifications = resp?.map((x) => ({
            title: getNotificationTitle(x.title),
            desc: x.description,
            value: x.notificationID,
            date: x.createdAt,
            type: x.type,
        }));

        const actionNotifications = notifications.filter(
            (x) => x.type === NotificationType.Action
        );
        const infoNotifications = notifications.filter(
            (x) => x.type === NotificationType.Info
        );

        setNotification((prev) => ({
            ...prev,
            totalCount: notifications.length,
            actionCount: actionNotifications.length,
            infoCount: infoNotifications.length,
            actionNotifications: categorizeNotifications(actionNotifications),
            infoNotifications: categorizeNotifications(infoNotifications),
        }));
    };

    useEffect(() => {
        getNotifications();
    }, []);

    const formatTime = (date) =>
        new Date(date).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
        });

    const handleNotificationClick = async (notificationID, type) => {
        try {
            await putApiData(
                `api/CustomerNotification/ReadNotification?NotificationId=${notificationID}`
            );

            setNotification((prev) => {
                const updatedActionNotifications = removeNotification(
                    prev.actionNotifications,
                    notificationID
                );
                const updatedInfoNotifications = removeNotification(
                    prev.infoNotifications,
                    notificationID
                );

                return {
                    ...prev,
                    actionNotifications: updatedActionNotifications,
                    infoNotifications: updatedInfoNotifications,
                    actionCount:
                        type === NotificationType.Action
                            ? prev.actionCount - 1
                            : prev.actionCount,
                    infoCount:
                        type === NotificationType.Info
                            ? prev.infoCount - 1
                            : prev.infoCount,
                };
            });
        } catch (error) {
            console.log("Error marking notification as read:", error);
        }
    };

    const removeNotification = (notifications, notificationID) => {
        return Object.fromEntries(
            Object.entries(notifications).map(([key, items]) => [
                key,
                items.filter((item) => item.value !== notificationID),
            ])
        );
    };
    const renderEmpty = () => (
        <div className="d-flex flex-column gap-3 align-items-center justify-content-center">
            <FontAwesomeIcon icon={faBellSlash} shake size="2xl" className="mt-4" color="#FF5252" />
            <p>No unread notifications</p>
        </div>
    );

    const renderNotificationItems = (items, showDate = false) =>
        items.map((x) => (
            <DropdownItem
                key={x.value}
                className="d-flex"
                
            >
               
                   
                    <div>
                        <strong>{x.title}</strong>
                        <p className="mb-0">{x.desc}</p>
                    </div>
               
                <small className="text-muted">
                    {showDate ? formatDate(x.date) + ' ' : ''} 
                    {formatTime(x.date)}
                </small>
                <FontAwesomeIcon
                    icon={faCheckCircle}
                    size="sm"
                    color="#28a745"
                    className="ms-2"
                    onClick={(e) => {
                        e.stopPropagation();
                        handleNotificationClick(x.value, x.type);
                    }}
                    title="Mark as read"
                />
            </DropdownItem>
        ));

    const formatDate = (date) =>
        new Date(date).toLocaleDateString([], {
            month: 'short',
            day: 'numeric',
        });

    const renderTabContent = (notifications) =>
        Object.entries(notifications)
            .filter(([_, items]) => items.length > 0)
            .map(([category, items]) => (
                <div key={category} className="mt-3">
                    <h5 className="d-flex align-items-center">
                        <FontAwesomeIcon
                            icon={getCategoryIcon(category)}
                            size="sm"
                            color={getIconColor(category)}
                            className="me-2"
                        />
                        {category.charAt(0).toUpperCase() + category.slice(1)}
                    </h5>
                    {renderNotificationItems(items, category === 'older')}
                </div>
            ));


    const getCategoryIcon = (category) => ({
        today: faCalendarDay,
        yesterday: faClock,
        older: faCalendarAlt,
    }[category]);

    const getIconColor = (category) => ({
        today: "#556ee6",
        yesterday: "#50a5f1",
        older: "#9E9E9E",
    }[category]);

    const getCategoryColor = (date) => {
        const today = new Date().setHours(0, 0, 0, 0);
        const yesterday = new Date(today).setDate(new Date().getDate() - 1);
        const notificationDate = new Date(date);

        return notificationDate >= today
            ? "#34c38f"
            : notificationDate >= yesterday
                ? "#FFC107"
                : "#9E9E9E";
    };

  

    const { bellMenu, activeTab, actionNotifications, infoNotifications } = notification;

    return (
        <Dropdown isOpen={bellMenu} toggle={toggleBellMenu} className="ms-3">
            <DropdownToggle tag="button" className="btn header-item position-relative">
                <FontAwesomeIcon icon={faBell} size="lg" />
                {notification.totalCount > 0 && (
                    <span className="badge bg-danger rounded-circle position-absolute top-0 start-100 translate-middle mt-4 ms-n2">
                        {notification.totalCount}
                    </span>
                )}
            </DropdownToggle>
            
            <DropdownMenu
                className="dropdown-menu-end responsive-dropdown p-3"
                style={{
                    maxHeight: '500px',
                   
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    padding: '10px',
                    minWidth: isMobile ? "30vw" : "500px",
                }}
            >

                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5>Notifications</h5>
                    <div className="d-flex justify-content-between  ">
                    <FontAwesomeIcon
                        icon={faSyncAlt}
                        style={{
                            cursor: "pointer",
                            transition: "transform 0.5s linear",
                            transform: loading ? "rotate(360deg)" : "rotate(0deg)",
                        }}
                        onClick={handleRefresh}
                    />
                    <FontAwesomeIcon
                        icon={faQuestionCircle}
                        onClick={handleHelpClick}
                        color="#556ee6"
                        id="helpIcon"

                        style={{ fontSize: '1.2em', cursor: "pointer", marginLeft:'2px' }}
                    />
                    <Tooltip
                        placement="top"
                        isOpen={tooltipOpen}
                        target="helpIcon"
                        toggle={() => setTooltipOpen(!tooltipOpen)}
                    >
                        Help
                    </Tooltip>
                </div>
                
                    
                </div>

                <Nav tabs className="mb-3">
                    {[{ name: `Actions (${notification.actionCount})`, icon: faTasks },
                    { name: `Info (${notification.infoCount})`, icon: faInfoCircle },
                    ].map((tab, idx) => (
                        <NavItem key={idx}>
                            <NavLink
                                className={`d-flex align-items-center ${activeTab === idx + 1 ? "active" : ""}`}
                                style={{
                                    backgroundColor: activeTab === idx + 1 ? "#eff2f7" : "transparent", 
                                }}
                                onClick={() => toggleTab(idx + 1)}
                            >
                                <FontAwesomeIcon
                                    icon={tab.icon}
                                    size="lg"
                                    color={idx === 0 ? "#556ee6" : "#f46a6a"}
                                    className="me-2"
                                />
                                {tab.name}
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav>

                <TabContent activeTab={activeTab}  >
                    <TabPane tabId={1}  >
                        {Object.values(actionNotifications).some((arr) => arr.length)
                            ? renderTabContent(actionNotifications)
                            : renderEmpty()}
                    </TabPane>
                    <TabPane tabId={2}>
                        {Object.values(infoNotifications).some((arr) => arr.length)
                            ? renderTabContent(infoNotifications)
                            : renderEmpty()}
                    </TabPane>
                </TabContent>
            </DropdownMenu>
        </Dropdown>
    );
};

export default Notifications;
