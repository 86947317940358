import React from "react";
import { Container, Row, Col } from "reactstrap";

//Import Components
import FeatureBox from "./feature-box";

//Import images
import ForeignPremises from "./../../../../../images/crypto/features-img/ForeignPremises.png";
import LocalPremises from "./../../../../../images/crypto/features-img/LocalPremises.png";
import OnlineRemittance from "./../../../../../images/crypto/features-img/OnlineRemittance.png";
import RetailRemittance from "./../../../../../images/crypto/features-img/RetailRemittance.png";
import OverTheCounter from "./../../../../../images/crypto/features-img/OverTheCounter.png";

const Features = () => {
  const rmcfeatures = [
    { id: 1, desc: "Experienced team" },
    { id: 2, desc: "Competitive exchange rates" },
  ];
  const orfeatures = [
    { id: 1, desc: "Online remittance service with just a few clicks" },
    { id: 2, desc: "Low fees, and fast and reliable transfers" },
  ];
  const omclfeatures = [
    { id: 1, desc: "Place your currency exchange order online" },
    { id: 2, desc: "Collect the exchanged currency at our local premises" },
    { id: 3, desc: "Flexibility and ease of online currency exchange" },
  ];
  const omcffeatures = [
    { id: 1, desc: "Place your currency exchange order online" },
    { id: 2, desc: "Collect the exchanged currency at our foreign premises" },
    { id: 3, desc: "Flexibility and ease of online currency exchange" },
    { id: 4, desc: "You can have the local currency ready before reaching your destination" },
  ];
  const rrcfeatures = [
    {
      id: 1,
      desc: "Allows you to send money to your loved ones quickly and securely",
    },
    { id: 2, desc: "Initiate a remittance transaction in our premises" },
    {
      id: 3,
      desc:
        "Staff will assist you in transferring funds to your desired destination.",
    },
    { id: 4, desc: "Low fees, and fast and reliable transfers" },
  ];

  return (
    <React.Fragment>
      <section className="section" id="features">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <h4>Services</h4>
              </div>
            </Col>
          </Row>

          <Row className="align-items-center pt-4">
            <Col md="5">
              {/* featurebox */}
              <FeatureBox
                num="01"
                title="Retail Money-Changing"
                subtitle="(Over-the-Counter)"
                features={rmcfeatures}
                desc="Our retail money-changing service allows you to conveniently exchange your currency in person at our local premises. Our experienced team will provide you with competitive exchange rates and excellent customer service."
              />
            </Col>
            <Col md="7" sm="12" className="ms-md-auto">
              <div>
                <img
                  src={OverTheCounter}
                  alt=""
                  className="img-fluid mx-auto d-block"
                />
              </div>
            </Col>
          </Row>

          <Row className="align-items-center mt-5 pt-md-5">
            <Col md="5">
              {/* featurebox */}
              <FeatureBox
                num="02"
                title="Online Money-Changing"
                subtitle="(Collection at Local Premises)"
                features={omclfeatures}
                desc="With our online money-changing service, you can conveniently place your currency exchange order online and collect the exchanged currency at our local premises. Enjoy the flexibility and ease of online currency exchange."
              />
            </Col>
            <Col md="7" sm="12" className="ms-md-auto">
              <div className="mt-4 me-md-0">
                <img
                  src={LocalPremises}
                  alt=""
                  className="img-fluid mx-auto d-block"
                />
              </div>
            </Col>
          </Row>

          <Row className="align-items-center mt-5 pt-md-5">
            <Col md="5">
              {/* featurebox */}
              <FeatureBox
                num="03"
                title="Online Money-Changing"
                subtitle="(Collection at Foreign Premises)"
                features={omcffeatures}
                desc="Our online money-changing service also offers the option to collect your exchanged currency at foreign premises. This service is ideal for travelers who prefer to have local currency ready before reaching their destination be it locally or overseas."
              />
            </Col>
            <Col md="7" sm="12" className="ms-md-auto">
              <div className="mt-4 me-md-0">
                <img
                  src={ForeignPremises}
                  alt=""
                  className="img-fluid mx-auto d-block"
                />
              </div>
            </Col>
          </Row>

          <Row className="align-items-center mt-5 pt-md-5">
            <Col md="5">
              {/* featurebox */}
              <FeatureBox
                num="04"
                title="Retail Remittance"
                subtitle="(Over-the-Counter)"
                features={rrcfeatures}
                desc="Our retail remittance service allows you to send money to your loved ones quickly and securely. Visit our premises to initiate a remittance transaction, and our dedicated staff will assist you in transferring funds to your desired destination."
              />
            </Col>
            <Col md="7" sm="12" className="ms-md-auto">
              <div className="mt-4 me-md-0">
                <img
                  src={RetailRemittance}
                  alt=""
                  className="img-fluid mx-auto d-block"
                />
              </div>
            </Col>
          </Row>

          <Row className="align-items-center mt-5">
            <Col md="5">
              {/* featurebox */}
              <FeatureBox
                num="05"
                title="Online Remittance"
                subtitle="&nbsp;"
                features={orfeatures}
                desc="Experience the convenience of our online remittance service, where you can send money to your beneficiaries with just a few clicks. Enjoy competitive exchange rates, low fees, and fast and reliable transfers for your remittance needs."
              />
            </Col>
            <Col md="7" sm="12" className="ms-md-auto">
              <div className="mt-4 me-md-0">
                <img
                  src={OnlineRemittance}
                  alt=""
                  className="img-fluid mx-auto d-block"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Features;
