import PropTypes from "prop-types";
import React, { useState } from "react";
import {
    Col,
    Row,
    Card,
    Form,
    Label,
    Input,
    Button,
    CardBody,
    Container,
    FormFeedback,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import axios, { postApiData } from "../../helpers/axiosHelper";
import RequiredAsterisk from "../../components/Common/RequiredAsterisk";

//Redux
import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import profile from "../../../images/profile-img.png";
import logo from "../../../images/crescent_logo.png"

//Other Authentication
import { GoogleLogin } from '@react-oauth/google';



const Login = () => {

    document.title = "Crescent Exchange | Login";

    const navigate = useNavigate();
    const [isAuth, setIsAuth] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const handleSuccess = (credentialResponse) => {
        console.log(credentialResponse)
        const authorizationCode = credentialResponse.credential;
        setIsAuth(true);
        postApiData('api/User/LoginWithGoogle',
            JSON.stringify({ idToken: authorizationCode }),
        )
            .then(response => response)
            .then(data => {
                if (data.success === true) {
                    if (data.data.token) {
                        const logged_user = {
                            login: true,
                            uid: data.data.uid,
                            email: data.data.username,
                            token: data.data.token,
                            userType: data.data.userType,
                            name: data.data.name
                        };

                        sessionStorage.setItem("custUser", JSON.stringify(logged_user));
                        toast.success("Customer Login Successful", {
                            position: "top-right",
                            autoClose: 3000,
                            onClose: () => {
                                navigate("/dashboard");
                                validation.resetForm();
                                setIsAuth(false);
                            },
                        });
                    }
                }
                else {
                    toast.warning("Login Failed", {
                        position: "top-right",
                        autoClose: 3000,
                        onClose: () => {
                            validation.resetForm();
                            setIsAuth(false);
                        }

                    });
                }
            })
            .catch(error => {
                console.error("Error occuried", error);
            });
    }

    const handleError = (errorResponse) => {
        toast.error('Google login failed', errorResponse);
    };

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            login: "",
            password: "",
        },

        validationSchema: Yup.object({
            login: Yup.string()
                .required("Please enter your Mobile Number or Email")
                .test("is-email-or-phone", "Invalid Mobile Number or Email", function (value) {
                    const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
                    const isPhone = /^\+?[0-9]{8,14}$/.test(value);
                    return isEmail || isPhone;
                }),
            password: Yup.string()
                .required("Please Enter Your Password")
                .min(8, 'Password must be 8 characters long')
                .matches(/[^\w]/, 'Password requires a symbol')
                .matches(/[0-9]/, 'Password requires a number')
                .matches(/[a-z]/, 'Password requires a lowercase letter')
                .matches(/[A-Z]/, 'Password requires an uppercase letter'),
        }),

        onSubmit: async (values, { resetForm }) => {
            try {
                setIsLoading(true);
                const response = await postApiData(
                    "api/Login/CustomerLogin",
                    JSON.stringify(values),
                );
                if (response.success === true) {
                    if (response.data.token) {
                        values.userType = response.data.userType;
                        values.token = response.data.token;
                        const logged_user = {
                            login: true,
                            uid: response.data.uid,
                            name: response.data.name,
                            roles: response.data.roles,
                            token: response.data.token,
                            email: response.data.username,
                            userType: response.data.userType,

                        };
                        const expirValidation = {
                            uid: response.data.uid,
                            expiration: response.data.expiration,
                        };
                        axios.defaults.headers.common["Authorization"] = "Bearer " + response.token;
                        sessionStorage.setItem("custUser", JSON.stringify(logged_user));
                        sessionStorage.setItem("forceLogout", "false");
                        sessionStorage.setItem("expirValidation", JSON.stringify(expirValidation));
                        if (response.data.userType === "Customer") {
                            toast.success("Customer Login Successful", {
                                position: "top-right",
                                autoClose: 3000,
                                onClose: () => {
                                    navigate("/dashboard", { replace: true });
                                    setIsLoading(false);
                                },
                            });
                        } else {
                            toast.error(response.message, {
                                position: "top-right",
                                autoClose: 3000,
                            });
                            resetForm();
                            setIsLoading(false);
                        }
                    } else {
                        toast.warning("User verification Required", {
                            position: "top-right",
                            autoClose: 3000,
                            onClose: () => {
                                navigate(`/emailverification/${response.data.uid}`);
                                setIsLoading(false);
                            },
                        });
                    }

                } else {
                    toast.error(response.message, {
                        position: "top-right",
                        autoClose: 3000,
                    });
                    resetForm();
                    setIsLoading(false);
                }


            } catch (error) {
                console.error("Error occuried", error);
                resetForm();
                setIsLoading(false);
            }

        },
    });

    return (
        <React.Fragment>
            <ToastContainer closeButton={false} limit={1} />

            <div className="home-btn d-none d-sm-block">
                <Link to="/home" className="text-dark" >
                    <i className="bx bx-home h2" />
                </Link>
            </div>

            <div className="account-pages my-2 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">

                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary-subtle">
                                    <Row>
                                        <Col xs={7}>
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary">Welcome Back, User!</h5>
                                                <p>Log In to continue using Crescent Exchange.</p>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-end">
                                            <img src={profile} alt="" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">

                                    <div className="avatar-md profile-user-wid mb-2">
                                        <Link to="/" className="fw-medium text-primary" >
                                            <span className="avatar-title rounded-circle bg-light w-75 h-75 border border-dark border-2">
                                                <img
                                                    src={logo}
                                                    alt="crescent.png"
                                                    className="rounded-circle w-75"

                                                />
                                            </span>
                                        </Link>
                                    </div>

                                    <Form
                                        className="form-horizontal"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <div className="mb-2">
                                            <Label
                                                className="text-primary fw-bold"
                                            >
                                                Email
                                            </Label>
                                            <Label className='ms-2 me-2'>/</Label>
                                            <Label
                                                className="text-primary fw-bold"
                                            >
                                                Mobile Number
                                            </Label>
                                            <Input
                                                id="login"
                                                className="form-control"
                                                placeholder={"Enter either Email or Mobile number."}
                                                disabled={isLoading || isAuth}
                                                onBlur={validation.handleBlur}
                                                onChange={validation.handleChange}
                                                value={validation.values.login || ""}
                                                invalid={
                                                    validation.touched.login && validation.errors.login
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {validation.touched.login && validation.errors.login ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.login}
                                                </FormFeedback>
                                            ) : null}
                                        </div>

                                        <div className="mb-2">
                                            <Label className="form-label">Password</Label>
                                            <RequiredAsterisk />
                                            <Input
                                                type="password"
                                                name="password"
                                                className="form-control"
                                                placeholder="Enter Password"
                                                disabled={isLoading || isAuth}
                                                onBlur={validation.handleBlur}
                                                onChange={validation.handleChange}
                                                value={validation.values.password || ""}
                                                invalid={
                                                    validation.touched.password &&
                                                        validation.errors.password
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {validation.touched.password &&
                                                validation.errors.password ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.password}
                                                </FormFeedback>
                                            ) : null}
                                        </div>

                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customControlInline"
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customControlInline"
                                                >
                                                    Remember me
                                                </label>
                                            </div>

                                            <Link
                                                to="/forgot-password"
                                                className="text-muted"
                                            >
                                                <i className="mdi mdi-lock me-1" />
                                                Forgot password?
                                            </Link>
                                        </div>

                                        <div className="mt-2 d-grid">
                                            <button
                                                className="btn btn-primary btn-block"
                                                type="submit"
                                                disabled={isLoading || isAuth}
                                            >
                                                <b> {isLoading ? "Logging in.... " : "Login"}</b>
                                            </button>
                                        </div>

                                        <hr />

                                        <div className="mt-2 text-center">

                                            <h5 className="font-size-14 mb-2">Login with</h5>

                                            <Row>

                                                <Col className="lg-6 sm-6">
                                                    <GoogleLogin
                                                        onSuccess={handleSuccess}
                                                        onError={handleError} disabled={isLoading || isAuth}
                                                    />
                                                </Col>

                                                <Col className="lg-6 sm-6">
                                                    <Button
                                                        className="singpass-button"
                                                        disabled={isLoading || isAuth}
                                                    >
                                                        Log in Singpass
                                                    </Button>
                                                </Col>
                                            </Row>

                                        </div>

                                    </Form>
                                </CardBody>
                            </Card>

                            <div className="text-center">
                                <p>
                                    Don&#39;t have an account ?{" "}
                                    <Link
                                        to="/register"
                                        className="fw-medium text-primary"
                                    >
                                        <b>
                                            Register Now
                                        </b>
                                    </Link>
                                </p>

                            </div>

                        </Col>

                    </Row>
                </Container>
            </div>

        </React.Fragment>
    );
};

export default withRouter(Login);

Login.propTypes = {
    history: PropTypes.object,
};
