import Swal from "sweetalert2";
import ReactSelect from 'react-select';
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../components/Common/Loader";
import { ToastContainer, toast } from "react-toastify";
import { Form, Row, Col, Label, Input } from 'reactstrap';
import AVEBeneficiary from "../Beneficiary/AVEBeneficiary";
import { getApiData, postApiData } from "../../helpers/axiosHelper";
import { SelectStyle } from "../../common/data/SelectStyle";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faEquals, faXmark, faDivide } from '@fortawesome/free-solid-svg-icons';

const TransferForm = ({ branchID, transferCurrency, baseCurrency, globalTransferData, closeModal }) => {

    console.log({ branchID, transferCurrency, baseCurrency, globalTransferData, closeModal });

    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [beneficiaryError, setBeneficiaryError] = useState("");
    const [currency, setCurrency] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [currencies, setCurrencies] = useState([]);

    const [modal1, setModal1] = useState(false);
    const toggleAddModal = () => setModal1(!modal1);
    const [fromCurrencyDisplay, setFromCurrencyDisplay] = useState("0.00");
    const [toCurrencyDisplay, setToCurrencyDisplay] = useState("0.00");
    const [beneficiary, setBeneficiary] = useState([]);
    const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
    const [typedBeneficiaryName, setTypedBeneficiaryName] = useState("");

    const [fromCurrencyTransfer, setFromCurrencyTransfer] = useState([]);
    const [fromCurrencyValueTransfer, setFromCurrencyValueTransfer] = useState(0);
    const [selectedFromCurrencyTransfer, setSelectedFromCurrencyTransfer] = useState([]);

    const toCurrencyTransfer = transferCurrency;
    const [toCurrencyValueTransfer, setToCurrencyValueTransfer] = useState(0);
    const [isTransferRateAvailable, setIsTransferRateAvailable] = useState(true);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [buyRate, setBuyRate] = useState(null);

    const [rateHistoryId, setRateHistoryId] = useState(null);

    const transferData = globalTransferData?.find((x) => {
        const targetCurrency = toCurrencyTransfer;
        return x.currencyCode === targetCurrency;
    });

    const transferRate = transferData ? parseFloat(transferData.rate) : 0;

    const calculateFee = (fee, fromCurrency, toCurrency, buyRate) => {

        if (fromCurrency === "SGD") {
            return fee;
        } else if (toCurrency === "SGD") {
            return fee;
        } else {
            return fee / buyRate;
        }

    };

    let wireFee = parseFloat(transferData ? transferData.wireTransferFee : 0);
    let commissionFee = parseFloat(transferData ? transferData.commissionCharges : 0);
    let agentFee = parseFloat(transferData ? transferData.agentCharges : 0);

    const convertedWireFee = calculateFee(wireFee, selectedFromCurrencyTransfer?.value, toCurrencyTransfer, buyRate);
    const convertedCommissionFee = calculateFee(commissionFee, selectedFromCurrencyTransfer?.value, toCurrencyTransfer, buyRate);
    const convertedAgentFee = calculateFee(agentFee, selectedFromCurrencyTransfer?.value, toCurrencyTransfer, buyRate);

    console.log({ transferData, transferRate, wireFee, commissionFee, agentFee })

    const getAvaTransferCurrency = async () => {
        try {
            const avaCurrency = await getApiData(`api/RateMaster/GetBranchForCurrencyAll?rateType=3`);

            const distinctCurrencies = Array.from(
                new Set(avaCurrency.data.map(currency => currency.currencyCode))
            ).map(currencyCode => avaCurrency.data.find(currency => currency.currencyCode === currencyCode));

            const formattedCurrency = distinctCurrencies
                .map(currency => ({
                    label: currency.currencyName,
                    value: currency.currencyCode,
                    currencyCode: currency.currencyCode,
                }))
                .filter(currency => currency.label && currency.value !== transferCurrency);

            setFromCurrencyTransfer(formattedCurrency);
        } catch (error) {
            console.error("Error fetching currencies", error);
        }
    };

    const fetchTrancfer = async (fromCurr) => {
        setIsLoading(true);
        try {
            const resp = await getApiData(`api/RateMaster/GetTransactionRate?fromCurr=${fromCurr}`);
            if (resp.success === true) {
                setRateHistoryId(resp.data.rateHistoryId);
                setIsTransferRateAvailable(true);
                setBuyRate(parseFloat(resp.data.buyRate));
            } else {
                setRateHistoryId(null);
                setIsTransferRateAvailable(false);
                setBuyRate(null);
            }
        } catch (error) {
            console.error("Error fetching transfer rate", error);
            setRateHistoryId(null);
            setIsTransferRateAvailable(false);
            setBuyRate(null);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (selectedFromCurrencyTransfer?.value !== "SGD" &&
            selectedFromCurrencyTransfer?.value !== undefined) {
            fetchTrancfer(selectedFromCurrencyTransfer?.value);
        } else {
            console.log("not");
        }
        setFromCurrencyValueTransfer(0);
        setToCurrencyValueTransfer(0);
    }, [selectedFromCurrencyTransfer]);

    const fetchCurrencies = async () => {
        try {
            const response = await getApiData('api/Currency/GetAllCurrencies');
            if (response.success === true) {
                setCurrencies(response.data);
            } else {
                setCurrencies([]);
            }
        } catch (error) {
            console.error("Error fetching currencies", error);
        }
    };

    useEffect(() => {
        fetchCurrencies();
    }, []);

    const getAvaBeneficiary = async () => {
        try {
            setIsLoading(true);
            const response = await getApiData(`api/Customerbeneficiary/GetAllBeneficiaryByCustomer`);
            setIsLoading(false);

            const filteredResponse = response.data.filter(item => item.status === 2 && item.active === 1);
            const mappedResponse = filteredResponse.map(item => ({
                label: item.beneficiaryName,
                value: item.custBenId,
                currencyName: item.currencyName.split('(')[1]?.replace(')', '') || "",
            }));
            const transCurrency = mappedResponse.filter(cc => cc.currencyName === transferCurrency);
            setBeneficiary(transCurrency);
        } catch (error) {
            console.log("Error fetching beneficiaries", error);
        }

    };

    useEffect(() => {
        getAvaBeneficiary();
        getAvaTransferCurrency();

    }, []);

    useEffect(() => {
        if (baseCurrency && fromCurrencyTransfer.length > 0) {
            const initialCurrency = fromCurrencyTransfer.find(
                currency => currency.value === baseCurrency
            );
            if (initialCurrency) {
                setSelectedFromCurrencyTransfer(initialCurrency);
            }
        }
    }, [baseCurrency, fromCurrencyTransfer]);

    const convertFromCurrency = (e) => {
        const inputValue = e.target.value;

        setFromCurrencyDisplay(inputValue);

        if (inputValue === "") {
            setFromCurrencyValueTransfer("");
            setToCurrencyValueTransfer("");
            setErrors({
                fromCurrency: "",
                toCurrency: "",
            });
            return;
        }

        if (inputValue.length > 10) return;

        const amount = parseFloat(inputValue) || 0;
        const totalFee = convertedWireFee + convertedCommissionFee + convertedAgentFee || 0;

        setFromCurrencyValueTransfer(inputValue);

        if (selectedFromCurrencyTransfer?.value === undefined) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "Please select From currency to continue.",
                toCurrency: ""
            }));
            setFromCurrencyValueTransfer(0);
            setToCurrencyValueTransfer(0);
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
            }));
        }

        if (amount <= 0 || amount <= 10 || amount <= totalFee) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: `Amount must be greater than 10, total fees (${totalFee.toFixed(2)}), and cannot be negative.`,
                toCurrency: ""
            }));
            setToCurrencyValueTransfer(0);
            setToCurrencyDisplay("0.00");
            return;
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
            }));
        }


        let convertedValue = 0;

        if (selectedFromCurrencyTransfer?.value === "SGD") {
            if (transferRate > 0) {
                convertedValue = (amount - totalFee) / transferRate;
            }
        } else if (toCurrencyTransfer === "SGD") {
            if (!buyRate || buyRate <= 0) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    fromCurrency: "Invalid rates for foreign currency transfer. Please check Transfer rate.",
                    toCurrency: ""
                }));
                setToCurrencyValueTransfer(0);
                return;
            }
            console.log({
                amount, buyRate, convertedWireFee, convertedCommissionFee, convertedAgentFee
            })
            convertedValue = (amount * buyRate) - totalFee;

        } else {
            if (!buyRate || buyRate <= 0 || transferRate <= 0) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    fromCurrency: "Invalid rates for foreign currency transfer. Please check buy and sell rates.",
                    toCurrency: ""
                }));
                setToCurrencyValueTransfer(0);
                return;
            }
            const subAmount = amount - totalFee;
            const convertedSGD = subAmount * buyRate;
            convertedValue = convertedSGD / transferRate;
        }

        if (convertedValue <= 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                toCurrency: "Converted value must be greater than 10.",
            }));
            setToCurrencyValueTransfer(0);
            return;
        }


        setErrors((prevErrors) => ({
            ...prevErrors,
            toCurrency: "",
        }));



        setToCurrencyValueTransfer(convertedValue);
        setToCurrencyDisplay(convertedValue.toFixed(2));
    };

    const convertToCurrency = (e) => {
        const inputValue = e.target.value;

        setToCurrencyDisplay(inputValue);
        if (inputValue === "") {
            setFromCurrencyValueTransfer("");
            setFromCurrencyDisplay("0.00");
            setToCurrencyValueTransfer("");
            setErrors({
                fromCurrency: "",
                toCurrency: "",
            });
            return;
        }

        if (inputValue.length > 10) return;

        const amount = parseFloat(inputValue) || 0;

        const totalFee = convertedWireFee + convertedCommissionFee + convertedAgentFee || 0;

        setToCurrencyValueTransfer(inputValue);

        if (selectedFromCurrencyTransfer?.value === undefined) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "Please select From currency to continue.",
                toCurrency: ""
            }));
            setFromCurrencyValueTransfer(0);
            setToCurrencyValueTransfer(0);
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
            }));
        }

        if (amount <= 0 || amount < 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: `Amount must be greater than 10 and cannot be negative.`,
            }));
            setFromCurrencyValueTransfer(0);
            setFromCurrencyDisplay("0.00");
            return;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            toCurrency: "",
        }));

        let convertedValue = 0;

        if (selectedFromCurrencyTransfer?.value === "SGD") {
            if (transferRate > 0) {
                convertedValue = (amount * transferRate) + totalFee;
            }
        } else if (toCurrencyTransfer === "SGD") {
            if (!buyRate || buyRate <= 0) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    fromCurrency: "",
                    toCurrency: "Invalid rates for foreign currency transfer. Please check Transfer rates.",
                }));
                setFromCurrencyValueTransfer(0);
                return;
            }
            convertedValue = (amount + totalFee) / buyRate;
        } else {
            if (!buyRate || buyRate <= 0 || transferRate <= 0) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    fromCurrency: "",
                    toCurrency: "Invalid rates for foreign currency transfer. Please check buy and sell rates.",
                }));
                setFromCurrencyValueTransfer(0);
                return;
            }
            const convertedSGD = amount * transferRate;
            convertedValue = (convertedSGD / buyRate) + totalFee;
        }

        if (convertedValue <= 0) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: `Converted value must be greater than 10 and cannot be negative.`,
                toCurrency: ""
            }));
            setFromCurrencyValueTransfer(0);
            return;
        }
        if (convertedValue < 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: `Converted value must be greater than 10 `,
                toCurrency: ""
            }));
            setFromCurrencyValueTransfer(0);
            return;
        }
        console.log(convertedValue);
        setFromCurrencyValueTransfer(convertedValue.toFixed(10));
        setFromCurrencyDisplay(convertedValue.toFixed(2));
    };

    const getCurrencyFlag = (currencyCode) => {
        const currency = currencies.find(c => c.currencyCode === currencyCode);
        return currency ? `data:image/png;base64,${currency.currencyFlag}` : '';
    };

    const handleSubmitTransfer = async (e) => {
        e.preventDefault();

        if (!validateSubmit()) return;
        const fromRateHistoryId =
            selectedFromCurrencyTransfer?.value === "SGD"
                ? globalTransferData?.find(
                    (x) => x.currencyCode === selectedFromCurrencyTransfer?.value
                )?.rateHistoryId
                : rateHistoryId;

        if (!fromRateHistoryId) {
            console.error("Rate history ID is missing.");
            return;
        }

        const toRateHistory = globalTransferData?.find(
            (x) => x.currencyCode === toCurrencyTransfer
        );


        const TransferData = {
            toCustBenId: selectedBeneficiary?.value,

            fromCurrType: selectedFromCurrencyTransfer?.value,
            fromCurrValue: fromCurrencyValueTransfer,

            toCurrType: toCurrencyTransfer,
            toCurrValue: toCurrencyValueTransfer,

            exchangeRate: fromCurrencyValueTransfer / toCurrencyValueTransfer,

            fromRateHistroyId: fromRateHistoryId,
            
            toRateHistroyId: toRateHistory?.rateHistoryId,

            transactionType: 3,
        };


        try {
            const response = await postApiData(
                "api/Transaction/InsertTransaction",
                TransferData
            );
            if (response.success === true) {
                toast.success("Transaction request has been completed", {
                    position: "top-right",
                    autoClose: 3000,
                });
            } else {
                Swal.fire({
                    text: response.message + "! Click Ok to go to profile",
                    icon: "info",
                    showConfirmButton: true,
                    showCancelButton: false,
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#556ee6",
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate("/profile");
                    } else {
                        closeModal();
                    }
                });
            }
            closeModal();
        } catch (error) {
            console.error("Error during transaction", error);
        }
    };

    const validateSubmit = () => {
        let isValid = true;


        if (!selectedBeneficiary) {
            if (filteredBeneficiaries.some(option => option.value === "create-new")) {
                setBeneficiaryError("Please create a beneficiary.");
            } else {
                setBeneficiaryError("Please select a beneficiary.");
            }
            isValid = false;
        } else {
            setBeneficiaryError("");
        }


        if (!selectedFromCurrencyTransfer?.value) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                amount: "Please select the currency.",
            }));
            isValid = false;
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                amount: "",
            }));
        }



        return isValid;
    };


    const filteredBeneficiaries = beneficiary.filter(b =>
        b.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (typedBeneficiaryName) {
        filteredBeneficiaries.push({
            label: `Create "${typedBeneficiaryName}" Beneficiary`,
            value: "create-new",
        });
    }

    if (filteredBeneficiaries.length === 0) {
        filteredBeneficiaries.push({
            label: `Create New Beneficiary for ${toCurrencyTransfer} currency`,
            value: "create-new",
        });
    }


    const openADDBeneficiary = () => {
        //toggleAddModal();
        //setSearchTerm("");
        navigate("/CustomerBeneficiary");
    }
    const handleNumericInput = (e) => {
        const allowedKeys = [
            "Backspace",
            "Delete",
            "ArrowLeft",
            "ArrowRight",
            "Tab",
            "Enter"
        ];

        const inputValue = e.target.value;


        if (allowedKeys.includes(e.key)) return;


        if (e.key === ".") {
            if (inputValue.includes(".")) {
                e.preventDefault();
                return;
            }
        } else if (!/^\d$/.test(e.key)) {
            e.preventDefault();
            return;
        }


        if (inputValue.length >= 10) {
            e.preventDefault();
        }
    };

    return (
        <>
            <AVEBeneficiary
                editMode={3}
                isOpen={modal1}
                currencies={currency}
                toggle={toggleAddModal}
            />

            {isLoading ? (
                <Loader />
            ) : (
                <Form onSubmit={handleSubmitTransfer}>
                    <ToastContainer />
                    <Row>
                        <Col md={12}>
                            <div className="mb-2">
                                <Label>Select Beneficiary</Label>
                                <ReactSelect
                                    styles={SelectStyle}
                                    value={selectedBeneficiary}
                                    onInputChange={(inputValue) => {
                                        setSearchTerm(inputValue);
                                        setTypedBeneficiaryName(inputValue);
                                    }}
                                    onChange={(selectedOption) => {
                                        if (selectedOption?.value === "create-new") {
                                            openADDBeneficiary();
                                        } else {
                                            setSelectedBeneficiary(selectedOption);
                                            setBeneficiaryError("");
                                        }
                                    }}
                                    options={filteredBeneficiaries}
                                />
                                {beneficiaryError && (
                                    <div className="text-danger mt-1">
                                        {beneficiaryError}
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col md={6}>
                            <Label>You Give</Label>
                            <ReactSelect
                                styles={SelectStyle}
                                value={selectedFromCurrencyTransfer}
                                formatOptionLabel={({ value, label }) => (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img
                                            src={getCurrencyFlag(value)}
                                            alt={`${value} flag`}
                                            style={{ width: '20px', marginRight: '5px' }}
                                        />
                                        <span>{label}</span>
                                    </div>
                                )}
                                onChange={(selectedOption) => {
                                    setSelectedFromCurrencyTransfer(selectedOption);
                                    setErrors("");
                                }}
                                options={fromCurrencyTransfer}
                            />
                            {errors.amount && <div className="text-danger">{errors.amount}</div>}
                        </Col>

                        <Col md={6}>
                            <Label>{selectedFromCurrencyTransfer?.currencyCode} Amount </Label>
                            <Input
                                type="text"
                                className="form-control"
                                placeholder="Enter Amount"
                                value={fromCurrencyDisplay}
                                onKeyDown={handleNumericInput}
                                onChange={convertFromCurrency}
                                disabled={!selectedFromCurrencyTransfer?.value}
                                onFocus={(e) => (e.target.style.border = '2px solid #556ee6')}
                            />
                            {errors.fromCurrency && <div className="text-danger">{errors.fromCurrency}</div>}
                        </Col>

                    </Row>

                    {isTransferRateAvailable ? (
                        <>


                            {/* Other rate-related fields */}
                            {toCurrencyTransfer === "SGD" && (
                                <Row className="mb-3 text-end">
                                    <Col lg={6} className="text-start">
                                        <span>Converted SGD</span>
                                    </Col>
                                    <Col lg={6}>
                                        <span>{(fromCurrencyValueTransfer * buyRate).toFixed(2)}</span>
                                    </Col>
                                </Row>
                            )}

                            <Row className="mb-3 text-end">
                                <Col lg={6} className="text-start">
                                    <span>Wire Transfer Fee</span>
                                </Col>
                                <Col lg={6}>
                                    <span>
                                        <FontAwesomeIcon icon={faMinus} /> {convertedWireFee.toFixed(2)}{" "}
                                        {selectedFromCurrencyTransfer?.value === "SGD"
                                            ? selectedFromCurrencyTransfer?.value
                                            : toCurrencyTransfer === "SGD"
                                                ? toCurrencyTransfer
                                                : selectedFromCurrencyTransfer?.value}
                                    </span>
                                </Col>
                            </Row>

                            <Row className="mb-3 text-end">
                                <Col lg={6} className="text-start">
                                    <span>Commission Fee</span>
                                </Col>
                                <Col lg={6}>
                                    <span>
                                        <FontAwesomeIcon icon={faMinus} /> {convertedCommissionFee.toFixed(2)}{" "}
                                        {selectedFromCurrencyTransfer?.value === "SGD"
                                            ? selectedFromCurrencyTransfer?.value
                                            : toCurrencyTransfer === "SGD"
                                                ? toCurrencyTransfer
                                                : selectedFromCurrencyTransfer?.value}
                                    </span>
                                </Col>
                            </Row>

                            {!isNaN(convertedAgentFee) && (
                                <Row className="mb-3 text-end">
                                    <Col lg={6} className="text-start">
                                        <span>Agent Fee</span>
                                    </Col>
                                    <Col lg={6}>
                                        <span>
                                            <FontAwesomeIcon icon={faMinus} /> {convertedAgentFee.toFixed(2)}{" "}
                                            {selectedFromCurrencyTransfer?.value === "SGD"
                                                ? selectedFromCurrencyTransfer?.value
                                                : toCurrencyTransfer === "SGD"
                                                    ? toCurrencyTransfer
                                                    : selectedFromCurrencyTransfer?.value}
                                        </span>
                                    </Col>
                                </Row>
                            )}

                            <hr />

                            {toCurrencyTransfer !== "SGD" && (
                                <Row className="mb-3 text-end">
                                    <Col lg={6} className="text-start">
                                        <span>Total {selectedFromCurrencyTransfer?.value || "N/A"}</span>
                                    </Col>
                                    <Col lg={6}>
                                        <span>
                                            <FontAwesomeIcon icon={faEquals} />{" "}
                                            {fromCurrencyValueTransfer > 0
                                                ? (
                                                    fromCurrencyValueTransfer -
                                                    ((convertedWireFee || 0) +
                                                        (convertedCommissionFee || 0) +
                                                        (convertedAgentFee || 0))
                                                )
                                                    .toFixed(2)
                                                    .replace(/\.?0+$/, "")
                                                : 0}{" "}
                                            {selectedFromCurrencyTransfer?.value || ""}
                                        </span>
                                    </Col>
                                </Row>
                            )}

                            <hr />
                            {toCurrencyTransfer !== "SGD" ? (

                                <Row className="mb-3 text-end">
                                    <Col lg={6} className="text-start">
                                        <span>Total {selectedFromCurrencyTransfer?.value || "N/A"}</span>
                                    </Col>
                                    <Col lg={6}>
                                        <span>
                                            <FontAwesomeIcon icon={faEquals} />
                                            {" "}
                                            {fromCurrencyValueTransfer > 0
                                                ? (
                                                    fromCurrencyValueTransfer -
                                                    ((convertedWireFee || 0) + (convertedCommissionFee || 0) + (convertedAgentFee || 0))
                                                )
                                                    .toFixed(2)
                                                    .replace(/\.?0+$/, "")
                                                : 0
                                            }
                                            {" "}
                                            {selectedFromCurrencyTransfer?.value || ""}
                                        </span>
                                    </Col>
                                </Row>
                            ) : null}


                            {(selectedFromCurrencyTransfer?.value === "SGD" || toCurrencyTransfer === "SGD") ? (
                                <Row className="mb-3 text-end">
                                    <Col lg={6} className="text-start">
                                        <span>Transfer Rate</span>
                                    </Col>
                                    <Col lg={6}>
                                        <span>
                                            {toCurrencyTransfer !== "SGD" ? (
                                                <>
                                                    <FontAwesomeIcon icon={faDivide} className="me-1" />
                                                    {transferRate}
                                                </>
                                            ) : (
                                                <>
                                                    <FontAwesomeIcon icon={faXmark} className="me-1" />
                                                    {buyRate?.toFixed(4) || 0}
                                                </>
                                            )}
                                        </span>
                                    </Col>

                                </Row>
                            ) : (
                                <Row className="mb-3 text-end">
                                    <Col lg={6} className="text-start">
                                        <span>Transfers Rate</span>
                                    </Col>
                                    <Col lg={6}>
                                        <span>
                                            <FontAwesomeIcon icon={faDivide} className="me-1" />
                                            {fromCurrencyValueTransfer && toCurrencyValueTransfer ? (
                                                (toCurrencyValueTransfer / fromCurrencyValueTransfer).toFixed(4)
                                            ) : (
                                                0
                                            )}
                                        </span>
                                    </Col>


                                </Row>
                            )}



                            <Row className="mb-3">
                                <Col md={6}>
                                    <Label>Beneficiary Receives</Label>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            background: "#F8F8F8",
                                            padding: "2px",
                                        }}
                                    >
                                        <img
                                            src={getCurrencyFlag(toCurrencyTransfer)}
                                            alt={`${toCurrencyTransfer} flag`}
                                        />
                                        <Input
                                            value={toCurrencyTransfer}
                                            disabled
                                            style={{ border: "none" }}
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <Label>{toCurrencyTransfer} Amount </Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Amount"
                                        value={toCurrencyDisplay}
                                        onKeyDown={handleNumericInput}
                                        onChange={convertToCurrency}
                                        onFocus={(e) => (e.target.style.border = "2px solid #556ee6")}
                                    />
                                    {errors.toCurrency && (
                                        <div className="text-danger">{errors.toCurrency}</div>
                                    )}
                                </Col>
                            </Row>

                            <div className="text-end">
                                <button type="submit" className="btn btn-primary">
                                    Submit
                                </button>
                            </div>
                        </>
                    ) : (
                        // This will display a message if the transfer rate is not available
                        <Row className="mb-3">
                            <Col md={12}>
                                <div className="text-warning text-center">
                                    Transfer Rate not available.
                                </div>
                            </Col>
                        </Row>
                    )}
                </Form>

            )}
        </>
    );
};

export default TransferForm;


