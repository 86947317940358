import React, { useEffect, useState } from "react";
import {
    Col,
    Card,
    CardBody,
    Nav,
    NavItem,
    NavLink,
    Input,
    Label,
    Row,
    CardTitle,
    Form,
    TabContent,
    TabPane,
    Container,
    Button,
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faEquals, faX, faDivide, faXmark, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import Swal from "sweetalert2";
import classnames from "classnames";
import { useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { getApiData, postApiData } from "../../helpers/axiosHelper";
import { SelectStyle } from "../../common/data/SelectStyle";
import ReactSelect from 'react-select';
import AVEBeneficiary from "../Beneficiary/AVEBeneficiary";
import DatePicker from "react-datepicker";
import { Tooltip } from "reactstrap";

const CustomOption = (props) => {

    return (
        <components.Option {...props}>
            <div className="d-flex justify-content-between">
                <div>
                    <div>{props.data.label}</div>
                    <div className="text-light" style={{ fontSize: '0.8em' }}>{props.data.address}</div>
                </div>
                <div style={{ fontSize: '0.9em' }}>
                    {props.data.availableAmount}
                </div>
            </div>
        </components.Option>
    );
};

const AddTransaction = ({ reload }) => {
    const navigate = useNavigate();
    const [optime, setOptime] = useState([]);
    const [pickupDT, setPickupDT] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [customActiveTab, setCustomActiveTab] = useState("1");

    const [branch, setBranch] = useState([]);

    const currentDate = new Date().toLocaleString();
    const [errors, setErrors] = useState({});

    const [currencies, setCurrencies] = useState([]);

    //Buying
    const fromCurrencyBuying = "SGD";
    const [buyCharge, setBuyCharge] = useState(0);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const [selectedBuyBranch, setSelectedBuyBranch] = useState(null);

    const buyExchnageRate = selectedBuyBranch?.conversionValue;

    const [buyingCurrency, setBuyingCurrency] = useState([]);
    const [fromCurrencyValueBuying, setFromCurrencyValueBuying] = useState(0);
    const [toCurrencyValueBuying, setToCurrencyValueBuying] = useState(0);

    const [fromCurrencyDisplay, setFromCurrencyDisplay] = useState("0.00");
    const [toCurrencyDisplay, setToCurrencyDisplay] = useState("0.00");


    const [selectedToCurrencyBuying, setSelectedToCurrencyBuying] = useState(null);

    //Selling
    const toCurrencySelling = "SGD";
    const [sellCharge, setSellCharge] = useState(0);
    const [activebranch, setActivebranch] = useState([])
    const [noRatesMessage, setNoRatesMessage] = useState("");
    const [sellingCurrency, setSellingCurrency] = useState([]);
    const [fromCurrencyValueSelling, setFromCurrencyValueSelling] = useState(0);
    const [toCurrencyValueSelling, setToCurrencyValueSelling] = useState(0);
    const [selectedFromCurrencySelling, setSelectedFromCurrencySelling] = useState(null);
    const [selectedSellBranch, setSelectedSellBranch] = useState(null);
    const sellExchnageRate = selectedSellBranch?.conversionValue;

    //Transfer
    const [searchTerm, setSearchTerm] = useState("");
    const [beneficiaryError, setBeneficiaryError] = useState("");

    const [modal1, setModal1] = useState(false);
    const toggleAddModal = () => setModal1(!modal1);

    const [beneficiary, setBeneficiary] = useState([]);
    const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
    const [typedBeneficiaryName, setTypedBeneficiaryName] = useState("");

    const [fromCurrencyTransfer, setFromCurrencyTransfer] = useState([]);
    const [fromCurrencyValueTransfer, setFromCurrencyValueTransfer] = useState(0);
    const [selectedFromCurrencyTransfer, setSelectedFromCurrencyTransfer] = useState([]);

    const [toCurrencyTransfer, setToCurrencyTransfer] = useState([]);
    const [toCurrencyValueTransfer, setToCurrencyValueTransfer] = useState(0);
    const [selectedToCurrencyTransfer, setSelectedToCurrencyTransfer] = useState([]);


    const [buyRate, setBuyRate] = useState(null);

    const [globalTransferData, setGlobalTransferData] = useState(null);
    const transferData = globalTransferData?.find((x) => {
        const targetCurrency = selectedToCurrencyTransfer?.value
        return x.currencyCode === targetCurrency;
    });

    const transferRate = transferData ? parseFloat(transferData.rate) : 0;

    const calculateFee = (fee, fromCurrency, toCurrency, buyRate) => {

        if (fromCurrency === "SGD") {
            return fee;
        } else if (toCurrency === "SGD") {
            return fee;
        } else {
            return fee / buyRate;
        }

    };

    let wireFee = parseFloat(transferData ? transferData.wireTransferFee : 0);
    let commissionFee = parseFloat(transferData ? transferData.commissionCharges : 0);
    let agentFee = parseFloat(transferData ? transferData.agentCharges : 0);

    const convertedWireFee = calculateFee(wireFee, selectedFromCurrencyTransfer?.value, selectedToCurrencyTransfer?.value, buyRate);
    const convertedCommissionFee = calculateFee(commissionFee, selectedFromCurrencyTransfer?.value, selectedToCurrencyTransfer?.value, buyRate);
    const convertedAgentFee = calculateFee(agentFee, selectedFromCurrencyTransfer?.value, selectedToCurrencyTransfer?.value, buyRate);

    console.log({ transferData, transferRate, wireFee, commissionFee });

    const resetTransaction = () => {
        setIsLoading(false);

        setPickupDT(null);

        //Buying
        setFromCurrencyValueBuying(0);
        setToCurrencyValueBuying(0);
        setSelectedToCurrencyBuying(null);
        setSelectedBuyBranch(null);

        //Selling
        setSelectedSellBranch(null);
        setSelectedFromCurrencySelling(null);

        //Transfer
        setFromCurrencyValueTransfer(0);
        setFromCurrencyDisplay("0.00");
        setToCurrencyValueTransfer(0);
        setToCurrencyDisplay("0.00");

    };

    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setCustomActiveTab(tab);
            resetTransaction();
            getBSCurrency(tab);
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: ""
            }));
        }
    };

    const handleHelpClick = () => {
        window.open("https://sitapi.crescentexchange.com/help/customer/Booking Transactions.pdf");
    };

    useEffect(() => {
        const fetchCurrencies = async () => {
            try {
                const response = await getApiData("api/Currency/GetAllCurrencies");
                if (response.success === true) {
                    setCurrencies(response.data);
                } else {
                    setCurrencies([]);
                }
            } catch (error) {
                setCurrencies([]);
                console.error("Failed to fetch currencies:", error);
            }
        };


        const fetchGlobalTransferRates = async () => {
            try {
                const response = await getApiData("api/RateMaster/GetGlobalTransferRate");

                const mappedTransferRates = response.data.map(rate => ({
                    rateHistoryId: rate.rateHistoryId,
                    currencyCode: rate.currencyCode,
                    currencyName: rate.currencyName,
                    rate: rate.value,
                    worldRate: parseFloat(rate.worldRate),
                    transferRate: parseFloat(rate.transferRate),
                    wireTransferFee: parseFloat(rate.wireTransferFee),
                    agentCharges: parseFloat(rate.agentCharges),
                    commissionCharges: parseFloat(rate.commissionCharges),
                    totalFeesAdmin: parseFloat(rate.totalFeesAdmin),
                }));

                setGlobalTransferData(mappedTransferRates);
            } catch (error) {
                console.error("Error fetching global transfer rates", error);
            }
        };

        fetchCurrencies();
        fetchGlobalTransferRates();
    }, []);

    const getCurrencyFlag = (currencyCode) => {
        if (!currencies || currencies.length === 0) {
            console.warn('Currencies array is empty');
            return '';
        }

        if (!currencyCode) {
            console.warn('Currency code is undefined');
            return '';
        }

        const currency = currencies.find(c => c.currencyCode === currencyCode);
        console.log('Looking for currency flag:', currencyCode, 'Found:', currency);

        return currency ? `data:image/png;base64,${currency.currencyFlag}` : '';
    };


    const getBSCurrency = async (tab) => {
        try {
            let rateType;

            if (tab === "1") {
                rateType = 2;
            } else if (tab === "2") {
                rateType = 1;
            } else {
                return;
            }

            const response = await getApiData(`api/BranchMaster/GetCurrencyForBST?rateType=${rateType}`);
            const avaCurrency = response?.data || [];

            const uniqueCurrencyMap = new Map();
            avaCurrency.forEach(currency => {
                if (currency.currencyCode !== "SGD" && currency.currencyName !== null && currency.currencyCode !== null) {
                    uniqueCurrencyMap.set(currency.currencyCode, {
                        label: currency.currencyName,
                        value: currency.currencyCode
                    });
                }
            });

            const uniqueCurrencies = Array.from(uniqueCurrencyMap.values());

            if (tab === "1") {
                setBuyingCurrency(uniqueCurrencies);
            } else if (tab === "2") {
                setSellingCurrency(uniqueCurrencies);
            }
        } catch (error) {
            console.error("Error fetching currencies", error);
        }
    };

    const convertTo12HourFormat = (time24) => {
        const [hours, minutes] = time24.split(":");
        const meridiem = hours >= 12 ? "PM" : "AM";
        const hours12 = hours % 12 || 12;
        return `${hours12}:${minutes} ${meridiem}`;
    };

    const getBranchByCurrCode = async (currencyCode, rateType) => {
        try {
            let apiEndpoint;
            let formattedBranch;

            if (rateType === 1) {
                apiEndpoint = `api/BranchMaster/GetBranches`;
                const response = await getApiData(apiEndpoint);


                const filteredData = response.data;

                formattedBranch = filteredData.map(branch => ({
                    label: branch.branchName,
                    value: branch.branchId,
                    // conversionValue: branch.value,
                    //  sellrateHistoryId: branch.rateHistoryId,
                    address: `${branch.street}, ${branch.city}, ${branch.postalcode}`,
                }));
            } else {
                apiEndpoint = `api/RateMaster/GetBranchForCurrency?currencyCode=${currencyCode}&rateType=${rateType}`;
                const response = await getApiData(apiEndpoint);

                formattedBranch = response.data.map(branch => ({
                    label: branch.branchName,
                    value: branch.branchId,
                    address: `${branch.street}, ${branch.city}, ${branch.postalcode}`,
                    availableAmount: branch.availableAmount,
                    buyrateHistoryId: branch.rateHistoryId,
                    conversionValue: branch.value,
                }));
            }

            const uniqueBranches = formattedBranch.filter(
                (branch, index, self) =>
                    index === self.findIndex(b => b.value === branch.value)
            );

            if (rateType === 1) {
                setActivebranch(formattedBranch);
            } else {
                setBranch(uniqueBranches);
            }
        } catch (error) {
            console.error("Error fetching branches", error);
        }
    };

    const handleBranchSelection = async (selectedBranch, rateType) => {
        if (!selectedBranch) {
            console.error("No branch selected.");
            return;
        }

        try {
            const branchId = selectedBranch.value;

            if (rateType === 1) {

                const ratesResponse = await getApiData(`api/RateMaster/GetActiveRatesByBranch?BranchID=${branchId}`);
                const rates = ratesResponse?.data || [];

                const matchingRate = rates.find(rate =>
                    rate.currencyCode === selectedFromCurrencySelling?.value &&
                    rate.rateType === 1
                );

                if (!matchingRate) {
                    console.error("No rate available in branch to sell your currency.");
                    setNoRatesMessage("No rate is available in this branch.");
                    setSellCharge(0);
                    setSelectedSellBranch(null);
                    return;
                }

                setNoRatesMessage("");

                const { rateHistoryId, value: conversionValue } = matchingRate;

                if (!rateHistoryId || !conversionValue) {
                    console.error("Rate History ID or Conversion Value is undefined.");
                    return;
                }

                setSelectedSellBranch({
                    ...selectedBranch,
                    conversionValue,
                    sellrateHistoryId: rateHistoryId
                });

                const chargesRes = await getApiData(`api/RateMaster/GetTransactionCharges?rateHistoryId=${rateHistoryId}`);
                const agentCharges = chargesRes?.data?.agentCharges || 0;

                setSellCharge(agentCharges);
                getBranchOpTime(branchId);

            } else if (rateType === 2) {

                setSelectedBuyBranch(selectedBranch);


                setNoRatesMessage("");

                const chargesRes = await getApiData(`api/RateMaster/GetTransactionCharges?rateHistoryId=${selectedBranch?.buyrateHistoryId}`);
                const agentCharges = chargesRes?.data?.agentCharges || 0;

                setBuyCharge(agentCharges);
                getBranchOpTime(branchId);
            }
        } catch (error) {
            console.error("Error handling branch selection:", error);
        }
    };

    const getBranchOpTime = async (branchId) => {
        const branchResponse = await getApiData(`api/BranchMaster/GetBranchWithOperation?branchId=${branchId}`);

        const operationalTime = branchResponse.data.operationalTime[0];
        if (operationalTime) {
            const startTime12 = convertTo12HourFormat(operationalTime.startTime);
            const endTime12 = convertTo12HourFormat(operationalTime.endTime);

            setOptime({
                startTime: startTime12,
                endTime: endTime12
            });
        }
    }

    const handleSubmitBuying = async (e) => {
        e.preventDefault();
        setIsLoading(true)

        const BuyingData = {

            fromCurrType: fromCurrencyBuying,
            fromCurrValue: fromCurrencyValueBuying,

            toCurrType: selectedToCurrencyBuying.value,
            toCurrValue: toCurrencyValueBuying,

            toRateHistoryId: selectedBuyBranch.buyrateHistoryId,
            fromBranchId: selectedBuyBranch.value,

            transactionType: 2,
            exchangeRate: selectedBuyBranch.conversionValue,
            pickup: pickupDT ? pickupDT : "",

        }

        try {
            setIsLoading(true);
            const response = await postApiData('api/Transaction/InsertTransaction', BuyingData)
            if (response.success === true) {

                toast.success("Transaction request has been completed ", {
                    position: "top-right",
                    autoClose: 3000,
                    onClose: () => {
                        setIsLoading(false);
                    }
                });
                reload();
            } else {
                Swal.fire({
                    text: response.message + "! Click Ok to go to profile ",
                    icon: "info",
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#556ee6",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        navigate("/profile");
                    } else {
                        resetTransaction();
                    }
                });
            }
            resetTransaction();
        } catch (error) {
            console.error("Error occuried", error);
            resetTransaction();
            setIsLoading(false);
        }
    }

    const handleSubmitSelling = async (e) => {
        e.preventDefault();

        if (!selectedSellBranch) {
            toast.error("Please select a Branch.", {
                position: "top-right",
                autoClose: 3000,
            });
            return;
        }
        if (!selectedFromCurrencySelling) {
            toast.error("Please select the currencies you want to exchange.", {
                position: "top-right",
                autoClose: 3000,
            });
            return;
        }
        if (!fromCurrencyValueSelling) {
            toast.error("Please Enter Amount you want to Exchange.", {
                position: "top-right",
                autoClose: 3000,
            });
            return;
        }

        const sellingData = {
            fromCurrType: selectedFromCurrencySelling.value,
            toCurrType: toCurrencySelling,
            fromCurrValue: fromCurrencyValueSelling,
            fromRateHistoryId: selectedSellBranch.sellrateHistoryId,
            toCurrValue: toCurrencyValueSelling,
            fromBranchId: selectedSellBranch.value,
            transactionType: 1,
            exchangeRate: selectedSellBranch.conversionValue,
            pickup: pickupDT ? pickupDT : "",
        }
        try {
            setIsLoading(true);
            const response = await postApiData('api/Transaction/InsertTransaction', sellingData)
            if (response.success === true) {
                toast.success("Transaction request has been completed", {
                    position: "top-right",
                    autoClose: 3000,
                    onClose: () => {
                        setIsLoading(false);
                    }
                });
                reload();
            } else {
                Swal.fire({
                    text: response.message + "! Click Ok to go to profile ",
                    icon: "info",
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#556ee6",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        navigate("/profile")
                    } else {
                        resetTransaction();
                    }
                })
            }
            setSelectedFromCurrencySelling("");
            setFromCurrencyValueSelling("");
        } catch (error) {
            console.error(error, {
                position: "top-right",
                autoClose: 3000,
            });
            resetTransaction();
        }
    }

    const handleSubmitTransfer = async (e) => {
        e.preventDefault();
        if (!validateSubmit()) return;

        if (!selectedBeneficiary) {
            toast.error("Please select a Beneficiary.", {
                position: "top-right",
                autoClose: 3000,
            });
            return;
        }
        const TransferData = {
            toCustBenId: selectedBeneficiary?.value,

            fromCurrType: selectedFromCurrencyTransfer?.value,
            fromCurrValue: fromCurrencyValueTransfer,

            toCurrType: selectedToCurrencyTransfer?.value,
            toCurrValue: toCurrencyValueTransfer,

            exchangeRate: transferRate,

            fromBranchId: 1,
            transactionType: 3,
        };

        try {
            setIsLoading(true);
            const response = await postApiData('api/Transaction/InsertTransaction', TransferData)
            if (response.success === true) {
                toast.success("Transaction request has been completed", {
                    position: "top-right",
                    autoClose: 3000,
                    onClose: () => {
                        setIsLoading(false);
                    }
                });
                reload();
            } else {
                Swal.fire({
                    text: response.message + "! Click Ok to go to profile ",
                    icon: "info",
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#556ee6",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        navigate("/profile")
                    } else {
                        resetTransaction();
                    }
                })
            }
            resetTransaction();
        } catch (error) {
            console.error("Error occuried.", error);
            resetTransaction();
        }
    }

    const getAvaBeneficiary = async () => {
        try {
            setIsLoading(true);
            const response = await getApiData(`api/Customerbeneficiary/GetAllBeneficiaryByCustomer`);
            setIsLoading(false);

            const filteredResponse = response.data.filter(item => item.status === 2 && item.active === 1);
            const mappedResponse = filteredResponse.map(item => ({
                label: item.beneficiaryName,
                value: item.custBenId,
                currencyName: item.currencyName.split('(')[1]?.replace(')', '') || "",
            }));
            setBeneficiary(mappedResponse);
        } catch (error) {
            console.error("Error fetching beneficiaries", error);
        }

    };

    useEffect(() => {
        getAvaBeneficiary();
        getAvaTransferCurrency();
        getBSCurrency(customActiveTab);
    }, []);

    useEffect(() => {
        if (selectedToCurrencyBuying) {
            getBranchByCurrCode(selectedToCurrencyBuying.value, 2);
        }
    }, [selectedToCurrencyBuying]);

    useEffect(() => {
        if (selectedFromCurrencySelling) {
            getBranchByCurrCode(selectedFromCurrencySelling.value, 1);
        }
    }, [selectedFromCurrencySelling]);


    const convertFromBuyCurrency = (e) => {
        const inputValue = e.target.value.trim();

        // Update display value directly
        setFromCurrencyDisplay(inputValue);

        if (inputValue === "" || isNaN(parseFloat(inputValue))) {
            setFromCurrencyValueBuying(0);
            setToCurrencyValueBuying(0);
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: "",
            }));
            return;
        }

        if (inputValue.length > 10) return;

        const amount = parseFloat(inputValue);
        const charges = parseFloat(buyCharge) || 0;
        const rate = parseFloat(buyExchnageRate) || 0;

        setFromCurrencyValueBuying(amount);

        if (amount < 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "SGD must be greater than 10.",
                toCurrency: "",
            }));
            setToCurrencyValueBuying(0);
            setToCurrencyDisplay("0.00");
            return;
        }

        if (amount <= charges) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "Amount must be greater than buying fee.",
                toCurrency: "",
            }));
            setToCurrencyValueBuying(0);
            setToCurrencyDisplay("0.00");
            return;
        }

        const subAmount = amount - charges;
        const convertedToCurrency = subAmount / rate;

        if (convertedToCurrency < 1) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: "Converted amount must be greater than 1.",
            }));
            setToCurrencyValueBuying(0);
            setToCurrencyDisplay("0.00");
            return;
        }

        if (convertedToCurrency > selectedBuyBranch?.availableAmount) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: `Amount cannot exceed the available stock of ${selectedBuyBranch?.availableAmount}.`,
            }));
            setToCurrencyValueBuying(0);
            setToCurrencyDisplay("0.00");
            return;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            fromCurrency: "",
            toCurrency: "",
        }));

        setToCurrencyValueBuying(convertedToCurrency > 0 ? convertedToCurrency : 0);
        setToCurrencyDisplay(convertedToCurrency.toFixed(2));
    };

    const convertToBuyCurrency = (e) => {
        const inputValue = e.target.value.trim();

        setToCurrencyDisplay(inputValue);

        if (inputValue === "" || isNaN(parseFloat(inputValue))) {
            setFromCurrencyValueBuying(0);
            setToCurrencyValueBuying(0);
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: "",
            }));
            return;
        }

        if (inputValue.length > 10) return;

        const amount = parseFloat(inputValue);
        const charges = parseFloat(buyCharge) || 0;
        const rate = parseFloat(buyExchnageRate) || 0;

        setToCurrencyValueBuying(amount);

        if (amount < 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "Amount must be greater than 10.",
                toCurrency: "",
            }));
            setFromCurrencyValueBuying(0);
            setFromCurrencyDisplay("0.00");
            return;
        }

        const convertedSGD = amount * rate;
        const convertedFromCurrency = convertedSGD + charges;

        if (convertedSGD < charges) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "Converted amount must be greater than buying charges.",
                toCurrency: "",
            }));
            setFromCurrencyValueBuying(0);
            setFromCurrencyDisplay("0.00");
            return;
        }

        if (convertedFromCurrency < 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "Converted SGD must be greater than 10.",
                toCurrency: "",
            }));
            setFromCurrencyValueBuying(0);
            setFromCurrencyDisplay("0.00");
            return;
        }

        if (convertedFromCurrency > selectedBuyBranch?.availableAmount) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: `Amount cannot exceed the available stock of ${selectedBuyBranch?.availableAmount}.`,
            }));
            setFromCurrencyValueBuying(0);
            setFromCurrencyDisplay("0.00");
            return;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            fromCurrency: "",
            toCurrency: "",
        }));

        setFromCurrencyValueBuying(convertedFromCurrency > 0 ? convertedFromCurrency : 0);
        setFromCurrencyDisplay(convertedFromCurrency.toFixed(2));
    };


    const convertFromSellCurrency = (e) => {
        const inputValue = e.target.value.trim();

        setFromCurrencyDisplay(inputValue);

        if (inputValue === "") {
            setFromCurrencyValueSelling("");
            setToCurrencyValueSelling("");
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: "",
            }));
            return;
        }

        if (inputValue.length > 10) return;

        const amount = parseFloat(inputValue) || 0;
        const charges = sellCharge || 0;
        const rate = parseFloat(sellExchnageRate) > 0 ? parseFloat(sellExchnageRate) : 0;

        setFromCurrencyValueSelling(amount);

        if (amount <= 0) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: `${selectedFromCurrencySelling?.value} cannot be 0 or less than 0.`,
            }));
            setToCurrencyValueSelling(0);
            setToCurrencyDisplay("0.00");
            return;
        }

        if (amount <= 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: `${selectedFromCurrencySelling?.value} must be greater than 10.`,
            }));
            setToCurrencyValueSelling(0);
            setToCurrencyDisplay("0.00");
            return;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            fromCurrency: "",
            toCurrency: ""
        }));

        if (amount < charges) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: `${selectedFromCurrencySelling?.value} must be greater than the selling fee.`,
            }));
            setToCurrencyValueSelling(0);
            setToCurrencyDisplay("0.00");
            return;
        }

        const convertedToCurrency = (amount * rate) - charges;

        if (convertedToCurrency < 1) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: "Converted amount must be greater than 1."
            }));
            setToCurrencyValueSelling(0);
            setToCurrencyDisplay("0.00");
            return;
        }

        setToCurrencyValueSelling(convertedToCurrency > 0 ? convertedToCurrency : 0);
        setToCurrencyDisplay(convertedToCurrency.toFixed(2));

    };

    const convertToSellCurrency = (e) => {
        const inputValue = e.target.value.trim();

        setToCurrencyDisplay(inputValue);

        if (inputValue === "") {
            setFromCurrencyValueSelling(0);
            setToCurrencyValueSelling(0);
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: "",
            }));
            return;
        }

        if (inputValue.length > 10) return;

        const amount = parseFloat(inputValue) || 0;
        const charges = parseFloat(sellCharge) || 0;
        const rate = parseFloat(sellExchnageRate) || 0;

        setToCurrencyValueSelling(amount);

        if (amount <= 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                toCurrency: "SGD cannot be less than 10.",
            }));
            setFromCurrencyValueSelling(0);
            setFromCurrencyDisplay("0.00");
            return;
        }

        if (amount <= charges) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                toCurrency: "SGD must be greater than the selling fee.",
            }));
            setFromCurrencyValueSelling(0);
            setFromCurrencyDisplay("0.00");
            return;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            fromCurrency: "",
            toCurrency: "",
        }));

        const convertedFromCurrency = (amount + charges) / rate;

        if (convertedFromCurrency <= 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
            }));
            setFromCurrencyValueSelling(0);
            setFromCurrencyDisplay("0.00");
            return;
        }

        setFromCurrencyValueSelling(convertedFromCurrency > 0 ? convertedFromCurrency : 0);
        setFromCurrencyDisplay(convertedFromCurrency.toFixed(2));

    };


    const convertFromTransferCurrency = (e) => {
        const inputValue = e.target.value;

        setFromCurrencyDisplay(inputValue);

        if (inputValue === "") {
            setFromCurrencyValueTransfer("");
            setToCurrencyValueTransfer("");
            setErrors({
                fromCurrency: "",
                toCurrency: "",
            });
            return;
        }

        if (inputValue.length > 10) return;

        let convertedValue = 0;
        const amount = parseFloat(inputValue) || 0;
        const totalFee = (convertedWireFee || 0) + (convertedCommissionFee || 0) + (convertedAgentFee || 0);

        setFromCurrencyValueTransfer(inputValue);

        if (amount <= 10 || amount < totalFee) {
            setErrors({
                fromCurrency: `Amount must be greater than 10, total fees (${totalFee.toFixed(2)}), and cannot be negative.`,
                toCurrency: "",
            });
            setToCurrencyValueTransfer(0);
            setToCurrencyDisplay("0.00");
            return;
        }

        if (selectedFromCurrencyTransfer?.value === "SGD") {
            // Scenario 1: From currency is SGD
            if (transferRate > 0) {
                convertedValue = (amount - totalFee) / transferRate;
            } else {
                setErrors({
                    fromCurrency: "Transfer rate is invalid.",
                    toCurrency: "",
                });
                setToCurrencyValueTransfer(0);
                return;
            }
        } else if (selectedToCurrencyTransfer?.value === "SGD") {
            // Scenario 2: To currency is SGD
            if (buyRate > 0) {
                convertedValue = (amount * buyRate) - totalFee;

                if (convertedValue < 1) {
                    setErrors({
                        fromCurrency: "",
                        toCurrency: "Converted SGD cannot be less than 1",
                    });
                    setToCurrencyValueTransfer(0);
                    setToCurrencyDisplay("0.00");
                    return;
                }
            } else {
                setErrors({
                    fromCurrency: "Invalid buy rate for conversion.",
                    toCurrency: "",
                });
                setToCurrencyValueTransfer(0);
                setToCurrencyDisplay("0.00");
                return;
            }
        } else {
            // Scenario 3: Neither currency is SGD
            if (buyRate <= 0 || transferRate <= 0) {
                setErrors({
                    fromCurrency: "Invalid buy or transfer rates.",
                    toCurrency: "",
                });
                setToCurrencyValueTransfer(0);
                setToCurrencyDisplay("0.00");
                return;
            }

            const subAmount = amount - totalFee;
            const convertedSGD = subAmount * buyRate;
            convertedValue = convertedSGD / transferRate;
        }

        setErrors({
            fromCurrency: "",
            toCurrency: "",
        });

        setToCurrencyValueTransfer(convertedValue);
        setToCurrencyDisplay(convertedValue.toFixed(2));
    };

    const convertToTransferCurrency = (e) => {
        const inputValue = e.target.value;

        setToCurrencyDisplay(inputValue);



        if (inputValue === "") {
            setToCurrencyValueTransfer("");
            setToCurrencyDisplay("");
            setErrors({
                fromCurrency: "",
                toCurrency: "",
            });
            return;
        }

        if (inputValue.length > 10) return;

        let convertedValue = 0;
        const amount = parseFloat(inputValue) || 0;
        const totalFee = (convertedWireFee || 0) + (convertedCommissionFee || 0) + (convertedAgentFee || 0);

        setToCurrencyValueTransfer(inputValue);

        if (amount <= 0 || amount < 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                toCurrency: `Amount must be greater than 10 and cannot be negative.`,
            }));
            setFromCurrencyValueTransfer(0);
            setFromCurrencyDisplay("0.00");
            return;
        }

        if (selectedFromCurrencyTransfer?.value === "SGD") {
            // Scenario 1: From currency is SGD
            if (transferRate > 0) {
                convertedValue = (amount * transferRate) + totalFee;
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    toCurrency: "Invalid transfer rate.",
                }));
                setFromCurrencyValueTransfer(0);
                setFromCurrencyDisplay("0.00");
                return;
            }
        } else if (selectedToCurrencyTransfer?.value === "SGD") {
            // Scenario 2: To currency is SGD
            if (buyRate > 0) {
                convertedValue = (amount + totalFee) / buyRate;
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    toCurrency: "Invalid buy rate for conversion.",
                }));
                setFromCurrencyValueTransfer(0);
                setFromCurrencyDisplay("0.00");
                return;
            }
        } else {
            // Scenario 3: Neither currency is SGD
            if (buyRate <= 0 || transferRate <= 0) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    toCurrency: "Invalid buy or transfer rates.",
                }));
                setFromCurrencyValueTransfer(0);
                setFromCurrencyDisplay("0.00");
                return;
            }

            const convertedSGD = amount * transferRate;
            convertedValue = (convertedSGD / buyRate) + totalFee;
        }

        setErrors({
            fromCurrency: "",
            toCurrency: "",
        });

        setFromCurrencyValueTransfer(convertedValue);
        setFromCurrencyDisplay(convertedValue.toFixed(2));
    };


    const getAvaTransferCurrency = async () => {
        try {
            const avaCurrency = await getApiData(`api/RateMaster/GetBranchForCurrencyAll?rateType=3`);

            const distinctCurrencies = Array.from(
                new Set(avaCurrency.data.map(currency => currency.currencyCode))
            ).map(currencyCode => avaCurrency.data.find(currency => currency.currencyCode === currencyCode));

            const formattedCurrency = distinctCurrencies
                .map(currency => ({
                    label: currency.currencyName,
                    value: currency.currencyCode,
                    currencyCode: currency.currencyCode,
                }))

            setFromCurrencyTransfer(formattedCurrency);
            setToCurrencyTransfer(formattedCurrency);
        } catch (error) {
            console.error("Error fetching currencies", error);
        }
    };

    const fetchTrancfer = async (fromCurr) => {
        setIsLoading(true);
        const resp = await getApiData(`api/RateMaster/GetTransactionRate?fromCurr=${fromCurr}`);
        if (resp.success === true) {
            setBuyRate(parseFloat(resp.data.buyRate));
        } else {
            setBuyRate(null);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (selectedFromCurrencyTransfer?.value !== "SGD" &&
            selectedFromCurrencyTransfer?.value !== undefined) {
            fetchTrancfer(selectedFromCurrencyTransfer?.value);
        } else {
            console.log("not");
        }
        setFromCurrencyValueTransfer(0);
        setToCurrencyValueTransfer(0);
    }, [selectedFromCurrencyTransfer]);

    const validateSubmit = () => {
        if (!selectedBeneficiary) {
            setBeneficiaryError("Please select or create a beneficiary.");
            return false;
        }
        if (!toCurrencyValueTransfer || parseFloat(toCurrencyValueTransfer) === 0) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                toCurrency: `${toCurrencyTransfer} cannot be 0 or less than 0.`,
            }));
            return false;
        }
        return true;
    };

    const filteredBeneficiaries = beneficiary.filter(b =>
        b.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (typedBeneficiaryName) {
        filteredBeneficiaries.push({
            label: `Create "${typedBeneficiaryName}" Beneficiary`,
            value: "create-new",
        });
    }

    if (filteredBeneficiaries.length === 0) {
        filteredBeneficiaries.push({
            label: `Create New Beneficiary for ${selectedToCurrencyTransfer?.currencyCode} currency`,
            value: "create-new",
        });
    }

    useEffect(() => {
        const sgdCurrency = fromCurrencyTransfer.find(currency => currency.value === "SGD");
        setSelectedFromCurrencyTransfer(sgdCurrency || null);
    }, [fromCurrencyTransfer]);

    const openADDBeneficiary = () => {
        //toggleAddModal();
        //setSearchTerm("");
        navigate("/CustomerBeneficiary");
    };

    const validateDate = (selectedDate) => {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        const selectedDateObj = new Date(selectedDate);

        if (!selectedDate) {
            setErrors({ date: 'Date is required' });
        } else if (selectedDateObj < currentDate) {
            setErrors({ date: 'You cannot select a past date' });
        } else {
            setErrors({});
        }
    };

    return (
        <React.Fragment>
            <ToastContainer closeButton={false} limit={1} />
            <AVEBeneficiary currencies={currencies} isOpen={modal1} toggle={toggleAddModal} editMode={3} />
            <Container className='min-vh-100 mb-3' style={{ marginTop: '10rem', }}>

                <Card  >
                    <CardBody  >
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <CardTitle className="h4">Transaction</CardTitle>
                            <div className="ms-3">
                                <FontAwesomeIcon
                                    icon={faQuestionCircle}
                                    onClick={handleHelpClick}
                                    color="#556ee6"
                                    id="helpIcon"
                                    style={{ fontSize: '1.2em', cursor: "pointer" }}
                                />
                                <Tooltip
                                    placement="top"
                                    isOpen={tooltipOpen}
                                    target="helpIcon"
                                    toggle={() => setTooltipOpen(!tooltipOpen)}
                                >
                                    Help
                                </Tooltip>
                            </div>
                        </div>

                        <Nav tabs className="nav-tabs-custom nav-justified">
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: customActiveTab === "1",
                                    })}
                                    onClick={() => {
                                        toggleCustom("1");
                                    }}
                                >
                                    Buying
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: customActiveTab === "2",
                                    })}
                                    onClick={() => {
                                        toggleCustom("2");
                                    }}
                                >
                                    Selling
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: customActiveTab === "3",
                                    })}
                                    onClick={() => {
                                        toggleCustom("3");
                                    }}
                                >
                                    Transfer
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <TabContent activeTab={customActiveTab} className="p-3 text-muted">

                            <TabPane tabId="1">
                                <Form onSubmit={handleSubmitBuying}>

                                    <Row>

                                        <Col md={6}>
                                            <Label>You Give</Label>
                                            <div style={{ display: 'flex', alignItems: 'center', border: '2px solid #ced4da', background: '#F8F8F8', padding: '2px' }}>
                                                <img
                                                    src={getCurrencyFlag(fromCurrencyBuying)}
                                                    alt={"Currency_Flag"}
                                                    style={{ width: '20px', height: '20px', marginRight: '5px' }}
                                                />
                                                <Input value={fromCurrencyBuying} disabled style={{ border: 'none' }} />
                                            </div>
                                        </Col>

                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label>You Get</Label>
                                                <Select
                                                    options={buyingCurrency}
                                                    styles={SelectStyle}
                                                    value={selectedToCurrencyBuying}
                                                    formatOptionLabel={({ value, label }) => (
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <img src={getCurrencyFlag(value)} alt={"Currency_Flag"} style={{ width: '20px', marginRight: '5px' }} />
                                                            {label}
                                                        </div>
                                                    )}
                                                    onChange={(selected) => {
                                                        setSelectedToCurrencyBuying(selected);
                                                        setSelectedBuyBranch(null);
                                                    }}
                                                />

                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="mb-3">
                                        <Label>Branch</Label>
                                        <Select
                                            id="branch"
                                            styles={SelectStyle}
                                            value={selectedBuyBranch}
                                            onChange={(selected) => handleBranchSelection(selected, 2)}
                                            options={branch}
                                            components={{ Option: CustomOption }}
                                        />

                                    </div>

                                    {selectedBuyBranch && (

                                        <div>
                                            <Row className="mb-3">
                                                <Col md={12}>
                                                    <Label>
                                                        Pick Currency by {' '}
                                                        {optime && optime.startTime && optime.endTime
                                                            ? `${optime.startTime} - ${optime.endTime}`
                                                            : <span style={{ fontSize: '0.8em', color: '#888' }}>No Branch Specific Time available</span>
                                                        }
                                                    </Label>
                                                    <DatePicker
                                                        selected={pickupDT}
                                                        onChange={(date) => {
                                                            setPickupDT(date)
                                                            validateDate(date);
                                                        }}
                                                        showTimeSelect
                                                        timeFormat="HH:mm"
                                                        timeIntervals={30}
                                                        dateFormat="MMMM d, yyyy h:mm aa"
                                                        timeCaption="Time"
                                                        className="form-control"

                                                        placeholderText={currentDate.toLocaleString()}
                                                    />
                                                    {errors.date && <div className="text-danger">{errors.date}</div>}
                                                </Col>
                                            </Row>
                                        </div>

                                    )}

                                    {selectedBuyBranch && (
                                        <>

                                            <div className="mb-3">
                                                <Label>{fromCurrencyBuying} Amount</Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Amount"
                                                    value={fromCurrencyDisplay}
                                                    onChange={convertFromBuyCurrency}
                                                    onFocus={(e) => (e.target.style.border = '2px solid #556ee6')}
                                                />
                                                {errors.fromCurrency && <div className="text-danger">{errors.fromCurrency}</div>}
                                            </div>

                                            {buyCharge > 0 &&
                                                <>

                                                    <Row className="mb-2 text-end">
                                                        <Col lg={6} className="text-start">
                                                            <span>Buy Charges</span>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <span>
                                                                <FontAwesomeIcon icon={faMinus} className="me-1" />
                                                                {buyCharge
                                                                    ? parseFloat(buyCharge).toFixed(2)
                                                                    : "0"
                                                                }
                                                                {" "}SGD

                                                            </span>
                                                        </Col>
                                                    </Row>

                                                    <Row className="mb-2 text-end">
                                                        <Col lg={6} className="text-start">
                                                            <span style={{ fontWeight: 'bold' }}>Total SGD we calculate</span>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <span style={{ fontWeight: 'bold' }}>
                                                                <FontAwesomeIcon icon={faEquals} className="me-1" />
                                                                {fromCurrencyValueBuying && buyCharge && fromCurrencyValueBuying < buyCharge ? 0 : (fromCurrencyValueBuying - buyCharge).toFixed(2)} SGD

                                                            </span>
                                                        </Col>
                                                    </Row>

                                                    <hr />

                                                </>
                                            }

                                            <Row className="mb-2 text-end">
                                                <Col lg={6} className="text-start">
                                                    <span>Exchange Rate</span>
                                                </Col>
                                                <Col lg={6}>
                                                    <span>
                                                        <FontAwesomeIcon icon={faDivide} className="me-1" />
                                                        {parseFloat(buyExchnageRate).toFixed(4)}
                                                    </span>
                                                </Col>
                                            </Row>


                                            <div className="mb-2">
                                                <Label>{selectedToCurrencyBuying?.value} Amount</Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Amount"
                                                    value={toCurrencyDisplay}
                                                    onChange={convertToBuyCurrency}
                                                    onFocus={(e) => (e.target.style.border = '2px solid #556ee6')}
                                                />
                                                {errors.toCurrency && <div className="text-danger">{errors.toCurrency}</div>}
                                            </div>

                                        </>
                                    )}

                                    <div className="d-flex justify-content-between mt-3 mb-3">
                                        <Button
                                            disabled={isLoading}
                                            onClick={resetTransaction}
                                            className="btn btn-danger w-md"
                                        >
                                            Cancel
                                        </Button>

                                        <Button
                                            disabled={isLoading}
                                            onClick={handleSubmitBuying}
                                            className="btn bg-primary w-md"
                                        >
                                            {isLoading ? "Loading..." : "Submit"}
                                        </Button>
                                    </div>

                                </Form>
                            </TabPane>

                            <TabPane tabId="2">
                                <Form onSubmit={handleSubmitSelling}>

                                    <Row>

                                        <Col md={6}>

                                            <div className="mb-3">
                                                <Label>You Give</Label>
                                                <Select
                                                    value={selectedFromCurrencySelling}
                                                    formatOptionLabel={({ value, label }) => (
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <img src={getCurrencyFlag(value)} alt={"Currency_Flag"} style={{ width: '20px', marginRight: '5px' }} />
                                                            {label}
                                                        </div>
                                                    )}
                                                    onChange={setSelectedFromCurrencySelling}
                                                    options={sellingCurrency}
                                                    styles={SelectStyle}
                                                />
                                            </div>
                                        </Col>

                                        <Col md={6}>

                                            <div className="mb-3">
                                                <Label>You Get</Label>
                                                <div style={{ display: 'flex', alignItems: 'center', border: '1px solid #ced4da', background: '#F8F8F8', padding: '2px' }}>
                                                    <img
                                                        src={getCurrencyFlag(fromCurrencyBuying)}
                                                        alt={`${fromCurrencyBuying} flag`}
                                                        style={{ width: '20px', height: '20px', marginRight: '5px' }}
                                                    />
                                                    <Input value={toCurrencySelling} disabled style={{ border: 'none' }} />
                                                </div>

                                            </div>
                                        </Col>

                                    </Row>

                                    <div className="mb-3">
                                        <Label>Branch</Label>
                                        <Select
                                            id="activeBranch"
                                            value={selectedSellBranch}
                                            onChange={(selected) => handleBranchSelection(selected, 1)}
                                            options={activebranch}
                                            components={{ Option: CustomOption }}
                                        />
                                    </div>
                                    {noRatesMessage ? (
                                        <div className="danger">
                                            {noRatesMessage}
                                        </div>
                                    ) : null}

                                    {selectedSellBranch && (
                                        <div>
                                            <Row className="mb-3">
                                                <Col md={12}>
                                                    <Label>
                                                        Pick Currency by {' '}
                                                        {optime && optime.startTime && optime.endTime
                                                            ? `${optime.startTime} - ${optime.endTime}`
                                                            : <span style={{ fontSize: '0.8em', color: '#888' }}>No Branch Specific Time available</span>
                                                        }
                                                    </Label>
                                                    <DatePicker
                                                        selected={pickupDT}
                                                        onChange={(date) => {
                                                            setPickupDT(date)
                                                            validateDate(date);
                                                        }}
                                                        showTimeSelect
                                                        timeFormat="HH:mm"
                                                        timeIntervals={30}
                                                        dateFormat="MMMM d, yyyy h:mm aa"
                                                        timeCaption="Time"
                                                        className="form-control"

                                                        placeholderText={currentDate.toLocaleString()}
                                                    />
                                                    {errors.date && <div className="text-danger">{errors.date}</div>}
                                                </Col>
                                            </Row>
                                        </div>
                                    )}

                                    {selectedSellBranch && (
                                        <>

                                            <div className="mb-3">
                                                <Label>{selectedFromCurrencySelling?.value} Amount</Label>

                                                <Input
                                                    type="text"
                                                    value={fromCurrencyDisplay}
                                                    className="form-control"
                                                    placeholder="Enter Amount"
                                                    onChange={convertFromSellCurrency}
                                                />
                                                {errors.fromCurrency && <div className="text-danger">{errors.fromCurrency}</div>}
                                            </div>


                                            {sellCharge > 0 &&
                                                <>

                                                    <Row className="mb-2 text-end">
                                                        <Col lg={6} className="text-start">
                                                            <span>Converted SGD</span>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <span>
                                                                <FontAwesomeIcon icon={faEquals} className="me-1" />
                                                                {fromCurrencyValueSelling
                                                                    ? (fromCurrencyValueSelling * parseFloat(selectedSellBranch?.conversionValue)).toFixed(2)
                                                                    : 0
                                                                }
                                                                {" "}
                                                                SGD
                                                            </span>
                                                        </Col>
                                                    </Row>

                                                    <Row className="mb-2 text-end">
                                                        <Col lg={6} className="text-start">
                                                            <span>Sell Charges</span>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <span>

                                                                <FontAwesomeIcon icon={faMinus} className="me-1" />
                                                                {sellCharge
                                                                    ? parseFloat(sellCharge).toFixed(2)
                                                                    : "0"
                                                                }
                                                                {" "}SGD
                                                            </span>
                                                        </Col>
                                                    </Row>

                                                    <hr />

                                                </>

                                            }

                                            <Row className="mb-2 text-end">
                                                <Col lg={6} className="text-start">
                                                    <span>Exchange Rate</span>
                                                </Col>
                                                <Col lg={6}>
                                                    <span>
                                                        <FontAwesomeIcon icon={faX} className="me-1" />
                                                        {selectedSellBranch.conversionValue
                                                            ? `${parseFloat(selectedSellBranch.conversionValue).toFixed(4)} SGD`
                                                            : "N/A"}
                                                    </span>
                                                </Col>
                                            </Row>

                                            <div className="mb-2">
                                                <Label>{toCurrencySelling} Amount</Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Amount"
                                                    value={toCurrencyDisplay}
                                                    onChange={convertToSellCurrency}
                                                    onFocus={(e) => (e.target.style.border = '2px solid #556ee6')}
                                                />
                                                {errors.toCurrency && <div className="text-danger">{errors.toCurrency}</div>}
                                            </div>
                                        </>
                                    )}
                                    <div className="d-flex justify-content-between mt-3 mb-3">
                                        <Button
                                            disabled={isLoading}
                                            onClick={resetTransaction}
                                            className="btn btn-danger w-md"
                                        >
                                            Cancel
                                        </Button>

                                        <Button
                                            disabled={isLoading}
                                            className="btn bg-primary w-md"
                                            onClick={handleSubmitSelling}
                                        >
                                            {isLoading ? "Loading..." : "Submit"}
                                        </Button>
                                    </div>
                                </Form>
                            </TabPane>

                            <TabPane tabId="3">
                                <Form onSubmit={handleSubmitTransfer}>
                                    <Row>
                                        <Col md={12}>
                                            <div className="mb-2">
                                                <Label>Select Beneficiary</Label>
                                                <ReactSelect
                                                    styles={SelectStyle}
                                                    value={selectedBeneficiary}
                                                    onInputChange={(inputValue) => {
                                                        setSearchTerm(inputValue);
                                                        setTypedBeneficiaryName(inputValue);
                                                    }}
                                                    onChange={(selectedOption) => {
                                                        if (selectedOption?.value === "create-new") {
                                                            openADDBeneficiary();
                                                        } else {
                                                            setSelectedBeneficiary(selectedOption);
                                                            const beneficiaryCurrency = toCurrencyTransfer.find(
                                                                (currency) => currency.value === selectedOption?.currencyName
                                                            );
                                                            setSelectedToCurrencyTransfer(beneficiaryCurrency || null);
                                                        }
                                                    }}
                                                    options={filteredBeneficiaries}
                                                    isClearable
                                                />
                                                {beneficiaryError && <div className="text-danger mt-1">{beneficiaryError}</div>}
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Label>You Give</Label>
                                            <ReactSelect
                                                styles={SelectStyle}
                                                value={selectedFromCurrencyTransfer}
                                                formatOptionLabel={({ value, label }) => (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <img
                                                            src={getCurrencyFlag(value)}
                                                            alt={"Currency flag"}
                                                            style={{ width: '20px', marginRight: '5px' }}
                                                        />
                                                        <span>{label}</span>
                                                    </div>
                                                )}
                                                onChange={setSelectedFromCurrencyTransfer}
                                                options={fromCurrencyTransfer}
                                                isDisabled={!selectedBeneficiary}
                                            />
                                        </Col>

                                        <Col md={6}>
                                            <Label>{selectedFromCurrencyTransfer?.currencyCode} Amount </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Amount"
                                                value={fromCurrencyDisplay}
                                                disabled={!selectedBeneficiary}
                                                onChange={convertFromTransferCurrency}
                                                onFocus={(e) => (e.target.style.border = '2px solid #556ee6')}
                                            />
                                            {errors.fromCurrency && <div className="text-danger">{errors.fromCurrency}</div>}
                                        </Col>
                                    </Row>

                                    {/* Updated rendering logic for the UI */}
                                    {selectedBeneficiary && (
                                        <>
                                            {(() => {
                                                if (selectedFromCurrencyTransfer?.value === "SGD" && selectedToCurrencyTransfer?.value !== "SGD") {
                                                    // Scenario 1: From Currency = SGD, To Currency = Foreign Currency
                                                    return (
                                                        <>
                                                            {/* Charges in SGD */}
                                                            <Row className="mb-3 text-end">
                                                                <Col lg={6} className="text-start">
                                                                    <span>Wire Transfer Fee</span>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <span>
                                                                        <FontAwesomeIcon icon={faMinus} /> {wireFee.toFixed(2)} SGD
                                                                    </span>
                                                                </Col>
                                                            </Row>

                                                            <Row className="mb-3 text-end">
                                                                <Col lg={6} className="text-start">
                                                                    <span>Commission Fee</span>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <span>
                                                                        <FontAwesomeIcon icon={faMinus} /> {commissionFee.toFixed(2)} SGD
                                                                    </span>
                                                                </Col>
                                                            </Row>

                                                            <Row className="mb-3 text-end">
                                                                <Col lg={6} className="text-start">
                                                                    <span>Agent Fee</span>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <span>
                                                                        <FontAwesomeIcon icon={faMinus} /> {agentFee.toFixed(2)} SGD
                                                                    </span>
                                                                </Col>
                                                            </Row>

                                                            <hr />

                                                            {/* Total SGD */}
                                                            <Row className="mb-3 text-end">
                                                                <Col lg={6} className="text-start">
                                                                    <span>Total SGD</span>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <span>
                                                                        <FontAwesomeIcon icon={faEquals} />
                                                                        {" "}
                                                                        {fromCurrencyValueTransfer > 0
                                                                            ? (fromCurrencyValueTransfer - (wireFee + commissionFee + agentFee)).toFixed(2)
                                                                            : 0
                                                                        } SGD
                                                                    </span>
                                                                </Col>
                                                            </Row>

                                                            {/* Transfer Rate */}
                                                            <Row className="mb-3 text-end">
                                                                <Col lg={6} className="text-start">
                                                                    <span>Transfer Rate</span>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <span>
                                                                        <FontAwesomeIcon icon={faDivide} />
                                                                        {" "}
                                                                        {transferRate.toFixed(4)}
                                                                    </span>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    );
                                                } else if (selectedFromCurrencyTransfer?.value !== "SGD" && selectedToCurrencyTransfer?.value === "SGD") {
                                                    // Scenario 2: From Currency = Foreign Currency, To Currency = SGD
                                                    if (buyRate <= 0) {
                                                        return (
                                                            <Row className="mb-3">
                                                                <Col>
                                                                    <span className="text-danger">No transfer available</span>
                                                                </Col>
                                                            </Row>
                                                        );
                                                    }

                                                    return (
                                                        <>
                                                            {/* Converted SGD */}
                                                            <Row className="mb-3 text-end">
                                                                <Col lg={6} className="text-start">
                                                                    <span>Converted SGD</span>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <span>
                                                                        <FontAwesomeIcon icon={faEquals} />
                                                                        {" "}
                                                                        {(fromCurrencyValueTransfer * buyRate).toFixed(2)} SGD
                                                                    </span>
                                                                </Col>
                                                            </Row>

                                                            {/* Charges in SGD */}
                                                            <Row className="mb-3 text-end">
                                                                <Col lg={6} className="text-start">
                                                                    <span>Wire Transfer Fee</span>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <span>
                                                                        <FontAwesomeIcon icon={faMinus} />
                                                                        {" "}
                                                                        {wireFee.toFixed(2)} SGD
                                                                    </span>
                                                                </Col>
                                                            </Row>

                                                            <Row className="mb-3 text-end">
                                                                <Col lg={6} className="text-start">
                                                                    <span>Commission Fee</span>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <span>
                                                                        <FontAwesomeIcon icon={faMinus} />
                                                                        {" "}
                                                                        {commissionFee.toFixed(2)} SGD
                                                                    </span>
                                                                </Col>
                                                            </Row>

                                                            <Row className="mb-3 text-end">
                                                                <Col lg={6} className="text-start">
                                                                    <span>Agent Fee</span>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <span>
                                                                        <FontAwesomeIcon icon={faMinus} />
                                                                        {" "}
                                                                        {agentFee.toFixed(2)} SGD
                                                                    </span>
                                                                </Col>
                                                            </Row>

                                                            {/* Transfer Rate */}
                                                            <Row className="mb-3 text-end">
                                                                <Col lg={6} className="text-start">
                                                                    <span>Transfer Rate</span>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <span>
                                                                        <FontAwesomeIcon icon={faX} />
                                                                        {" "}
                                                                        {buyRate.toFixed(4)}
                                                                    </span>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    );
                                                } else if (selectedFromCurrencyTransfer?.value !== "SGD" && selectedToCurrencyTransfer?.value !== "SGD") {
                                                    // Scenario 3: From Currency = Foreign Currency, To Currency = Foreign Currency
                                                    return (
                                                        <>
                                                            {/* Charges in From Currency */}
                                                            <Row className="mb-3 text-end">
                                                                <Col lg={6} className="text-start">
                                                                    <span>Wire Transfer Fee</span>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <span>
                                                                        <FontAwesomeIcon icon={faMinus} /> {convertedWireFee.toFixed(2)} {selectedFromCurrencyTransfer?.value}
                                                                    </span>
                                                                </Col>
                                                            </Row>

                                                            <Row className="mb-3 text-end">
                                                                <Col lg={6} className="text-start">
                                                                    <span>Commission Fee</span>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <span>
                                                                        <FontAwesomeIcon icon={faMinus} /> {convertedCommissionFee.toFixed(2)} {selectedFromCurrencyTransfer?.value}
                                                                    </span>
                                                                </Col>
                                                            </Row>

                                                            <Row className="mb-3 text-end">
                                                                <Col lg={6} className="text-start">
                                                                    <span>Agent Fee</span>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <span>
                                                                        <FontAwesomeIcon icon={faMinus} /> {convertedAgentFee.toFixed(2)} {selectedFromCurrencyTransfer?.value}
                                                                    </span>
                                                                </Col>
                                                            </Row>

                                                            <hr />

                                                            {/* Total From Currency */}
                                                            <Row className="mb-3 text-end">
                                                                <Col lg={6} className="text-start">
                                                                    <span>Total {selectedFromCurrencyTransfer?.value}</span>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <span>
                                                                        <FontAwesomeIcon icon={faEquals} />
                                                                        {(fromCurrencyValueTransfer - (convertedWireFee + convertedCommissionFee + convertedAgentFee)).toFixed(2)} {selectedFromCurrencyTransfer?.value}
                                                                    </span>
                                                                </Col>
                                                            </Row>

                                                            {/* Transfer Rate */}
                                                            <Row className="mb-3 text-end">
                                                                <Col lg={6} className="text-start">
                                                                    <span>Transfer Rate</span>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <span>
                                                                        {(toCurrencyValueTransfer / fromCurrencyValueTransfer).toFixed(4)}
                                                                    </span>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    );
                                                }
                                            })()}
                                        </>
                                    )}

                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Label>Beneficiary Receives</Label>
                                            {selectedBeneficiary ? (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "5px",
                                                        fontSize: "16px",
                                                    }}>
                                                    <img
                                                        src={getCurrencyFlag(selectedToCurrencyTransfer?.currencyCode)}
                                                        alt={"Currency flag"}
                                                        style={{ width: "20px", marginRight: "5px" }}
                                                    />
                                                    <span>{selectedBeneficiary.currencyName}</span>
                                                </div>
                                            ) : (
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    disabled
                                                    value="Please select Beneficiary"
                                                />
                                            )}
                                        </Col>

                                        <Col md={6}>
                                            <Label>{selectedToCurrencyTransfer?.currencyCode || "Currency"} Amount</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Amount"
                                                value={toCurrencyDisplay}
                                                disabled={!selectedBeneficiary}
                                                onChange={convertToTransferCurrency}
                                                onFocus={(e) => (e.target.style.border = "2px solid #556ee6")}
                                            />
                                            {errors.toCurrency && <div className="text-danger">{errors.toCurrency}</div>}
                                        </Col>
                                    </Row>

                                    <div className="d-flex justify-content-between mt-3">
                                        <Button
                                            disabled={isLoading}
                                            onClick={resetTransaction}
                                            className="btn btn-danger w-md"
                                        >
                                            Cancel
                                        </Button>

                                        <Button
                                            disabled={isLoading}
                                            className="btn bg-primary w-md"
                                            onClick={handleSubmitTransfer}
                                        >
                                            {isLoading ? "Loading..." : "Submit"}
                                        </Button>
                                    </div>
                                </Form>
                            </TabPane>

                        </TabContent>
                    </CardBody>
                </Card>

            </Container>
        </React.Fragment>
    );
};

export default AddTransaction;
