import Swal from "sweetalert2";
import ReactSelect from 'react-select';
import DatePicker from "react-datepicker";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Loader from "../../components/Common/Loader";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import { Form, Row, Col, Label, Input } from 'reactstrap';
import { SelectStyle } from "../../common/data/SelectStyle";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getApiData, postApiData } from "../../helpers/axiosHelper";
import { faMinus, faEquals, faDivide } from '@fortawesome/free-solid-svg-icons';

const BuyForm = ({ branchID, isAllBranch, buyingCurrency, rateType, rate, buyCharge, rateHisID, closeModal, }) => {

    console.log({ branchID, isAllBranch, buyingCurrency, rateType, rate, buyCharge, rateHisID, });

    const navigate = useNavigate();

    const fromCurrencyBuying = "SGD";
    const [fromCurrencyDisplay, setFromCurrencyDisplay] = useState("0.00");
    const [fromCurrencyValueBuying, setFromCurrencyValueBuying] = useState(0);

    const toCurrencyBuying = buyingCurrency;
    const [toCurrencyDisplay, setToCurrencyDisplay] = useState("0.00");
    const [toCurrencyValueBuying, setToCurrencyValueBuying] = useState(0);

    const [branchOptions, setBranchOptions] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState(null);

    const [optime, setOptime] = useState({});

    const currentDate = new Date();
    const [startDate, setStartDate] = useState(null);

    const [errors, setErrors] = useState({});

    const [currencies, setCurrencies] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const fetchCurrencies = async () => {
        try {
            const response = await getApiData('api/Currency/GetAllCurrencies');
            if (response.success === true) {
                setCurrencies(response.data);
            } else {
                setCurrencies([]);
            }
        } catch (error) {
            console.error("Error fetching currencies", error);
        }
    };

    const validateDate = (selectedDate) => {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        const selectedDateObj = new Date(selectedDate);

        if (!selectedDate) {
            setErrors({ date: 'Date is required' });
        } else if (selectedDateObj < currentDate) {
            setErrors({ date: 'You cannot select a past date' });
        } else {
            setErrors({});
        }
    };

    const handleSubmitBuying = async (e) => {
        e.preventDefault();
        if (isSubmitting) return;
        setIsSubmitting(true);

        const buyingData = {
            fromCurrType: fromCurrencyBuying,
            fromCurrValue: handlePrecision(fromCurrencyValueBuying),

            toCurrType: toCurrencyBuying,
            toCurrValue: handlePrecision(toCurrencyValueBuying),

            toRateHistoryId: rateHisID,

            exchangeRate: rate,
            transactionType: 2,

            fromBranchId: selectedBranch?.value,
            pickup: startDate ? startDate : "",
        };

        try {

            const response = await postApiData('api/Transaction/InsertTransaction', buyingData);
            if (response.success === true) {

                toast.success("Transaction completed successfully.", {
                    position: "top-right",
                    autoClose: 2000
                });
            } else {
                Swal.fire({
                    text: response.message + "! Click Ok to go to profile ",
                    icon: "info",
                    showConfirmButton: true,
                    showCancelButton: false,
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#556ee6",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        navigate("/profile");
                    } else {
                        closeModal();
                    }
                });
            }
            closeModal();
        } catch (error) {
            console.error("Error during transaction", error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const getBranch = async () => {
        try {
            setIsLoading(true);
            const apiEndpoint = branchID === 0
                ? `api/BranchMaster/GetBranchWithNoRS`
                : `api/RateMaster/GetBranchStockByCurrency?branchId=${branchID}&currencyCode=${toCurrencyBuying}&rateType=${rateType}`;

            const response = await getApiData(apiEndpoint);
            setIsLoading(false);

            let filteredBranches;
            if (isAllBranch === 1) {
                if (branchID === 0) {
                    filteredBranches = response
                        .filter(branch => branch.active === 1)
                        .map(branch => ({
                            value: branch.branchId,
                            label: branch.branchName,
                            address: `${branch.street}, ${branch.city}, ${branch.postalcode}`,
                        }));
                } else {
                    filteredBranches = response.data
                        .filter(branch => branch.active === true && branch.allBranch === 0)
                        .map(branch => ({
                            value: branch.branchId,
                            label: branch.branchName,
                            allBranch: branch.allBranch,
                            address: `${branch.street},${branch.city},${branch.postalcode}`,
                            availableAmount: branch.availableAmount,
                        }));
                }

            } else {
                filteredBranches = response.data
                    .filter(branch => branch.active === true)
                    .map(branch => ({
                        value: branch.branchId,
                        label: branch.branchName,
                        allBranch: branch.allBranch,
                        address: `${branch.street},${branch.city},${branch.postalcode}`,
                        availableAmount: branch.availableAmount,
                    }));
            }
            setBranchOptions(filteredBranches);
            if (filteredBranches.length === 1) {
                setSelectedBranch(filteredBranches[0]);
                getBranchOpTime(filteredBranches[0].value);
            }
        } catch (error) {
            console.error("Error fetching branches", error);
        }
    };

    const getBranchOpTime = async (branchId) => {
        try {
            const branchResponse = await getApiData(`api/BranchMaster/GetBranchWithOperation?branchId=${branchId}`);
            const operationalTime = branchResponse.data.operationalTime[0];
            if (operationalTime) {
                setOptime({
                    startTime: convertTo12HourFormat(operationalTime.startTime),
                    endTime: convertTo12HourFormat(operationalTime.endTime),
                });
            }
        } catch (error) {
            console.error("Error fetching branch operational time", error);
        }
    };

    const convertTo12HourFormat = (time) => {
        const [hour, minute] = time.split(":");
        const ampm = hour >= 12 ? "PM" : "AM";
        const formattedHour = hour % 12 || 12;
        return `${formattedHour}:${minute} ${ampm}`;
    };

    const handlePrecision = (value) => {
        return Math.round((value + Number.EPSILON) * 1000000000) / 1000000000;
    };

    const handleNumericInput = (e) => {
        const allowedKeys = [
            "Backspace",
            "Delete",
            "ArrowLeft",
            "ArrowRight",
            "Tab",
            "Enter"
        ];

        const inputValue = e.target.value;

        if (allowedKeys.includes(e.key)) return;

        if (e.key === ".") {
            if (inputValue.includes(".")) {
                e.preventDefault();
                return;
            }
        } else if (!/^\d$/.test(e.key)) {
            e.preventDefault();
            return;
        }

        if (inputValue.length >= 10) {
            e.preventDefault();
        }
    };

    const convertFromCurrency = (e) => {
        const inputValue = e.target.value.trim();

        setFromCurrencyDisplay(inputValue);

        if (inputValue === "") {
            setFromCurrencyValueBuying(0);
            setToCurrencyValueBuying(0);
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: ""
            }));
            return;
        }

        const amount = parseFloat(inputValue) || 0;
        const charges = parseFloat(buyCharge) || 0;

        setFromCurrencyValueBuying(amount);

        if (amount <= 0) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "SGD cannot be 0 or less tham 0.",
                toCurrency: ""
            }));
            setToCurrencyValueBuying(0);
            setToCurrencyDisplay("0.00");
            return;
        }

        if (amount <= 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "SGD must be greater than 10.",
                toCurrency: ""
            }));
            setToCurrencyValueBuying(0);
            setToCurrencyDisplay("0.00");
            return;
        }

        if (((amount - charges) / rate) >= selectedBranch?.availableAmount) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: `${toCurrencyBuying} must not be greater than available stock.`
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                toCurrency: "",
            }));
        }

        if (amount <= charges) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "SGD must be greater than the buying fee.",
                toCurrency: ""
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
            }));
        }

        const convertedToCurrency = (amount - charges) / rate;

        if (convertedToCurrency <= 1) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: "Converted amount must be greater than 1."
            }));
            setToCurrencyValueBuying(0);
            setToCurrencyDisplay("0.00");
            return;
        }

        setToCurrencyValueBuying(
            convertedToCurrency > 0 ? convertedToCurrency : 0
        );
        setToCurrencyDisplay(convertedToCurrency.toFixed(2));
    };

    const convertToCurrency = (e) => {
        const inputValue = e.target.value.trim();

        setToCurrencyDisplay(inputValue);

        if (inputValue === "") {
            setFromCurrencyValueBuying(0);
            setToCurrencyValueBuying(0);
            setFromCurrencyDisplay("0.00");
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: ""
            }));
            return;
        }

        const amount = parseFloat(inputValue) || 0;
        const charges = parseFloat(buyCharge) || 0;

        setToCurrencyValueBuying(amount);

        if (amount <= 0) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: `${toCurrencyBuying} cannot be 0 or less than 0.`
            }));
            setFromCurrencyValueBuying(0);
            setFromCurrencyDisplay("0.00");
            return;
        }

        if (amount >= selectedBranch?.availableAmount) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: `${toCurrencyBuying} must not be greater than available stock.`
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                toCurrency: ""
            }));
        }

        if ((amount * rate) <= charges) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "SGD must be greater than the buying fee.",
                toCurrency: ""
            }));
            setFromCurrencyValueBuying(0);
            setFromCurrencyDisplay("0.00");
            return;
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
            }));
        }

        const convertedFromCurrency = (amount * rate) + charges;
        setFromCurrencyValueBuying(
            convertedFromCurrency > 0 ? convertedFromCurrency : 0
        );
        setFromCurrencyDisplay(convertedFromCurrency.toFixed(2));
    };

    const getCurrencyFlag = (currencyCode) => {
        const currency = currencies.find(c => c.currencyCode === currencyCode);
        return currency ? `data:image/png;base64,${currency.currencyFlag}` : '';
    };

    useEffect(() => {
        if (toCurrencyBuying && rateType) getBranch(branchID, toCurrencyBuying, rateType);
        fetchCurrencies();
    }, [branchID, toCurrencyBuying, rateType]);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (

                <Form onSubmit={handleSubmitBuying}>
                    <Row>
                        <Col md={6}>
                            <Label>You Give</Label>
                            <div style={{ display: 'flex', alignItems: 'center', border: '1px solid #000', background: '#F8F8F8', padding: '2px' }}>
                                <img
                                    src={getCurrencyFlag(fromCurrencyBuying)}
                                    alt={`${fromCurrencyBuying} flag`}
                                    style={{ width: '30px', height: '30px', marginRight: '5px' }}
                                />
                                <Input value={fromCurrencyBuying} disabled style={{ border: 'none', fontWeight: '600', fontSize: '1rem' }} />
                            </div>
                        </Col>
                        <Col md={6}>
                            <Label>You Get</Label>
                            <div style={{ display: 'flex', alignItems: 'center', border: '1px solid #000', background: '#F8F8F8', padding: '2px' }}>
                                <img
                                    src={getCurrencyFlag(toCurrencyBuying)}
                                    alt={`${toCurrencyBuying} flag`}
                                    style={{ width: '30px', height: '30px', marginRight: '5px' }}
                                />
                                <Input value={toCurrencyBuying} disabled style={{ border: 'none', fontWeight: '600', fontSize: '1rem' }} />
                            </div>
                        </Col>
                    </Row>

                    {branchOptions.length === 0 ? (
                        <div className="mt-2 text-center">No stock available</div>
                    ) : (
                        <>
                            <div className="mb-2">
                                <Label>Branch</Label>
                                {branchID === 0 ? (
                                    <ReactSelect
                                        styles={SelectStyle}
                                        options={branchOptions}
                                        value={selectedBranch}
                                        onChange={(option) => {
                                            setSelectedBranch(option);
                                            getBranchOpTime(option.value);
                                        }}
                                        placeholder="Select Branch"
                                    />
                                ) : (
                                    branchOptions.map((branch) => (
                                        <div
                                            key={branch.value}
                                            onClick={() => setSelectedBranch(branch)}
                                            style={{
                                                padding: '10px',
                                                border: selectedBranch?.value === branch.value ? '1px solid #000' : '1px solid #ced4da',
                                                cursor: 'pointer',
                                                marginBottom: '5px',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <div>
                                                <strong>{branch.label}</strong>
                                                <div style={{ fontSize: '0.9em', color: '#888' }}>{branch.address}</div>
                                            </div>
                                            <div style={{ fontSize: '0.9em' }}>Max: {branch.availableAmount} {" "} {toCurrencyBuying}</div>
                                        </div>
                                    ))
                                )}
                                {errors.branch && <div className="text-danger">{errors.branch}</div>}
                            </div>

                            <Row className="mb-2" >
                                <Col md={12} >
                                    <Label>
                                        Pick Currency by {' '}
                                        {optime && optime.startTime && optime.endTime
                                            ? `${optime.startTime} - ${optime.endTime}`
                                            : <span style={{ fontSize: '0.8em', color: '#888' }}>No Branch Specific Time available</span>
                                        }
                                    </Label>
                                    <div style={{ border: '1px solid black', }}>

                                        <DatePicker
                                            selected={startDate}
                                            onChange={(date) => {
                                                setStartDate(date)
                                                validateDate(date);
                                            }}
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            timeIntervals={30}
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                            timeCaption="Time"
                                            className="form-control"

                                            placeholderText={currentDate.toLocaleString()}
                                        />
                                    </div>


                                    {errors.date && <div className="text-danger">{errors.date}</div>}
                                </Col>
                            </Row>

                            <div className="mb-2">
                                <Label>{fromCurrencyBuying} Amount</Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Amount"
                                    value={fromCurrencyDisplay}
                                    onChange={convertFromCurrency}
                                    onKeyDown={handleNumericInput}
                                    onFocus={(e) => (e.target.style.border = '2px solid #556ee6')}
                                />
                                {errors.fromCurrency && <div className="text-danger">{errors.fromCurrency}</div>}

                            </div>

                            {parseFloat(buyCharge) !== 0 &&
                                buyCharge !== "" &&
                                <>
                                    <Row className="mb-2 text-end">
                                        <Col lg={6} className="text-start">
                                            <span>Agent Charges</span>
                                        </Col>
                                        <Col lg={6}>
                                            <span>
                                                <FontAwesomeIcon icon={faMinus} className="me-1" />
                                                {buyCharge
                                                    ? parseFloat(buyCharge).toFixed(2)
                                                    : "0"
                                                }
                                                {" "}SGD
                                            </span>
                                        </Col>
                                    </Row>


                                    <Row className="mb-2 text-end">
                                        <Col lg={6} className="text-start">
                                            <span>Total SGD we calculate</span>
                                        </Col>
                                        <Col lg={6}>
                                            <span>
                                                <FontAwesomeIcon icon={faEquals} className="me-1" />
                                                {fromCurrencyValueBuying
                                                    ? (fromCurrencyValueBuying - parseFloat(buyCharge)).toFixed(2)
                                                    : 0
                                                }
                                                {" "}
                                                SGD

                                            </span>
                                        </Col>
                                    </Row>
                                    <hr />
                                </>
                            }

                            <Row className="mb-2 text-end">
                                <Col lg={6} className="text-start">
                                    <span>Exchange Rate</span>
                                </Col>
                                <Col lg={6}>
                                    <span>
                                        <FontAwesomeIcon icon={faDivide} className="me-1" />
                                        {parseFloat(rate).toFixed(4)} SGD
                                    </span>
                                </Col>
                            </Row>

                            <div className="mb-2">
                                <Label>{toCurrencyBuying} Amount</Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Amount"
                                    value={toCurrencyDisplay}
                                    onKeyDown={handleNumericInput}
                                    onChange={convertToCurrency}
                                    onFocus={(e) => (e.target.style.border = '2px solid #556ee6')}
                                />
                                {errors.toCurrency && <div className="text-danger">{errors.toCurrency}</div>}

                            </div>

                            <div className="text-center">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={
                                        isSubmitting ||
                                        Object.values(errors).some((error) => error !== "") ||
                                        toCurrencyValueBuying <= 0
                                    }
                                >
                                    {isSubmitting ? 'Processing...' : 'Submit'}
                                </button>

                            </div>

                        </>
                    )}
                    <ToastContainer limit={1} />
                </Form>
            )}
        </>
    );
};

export default BuyForm;