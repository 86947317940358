import React, { useEffect, useState, useMemo } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
    Badge,
    Button,
    Card,
    CardBody,
    Row, Col,
    Container,
} from "reactstrap";
import { faEdit, faDollar, faQuestionCircle, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TableContainer from "../../components/Common/TableContainer";
import Loader from "../../components/Common/Loader";
import ViewTransaction from "./ViewTransaction";
import { getApiData } from "../../helpers/axiosHelper";
import { formatDateToUserTimeZone } from "../../common/data/FormateDateTime";
import { mapStatus } from "../../common/data/StatusLabels";
import { Tooltip } from "reactstrap";

const ManageTransaction = () => {
    const transactionTypeMap = {
        2: { label: "Buy", color: "success" },
        1: { label: "Sell", color: "danger" },
        3: { label: "Transfer", color: "primary" },
    };
    const convertTo12HourFormat = (time24) => {
        const [hours, minutes] = time24.split(":");
        const meridiem = hours >= 12 ? "PM" : "AM";
        const hours12 = hours % 12 || 12;
        return `${hours12}:${minutes} ${meridiem}`;
    };

    const handleHelpClick = () => {
        window.open("https://sitapi.crescentexchange.com/help/customer/Manage Transaction.pdf");
    };

    const [optime, setOptime] = useState([]);
    const [modal1, setModal1] = useState(false);
    const [branchID, setBranchID] = useState("");
    const [branchData, setBranchData] = useState();
    const [editMode, setEditMode] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [commentData, setCommentData] = useState([]);
    const [chargesData, setChargesData] = useState(null);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [currencyFlags, setCurrencyFlags] = useState({});
    const [transactionID, setTransactionID] = useState("");
    const [transactionData, setTransactionData] = useState([]);
    const [transactionType, setTransactionType] = useState(null);
    const [sendTransactionDetails, setSendTransactiondetails] = useState(null);

    const Days = {
        0: "All Days",
        1: "Sunday",
        2: "Monday",
        3: "Tuesday",
        4: "Wednesday",
        5: "Thursday",
        6: "Friday",
        7: "Saturday",
    };

    const fetchCurrencyFlags = async () => {
        try {
            const response = await getApiData('api/Currency/GetAllCurrencies');
            const flags = {};
            response.data.forEach(currency => {
                flags[currency.currencyCode] = currency.currencyFlag;
            });
            setCurrencyFlags(flags);
        } catch (error) {
            console.error('Failed to load currency flags', {
                position: "top-right",
                autoClose: 3000,
            });
        }
    };

    const ViewTransactionDetails = (cellProps) => {
        const { transactionId, branchId } = cellProps;

        setTransactionID(transactionId);
        setBranchID(branchId);

        const getTransactionDetails = async () => {
            try {
                // Fetch transaction details
                const transactionResponse = await getApiData(
                    `api/Transaction/GetTransaction?TransactionID=${transactionId}`
                );

                // Fetch branch details
                const branchResponse = await getApiData(
                    `api/BranchMaster/GetBranchWithOperation?branchId=${branchId}`
                );

                // Map operational times to 12-hour format
                const operationalTimes = branchResponse.data.operationalTime.map((item) => ({
                    day: Days[item.day],
                    startTime: convertTo12HourFormat(item.startTime),
                    endTime: convertTo12HourFormat(item.endTime),
                }));

                setBranchData(branchResponse.data.branch);
                setOptime(operationalTimes);
                setSendTransactiondetails(transactionResponse);
                setCommentData(transactionResponse.comments);
                setTransactionType(transactionResponse.transactionType);

                // Fetch transaction charges
                const { transactionType, fromRateHistoryId, toRateHistoryId } = transactionResponse;

                await fetchTransactionCharges(transactionType, fromRateHistoryId, toRateHistoryId);
            } catch (error) {
                console.error("Error occurred:", error);
            }
        };

        getTransactionDetails();
        toggleViewModal();
    };

    const fetchTransactionCharges = async (transactionType, fromRateHistoryId, toRateHistoryId) => {
        try {
            const rateHistoryId = transactionType === 2 ? toRateHistoryId : fromRateHistoryId;

            const chargesResponse = await getApiData(
                `api/RateMaster/GetTransactionCharges?rateHistoryId=${rateHistoryId}`
            );

            if (chargesResponse.success) {
                setChargesData(chargesResponse);
            } else {
                console.error("Failed to fetch charges:", chargesResponse.message);
            }
        } catch (error) {
            console.error("Error fetching transaction charges:", error);
        }
    };

    useEffect(() => {
        fetchCurrencyFlags();
        getTransaction();
    }, []);

    const getTransaction = async () => {
        try {
            setIsLoading(true);
            const response = await getApiData('api/Transaction/GetTransactionByCustomer');
            const mappedResponse = response.data.map((item, index) => ({
                index: index + 1,
                name: item.name,
                branch: item.branchName,
                branchId: item.fromBranchId,
                transactionId: item.transactionId,
                transactionType: item.transactionType,
                date: formatDateToUserTimeZone(item.transactionDate),
                toCurrType: item.toCurrType,
                toCurrValue: item.toCurrValue,
                fromCurrType: item.fromCurrType,
                fromCurrValue: item.fromCurrValue,
                exchangeRate: item.exchangeRate,
                transactingCurrency: item.toCurrType,
                status: mapStatus(item.status),
                pickup: item.pickup ? formatDateToUserTimeZone(item.pickup) : 'No Pickup Date',
            }));
            setTransactionData(mappedResponse);

            setIsLoading(false);
        } catch (error) {
            console.error('Failed to load transactions', error);
            setIsLoading(false);
        }
    };

    const toggleViewModal = () => setModal1(!modal1);


    const columns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "transactionId",
                filterable: false,
                disableFilters: true,
            },
            {
                Header: "Branch",
                accessor: "branch",
                filterable: false,
                disableFilters: true,
            },
            {
                Header: "Currency",
                accessor: "transactingCurrency",
                disableFilters: true,
                Cell: (cellProps) => {
                    const { transactionType, fromCurrType, toCurrType } = cellProps.row.original;
                    const transactingCurrency = transactionType === 1 ? fromCurrType : toCurrType;
                    const { label, color } = transactionTypeMap[transactionType] || {};
                    const flagIcon = currencyFlags[transactingCurrency];

                    if (transactionType === 3) {
                        const fromFlagIcon = currencyFlags[fromCurrType];
                        const toFlagIcon = currencyFlags[toCurrType];

                        return (
                            <div>

                                {fromFlagIcon ? (
                                    <img src={`data:image/png;base64,${fromFlagIcon}`} alt="" style={{ width: '20px', marginRight: '5px' }} />
                                ) : (
                                    <FontAwesomeIcon icon={faDollar} />
                                )}
                                <span>{fromCurrType}</span>
                                <Badge className={`font-size-11 badge-soft-${color}`}>
                                    {label}
                                </Badge>
                                {toFlagIcon ? (
                                    <img src={`data:image/png;base64,${toFlagIcon}`} alt="" style={{ width: '20px', marginLeft: '5px' }} />
                                ) : (
                                    <FontAwesomeIcon icon={faDollar} />
                                )}
                                <span>{toCurrType}</span>
                            </div>
                        );
                    }

                    return (
                        <div>
                            <Badge className={`font-size-11 badge-soft-${color}`}>
                                {label}
                            </Badge>
                            {flagIcon ? (
                                <img src={`data:image/png;base64,${flagIcon}`} alt="" style={{ width: '20px', marginLeft: '5px' }} />
                            ) : (
                                <FontAwesomeIcon icon={faDollar} />
                            )}
                            <span>{transactingCurrency}</span>
                        </div>
                    );
                },
            },
            {
                Header: "From Currency",
                accessor: "fromCurrValue",
                filterable: false,
                disableFilters: true,
                Cell: (cellProps) => {
                    const { fromCurrValue, fromCurrType } = cellProps.row.original;

                    return <span>{fromCurrValue}  <strong style={{ color: "#556ee6" }}> {fromCurrType} </strong></span>;
                },
            },
            {
                Header: "Rate",
                accessor: "exchangeRate",
                filterable: false,
                disableFilters: true,
            },
            {
                Header: "To Currency",
                accessor: "toCurrValue",
                filterable: false,
                disableFilters: true,
                Cell: (cellProps) => {
                    const { toCurrValue, toCurrType } = cellProps.row.original;

                    return <span>{toCurrValue}   <strong style={{ color: "#556ee6" }}>{toCurrType}</strong></span>;
                },
            },
            {
                Header: "Pickup Date",
                accessor: "pickup",
                disableFilters: true,
                Cell: ({ value }) => {
                    return value ? value : "No Pickup Date";
                },
            },
            {
                Header: "Status",
                accessor: "status",
                filterable: false,
                disableFilters: true,
                Cell: ({ row }) => (
                    <Badge className={`font-size-11 badge-soft-${row.original.status.color}`}>
                        {row.original.status.label}
                    </Badge>
                ),
            },
            {
                Header: "View Details",
                accessor: "view",
                disableFilters: true,
                Cell: (cellProps) => (
                    <Button
                        type="button"
                        color="success"
                        className="btn-sm btn-rounded"
                        onClick={(e) => {
                            setEditMode(true);
                            e.preventDefault();
                            ViewTransactionDetails(cellProps.row.original);
                        }}
                    >
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                ),
            },
        ],
        [currencyFlags]
    );

    return (
        <React.Fragment>
            <ToastContainer />
            <div className="page-content">
                {modal1 && (
                    <ViewTransaction
                        optime={optime}
                        isOpen={modal1}
                        showAction={true}
                        branchID={branchID}
                        transactionType={transactionType}
                        chargesData={chargesData}
                        editMode={editMode}
                        branchData={branchData}
                        toggle={toggleViewModal}
                        commentData={commentData}
                        transactionID={transactionID}
                        sendTransactionDetails={sendTransactionDetails}
                    />
                )}

                {isLoading ? (
                    <Loader />
                ) : (
                    <Container fluid>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <div className="d-flex justify-content-between align-items-center mb-4">
                                            <h4 className="card-title mb-4">Manage Transactions</h4>
                                            <div className="ms-3">
                                                <FontAwesomeIcon
                                                    icon={faQuestionCircle}
                                                    onClick={handleHelpClick}
                                                    color="#556ee6"
                                                    id="helpIcon"
                                                    style={{ fontSize: '1.2em', cursor: "pointer" }}
                                                />
                                                <Tooltip
                                                    placement="top"
                                                    isOpen={tooltipOpen}
                                                    target="helpIcon"
                                                    toggle={() => setTooltipOpen(!tooltipOpen)}
                                                >
                                                    Help
                                                </Tooltip>
                                            </div>
                                        </div>

                                        {transactionData.length > 0 ? (
                                            <div className="table-container" style={{ padding: "1rem" }}>
                                                <TableContainer
                                                    columns={columns}
                                                    customPageSize={transactionData.length}
                                                    data={transactionData}
                                                />
                                            </div>
                                        ) : (
                                            <div className="text-muted d-flex justify-content-center align-items-center" style={{ minHeight: "100px" }}>
                                                <h3>No Records to Show</h3>
                                            </div>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                )}
            </div>
        </React.Fragment>
    );
};

export default ManageTransaction;
