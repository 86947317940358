import React from "react";
import { Link } from "react-router-dom";


//Toast
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
} from "reactstrap";

// import images
import profile from "../../../images/profile-img.png";
import withRouter from "../../components/Common/withRouter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const ResetSuccess = (props) => {

    document.title = "Email limk send | Crescent Exchange";


    return (
        <React.Fragment>
            <ToastContainer closeButton={false} limit={1} />
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark">
                    <i className="bx bx-home h2" />
                </Link>
            </div>
            <div className="account-pages  my-5 pt-sm-5">
                <Container className="mt-5">
                    <Row className="justify-content-center">
                        <Col md={5} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary-subtle">
                                    <Row>
                                        <Col xs={7}>
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary"> Success !</h5>
                                                <p>Link successfully sent.</p>
                                            </div>
                                        </Col>
                                        <Col xs={5} className="align-self-end">
                                            <img src={profile} alt="" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div>
                                        <Link to="/" >
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-circle bg-light border border-dark  border-2">
                                                    <FontAwesomeIcon
                                                        icon={faCheck}
                                                        style={{
                                                            height: "35px",
                                                            color: "green",
                                                        }}
                                                    />

                                                </span>
                                            </div>
                                        </Link>

                                    </div>

                                    <div className="text-center mb-3" >
                                        <h4>
                                            You can leave this page.
                                        </h4>

                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <p>
                                    Go back to {" "}
                                    <Link to="/login" className="font-weight-medium text-primary">
                                        <b>
                                            LOGIN
                                        </b>
                                    </Link>{" "}
                                </p>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(ResetSuccess);
