export const formatDateToUserTimeZone = (utcDateString) => {
    const date = new Date(
        utcDateString.endsWith("Z") ? utcDateString : utcDateString + "Z"
    );

    if (isNaN(date.getTime())) {
        throw new Error("Invalid date format");
    }

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
    };

    try {
        const formattedDate = date.toLocaleString("en-US", {
            timeZone: userTimeZone,
            ...options,
        });

        return formattedDate
            .replace(/(\d{2})\/(\d{2})\/(\d{4}),\s+/, "$2-$1-$3 ")
            .trim();
    } catch (error) {
        console.error("Error formatting date:", error);
    }
};