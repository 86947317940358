import React, { useRef, useState, useEffect } from "react";
import "@tensorflow/tfjs";
import "@tensorflow/tfjs-backend-webgl";
import "@mediapipe/face_mesh";
import { Modal, Row, Col } from 'reactstrap';
import Webcam from "react-webcam";
import { runDetector } from "./detector";
import { postApiData } from "../../helpers/axiosHelper";
import { toast } from "react-toastify";
import ReactSelect from 'react-select';
import Swal from "sweetalert2";
import {
    Button,
    Spinner,
} from "reactstrap";

const getInputResolution = () => {
    const width = Math.min(window.innerWidth * 0.8, 600);
    const height = (width / 4) * 3;
    return { width, height };
};

const FaceDetect = ({ getProfileImage, isOpen, toggle }) => {
    const canvasRef = useRef(null);
    const videoRef = useRef(null);
    const streamRef = useRef(null);

    const [inputResolution, setInputResolution] = useState(getInputResolution());
    const [deviceId, setDeviceId] = useState('');
    const [devices, setDevices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [faceTimeout, setFaceTimeout] = useState(null);

    useEffect(() => {
        const handleResize = () => {
            setInputResolution(getInputResolution());
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleVideoLoad = async (videoNode) => {
        setLoading(true);
        const video = videoNode.target;
        const canvas = canvasRef.current;
        if (video.readyState !== 4) return;

        video.width = inputResolution.width;
        video.height = inputResolution.height;

        const detect = await runDetector(video, canvas, noFaceDetect).then(() => {
            setLoading(false);
        });

        console.log(detect);
    };

    const noFaceDetect = () => {
        const timeout = setTimeout(() => {
            handleCaptureCancel();
            Swal.fire({
                title: "No face detected",
                text: "We couldn't detect a face in the camera feed. Please try again.",
                icon: "warning",
                confirmButtonText: "OK",
            });
        }, 10000);

        setFaceTimeout(timeout);
    };

    const handleCapture = async () => {
        const video = videoRef.current.getScreenshot();
        const capturedImageData = video;

        const videoStream = streamRef.current;
        if (videoStream) {
            const tracks = videoStream.getTracks();
            tracks.forEach((track) => track.stop());
        }

        toggle();

        const image = capturedImageData?.split(',')[1];
        const response = await postApiData('api/FaceRecognition/VerifyProfileImage', image);

        if (response.success) {
            toast.success("Profile Image verified Successfully", {
                position: "top-right",
                autoClose: 3000,
            });
            getProfileImage();
        } else {
            toast.error("Error", {
                position: "top-right",
                autoClose: 3000,
            });
        }
    };

    const handleDevices = (mediaDevices) => {
        const videoDevices = mediaDevices.filter(({ kind }) => kind === 'videoinput');
        setDevices(videoDevices);

        if (videoDevices.length === 0) {
            console.log(videoDevices);
            setTimeout(() => {
                Swal.fire({
                    title: "No Camera devices found",
                    text: "Please connect a camera device and try again.",
                    icon: "warning",
                    confirmButtonText: "OK",
                });
            }, 200);
            return;
        }

        if (videoDevices.length > 0) {
            setDeviceId(videoDevices[0].deviceId);
        }
    };

    useEffect(() => {
        navigator.mediaDevices.enumerateDevices().then(handleDevices);
    }, []);

    const checkCameraAvailability = async () => {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter((device) => device.kind === 'videoinput');

        if (videoDevices.length === 0) {
            Swal.fire({
                title: "No Camera Available",
                text: "Please connect a camera and try again.",
                icon: "warning",
                confirmButtonText: "OK",
            });
            return false;
        }

        setDevices(videoDevices);
        if (videoDevices.length > 0) {
            setDeviceId(videoDevices[0].deviceId);
        }

        return true;
    };

    const checkCameraPermissions = async () => {
        const permissions = await navigator.permissions.query({ name: 'camera' });

        if (permissions.state === 'denied') {
            Swal.fire({
                title: "Camera Permission Denied",
                text: "Please allow camera access in your browser settings.",
                icon: "warning",
                confirmButtonText: "OK",
            });
            return false;
        }

        return true;
    };

    const reconnectStream = () => {
        handleLiveCapture();
        Swal.fire({
            title: "Camera Stream Interrupted",
            text: "The camera stream was interrupted. Trying to reconnect...",
            icon: "info",
            confirmButtonText: "OK",
        });
    };

    useEffect(() => {
        if (isOpen) {
            checkCameraPermissions().then((hasPermission) => {
                if (hasPermission) {
                    checkCameraAvailability().then((isAvailable) => {
                        if (isAvailable) {
                            handleLiveCapture();
                        } else {
                            Swal.fire({
                                title: "No Camera Available",
                                text: "Please connect a camera or check your permissions.",
                                icon: "warning",
                                confirmButtonText: "OK",
                            });
                            toggle();
                        }
                    });
                } else {
                    Swal.fire({
                        title: "Camera Permission Denied",
                        text: "Please allow camera access in your browser settings and try again.",
                        icon: "warning",
                        confirmButtonText: "OK",
                    });
                    toggle();
                }
            });
        }

        return () => {
            handleCaptureCancel();
        };
    }, [isOpen]);

    const handleChange = (event) => {
        setDeviceId(event.deviceId);
    };

    const handleCaptureCancel = () => {
        const videoStream = streamRef.current;
        if (videoStream) {
            const tracks = videoStream.getTracks();
            tracks.forEach((track) => track.stop());
        }
        toggle();
        clearTimeout(faceTimeout);
    };

    const handleLiveCapture = () => {
        navigator.mediaDevices.getUserMedia({ video: { deviceId: deviceId ? { exact: deviceId } : undefined } })
            .then((stream) => {
                streamRef.current = stream;
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                }

                stream.getTracks().forEach(track => {
                    track.onended = () => {
                        reconnectStream();
                    };
                });
            })
            .catch((error) => {
                if (error.name === 'NotAllowedError') {
                    Swal.fire({
                        title: "Camera Access Denied",
                        text: "Please allow camera access in your browser settings and try again.",
                        icon: "warning",
                        confirmButtonText: "OK",
                    });
                } else if (error.name === 'NotFoundError') {
                    Swal.fire({
                        title: "No Camera Found",
                        text: "No camera was found on this device. Please connect a camera and try again.",
                        icon: "warning",
                        confirmButtonText: "OK",
                    });
                } else {
                    Swal.fire({
                        title: "Camera Error",
                        text: "An unexpected error occurred while accessing the camera. Please check your camera and try again.",
                        icon: "error",
                        confirmButtonText: "OK",
                    });
                }
                console.error("Error accessing the camera:", error);
                toggle();
            });
    };


    const videoConstraints = {
        facingMode: "user",
        deviceId: deviceId,
    };

    return (
        <Modal
            isOpen={isOpen}
            toggle={handleCaptureCancel}
            centered
            size="lg"
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    marginTop: "2px",
                    width: '100%',
                    height: `${inputResolution.height}px`,
                }}>
                <Webcam
                    width={inputResolution.width}
                    height={inputResolution.height}
                    videoConstraints={videoConstraints}
                    onLoadedData={handleVideoLoad}
                    ref={videoRef}
                    style={{
                        position: "absolute",
                        width: `${inputResolution.width}px`,
                        height: `${inputResolution.height}px`,
                    }}
                />

                <canvas
                    width={inputResolution.width}
                    height={inputResolution.height}
                    ref={canvasRef}
                    style={{
                        position: "absolute",
                        width: `${inputResolution.width}px`,
                        height: `${inputResolution.height}px`,
                    }}
                />

                {loading && (
                    <div style={{
                        position: "absolute",
                        width: `${inputResolution.width}px`,
                        height: `${inputResolution.height}px`,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}>
                        <Spinner color="primary" />
                        <p style={{ marginTop: '10px', fontSize: '16px' }}>Detecting face, please wait...</p>
                    </div>
                )}
            </div>

            <Row className="align-center mt-2 ms-3 me-3">
                <Col>
                    {devices.length > 0 && (
                        <ReactSelect
                            className="mb-3"
                            onChange={handleChange}
                            value={devices.find((device) => device.deviceId === deviceId) || null}
                            options={devices}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    backgroundColor: 'white',
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    backgroundColor: 'white',
                                }),
                            }}
                        />
                    )}
                </Col>
            </Row>

            <Row className="align-center mb-2 ms-3 me-3">
                <Col>
                    <Button
                        color="success"
                        onClick={handleCapture}
                        disabled={loading}
                        style={{
                            width: "100%"
                        }}>
                        Verify Image
                    </Button>
                </Col>
                <Col>
                    <Button
                        color="danger"
                        onClick={handleCaptureCancel}
                        style={{
                            width: "100%"
                        }}>
                        Cancel
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
};

export default FaceDetect;
