import AVEBeneficiary from "./AVEBeneficiary";
import Loader from "../../components/Common/Loader";
import { toast, ToastContainer } from "react-toastify";
import React, { useMemo, useState, useEffect } from "react";
import { Card, Button, CardBody, Container } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TableContainer from "../../components/Common/TableContainer";
import { deleteApiData, getApiData } from "../../helpers/axiosHelper";
import { faEye, faEdit, faCheck, faTrash, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "reactstrap";

const CustomerBeneficiary = () => {

    document.title = "Crescent Exchange | Beneficiary";

    const [modal1, setModal1] = useState(false);
    const [editMode, setEditMode] = useState(0);
    const [currency, setCurrency] = useState([]);
    const [loading, setLoading] = useState(false);
    const [custBenId, setCustBenId] = useState("");
    const [documentData, setDocumentData] = useState([]);
    const [currencyFlags, setCurrencyFlags] = useState({});
    const [beneficiaryData, setBeneficiaryData] = useState([]);
    const [sendBeneficiaryDetails, setSendBeneficiaryDetails] = useState(null);
    const [nationalData, setNationalData] = useState([]);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleAddModal = () => setModal1(!modal1);

    // Fetching currency flags
    const getAllCurrencies = async () => {
        try {
            const response = await getApiData('api/Currency/GetAllCurrencies');
            const flags = {};
            response.data.forEach(currency => {
                flags[currency.currencyName.trim()] = currency.currencyFlag;
            });
            setCurrencyFlags(flags);
            const mappedResponse = response.data.map((item, index) => ({
                index: index + 1,

                currencyId: item.currencyId,
                currencyName: item.currencyName,
                currencyCode: item.currencyCode,
            }));
            setCurrency(mappedResponse);

        } catch (error) {
            toast.error('Failed to load currency flags', {
                position: "top-right",
                autoClose: 3000,
            });
        }
    };

    const getAllNationlity = async () => {
        try {
            setLoading(true);
            const nationalResponse = await getApiData('api/Address/GetAllNationality');
            const national = await nationalResponse;

            const mappedNationalities = national.map(nation => ({
                value: nation.id.toString(),
                label: nation.nation,
                country: nation.country
            }));

            setNationalData(mappedNationalities);
            setLoading(false);
        } catch (error) {
            toast.error("Error occurred while fetching data.", {
                position: "top-right",
                autoClose: 3000,
            });
        }
    };

    const handleHelpClick = () => {
        window.open("https://sitapi.crescentexchange.com/help/customer/Beneficiary.pdf");
    };
    const isActive = {
        0: "inActive",
        1: "active",
    }

    const status = {
        0: "draft",
        1: "inComplete",
        2: "complete",
    }

    const accType = {
        1: "personal",
        2: "bussiness"
    }

    const fetchAllBeneficiary = async () => {
        try {
            setLoading(true);
            const response = await getApiData("api/CustomerBeneficiary/GetAllBeneficiaryByCustomer");

            const mappedResponse = response.data.map((item, index) => ({

                index: index + 1,

                custBenId: item.custBenId,
                customerId: item.customerId,

                beneficiaryName: item.beneficiaryName,
                email: item.email,
                mobile: item.mobile,
                dob: item.dob,
                currencyId: item.currencyId,
                currencyName: item.currencyName,
                nationalId: item.nationalId,


                bankName: item.bankName,
                isMyAccount: item.isMyAccount,
                accountType: accType[item.accountType],
                accountNumber: item.accountNumber,
                swift: item.swift,
                other: item.other,
                bankAddress: item.bankAddress,

                addressId: item.addressId,
                street: item.street,
                city: item.city,
                state: item.state,
                country: item.country,
                postalCode: item.postalCode,
                nationality: item.nationality,

                status: status[item.status],
                active: isActive[item.active],
            }));
            setBeneficiaryData(mappedResponse);
            setLoading(false);
        } catch (error) {
            console.log("Error occuried.", error);
        }
    };

    const getDocument = async (custBenId) => {
        const docResponse = await getApiData(`api/Customerbeneficiary/GetBeneficiaryDocByCustBenId?CustBenId=${custBenId}`);
        const document = await docResponse.data;
        setDocumentData(document);
    }

    const ViewBeneficiaryDetails = async (cellProps) => {
        const { custBenId } = cellProps;
        setCustBenId(custBenId);

        try {
            const benResponse = await getApiData(`api/Customerbeneficiary/GetBeneficiaryById?BeneficiaryId=${custBenId}`);
            const processedData = {
                ...benResponse.data,
                dob: benResponse.data.dob.split("T")[0],
            };
            setSendBeneficiaryDetails(processedData);

            getDocument(custBenId);

        } catch (error) {
            toast.error(error.message, {
                position: "top-right",
                autoClose: 3000,
            });
        }

        toggleAddModal();
    };

    const BenAct_Deact = async (cellProps, active) => {
        const { custBenId } = cellProps;
        setCustBenId(custBenId);
        try {
            const response = await deleteApiData(`api/CustomerBeneficiary/Delete?beneficiaryId=${custBenId}&active=${active}`);
            if (response.success === true) {
                toast.success(`Beneficiary ${active ? 'activated' : 'deactivated'} successfully`, {
                    position: "top-right",
                    autoClose: 3000,
                });
                fetchAllBeneficiary();
            } else {
                toast.error(`Beneficiary ${active ? 'activated' : 'deactivated'} unsuccessfully`, {
                    position: "top-right",
                    autoClose: 3000,
                });
            }
        } catch (error) {
            console.log("Error occuried", error);
        }
    };

    const columns = useMemo(
        () => [
            {
                Header: "Name",
                accessor: "beneficiaryName",
                filterable: false,
                disableFilters: true,
                Cell: cellProps => {
                    const { beneficiaryName, isMyAccount } = cellProps.row.original;
                    return (
                        <div>
                            {beneficiaryName}
                            {isMyAccount === true && <span className="badge bg-success ms-2">Own</span>}
                        </div>
                    );
                },
            },
            {
                Header: "Status",
                accessor: "status",
                filterable: false,
                disableFilters: true,
                Cell: cellProps => {
                    const status = cellProps.value || "";
                    let badgeColor = "";

                    switch (status) {
                        case "draft":
                            badgeColor = "bg-primary";
                            break;
                        case "inComplete":
                            badgeColor = "bg-danger";
                            break;
                        case "complete":
                            badgeColor = "bg-success";
                            break;
                        default:
                            badgeColor = "bg-secondary";
                            break;
                    }

                    return (
                        <div>
                            <span className={`badge ${badgeColor}`}>
                                {status.charAt(0).toUpperCase() + status.slice(1)}
                            </span>
                        </div>
                    );
                },
            },
            {
                Header: "Currency",
                accessor: "currencyName",
                filterable: false,
                disableFilters: true,
                Cell: (cellProps) => {
                    const currencyName = cellProps.value;
                    const flagImage = currencyFlags[currencyName];

                    return (
                        <div className="d-flex align-items-center">
                            {flagImage && (
                                <img
                                    src={`data:image/png;base64,${flagImage}`}
                                    alt={currencyName}
                                    style={{ width: 20, height: 20, marginRight: 5 }}
                                />
                            )}
                            <span>{currencyName}</span>
                        </div>
                    );
                },
            },
            {
                Header: "Action",
                disableFilters: true,
                accessor: "action",
                Cell: (cellProps) => {
                    const { active } = cellProps.row.original;

                    return (
                        <>
                            {active === "inActive" ? (
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-sm btn-rounded me-2 ms-3"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        BenAct_Deact(cellProps.row.original, true);
                                    }}
                                >
                                    Activate <FontAwesomeIcon className="ms-2" icon={faCheck} />
                                </Button>
                            ) : (
                                <>
                                    <Button
                                        type="button"
                                        color="primary"
                                        className="btn-sm btn-rounded me-2 ms-2"
                                        onClick={(e) => {
                                            setEditMode(1);
                                            e.preventDefault();
                                            ViewBeneficiaryDetails(cellProps.row.original);
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faEye} />
                                    </Button>
                                    <Button
                                        type="button"
                                        color="success"
                                        className="btn-sm btn-rounded me-2"
                                        onClick={(e) => {
                                            setEditMode(2);
                                            e.preventDefault();
                                            ViewBeneficiaryDetails(cellProps.row.original);
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faEdit} />
                                    </Button>
                                    <Button
                                        type="button"
                                        color="danger"
                                        className="btn-sm btn-rounded"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            BenAct_Deact(cellProps.row.original, false);
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </>
                            )}
                        </>
                    );
                },
            },
        ],
        [currencyFlags]
    );

    useEffect(() => {
        fetchAllBeneficiary();
        getAllCurrencies();
        getAllNationlity();
    }, []);


    return (
        <React.Fragment>
            <ToastContainer />
            <div className="page-content">
                <Container>
                    <AVEBeneficiary
                        isOpen={modal1}
                        editMode={editMode}
                        currencies={currency}
                        toggle={toggleAddModal}
                        documentData={documentData}
                        nationalData={nationalData}
                        sendBeneficiaryDetails={sendBeneficiaryDetails}
                        fetchAllBeneficiary={fetchAllBeneficiary}
                        getDocument={getDocument}

                    />
                    {loading ? (
                        <Loader />
                    ) : (
                        <Card>
                            <CardBody>
                                <div className="d-flex justify-content-between align-items-center mb-4">
                                    <button
                                        className="btn btn-primary text-end"
                                        onClick={() => {
                                            setSendBeneficiaryDetails(null);
                                            setDocumentData([]);
                                            setEditMode(3);
                                            toggleAddModal();
                                        }}
                                    >
                                        + Add Beneficiary
                                        </button>
                                        {/* Help Icon with Tooltip */}
                                        <div className="ms-3">
                                            <FontAwesomeIcon
                                                icon={faQuestionCircle}
                                                onClick={handleHelpClick}
                                                color="#556ee6"
                                                id="helpIcon" 
                                                style={{ fontSize: '1.2em', cursor: "pointer" }}
                                            />
                                            <Tooltip
                                                placement="top"
                                                isOpen={tooltipOpen} 
                                                target="helpIcon" 
                                                toggle={() => setTooltipOpen(!tooltipOpen)} 
                                            >
                                                 Help
                                            </Tooltip>
                                        </div>
                                </div>
                                {beneficiaryData.length > 0 ? (
                                    <TableContainer
                                        data={beneficiaryData}
                                        results={false}

                                        columns={columns}
                                        customPageSize={10}
                                        isAddOptions={false}
                                        isPageSelect={false}
                                        isGlobalFilter={false}
                                    />
                                ) : (
                                    <div className="text-muted d-flex justify-content-center align-items-center" style={{ minHeight: "100px" }}>
                                        <h3>No Beneficiaries to Show</h3>
                                    </div>
                                )}
                            </CardBody>
                        </Card>
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CustomerBeneficiary;
