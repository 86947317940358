
// Opening Page
import Dashboard from "../pages/Dashboard/index";

// Authentication Folder:-
import Home from "../pages/Authentication/Home";
import Profile from "../pages/UserProfile/Profile";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ResetSuccess from "../pages/Authentication/ResetSuccess";
import ResetPassword from "../pages/Authentication/ResetPassword";
import ChangePassword from "../pages/Authentication/ChangePassword";
import EmailVerification from "../pages/Authentication/EmailVerification";

// Customer Beneficiary:-
import Beneiciary from "../pages/Beneficiary/CustomerBeneficiary";

// Transaction Folder:-
//import LatestTransaction from "../pages/Transaction/LatestTranaction";
import ManageTransaction from "../pages/Transaction/ManageTransaction";
import AddTransaction from "../pages/Dashboard/AddTransaction";
import TransactionHistory from "../pages/Transaction/TransactionHistory";

const authProtectedRoutes = [
    { path: "/profile", component: <Profile /> },
    { path: "/dashboard", component: <Dashboard /> },
    { path: "/changepassword", component: <ChangePassword /> },
    { path: "/CustomerBeneficiary", component: <Beneiciary /> },
    //{ path: "/latesttransaction", component: <LatestTransaction /> },
    { path: '/addtransaction', component: <AddTransaction /> },
    { path: "/managetransaction", component: <ManageTransaction /> },
    { path: "/transactionhistory", component: <TransactionHistory /> },
];

const publicRoutes = [
    {
        path: "/",
        exact: true,
        component: <Home />,
    },
   { path: "/home", component: <Home /> },
    { path: "/login", component: <Login /> },
    { path: "/logout", component: <Logout /> },
    { path: "/register", component: <Register /> },
    { path: "/forgot-password", component: <ForgetPwd /> },
    { path: "/reset-success", component: <ResetSuccess /> },
    { path: "/reset-password", component: <ResetPassword /> },
    { path: "/emailverification/:uid", component: <EmailVerification />,},
];

export { publicRoutes, authProtectedRoutes };
