import React, { useEffect, useMemo, useState } from "react";
import {
    Badge,
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Input,
    Label,
    Modal,
    Row,
} from "reactstrap";
import {
    Date,
    Branch,
    CurrencyType,
    CurrencyValue,
} from "./TranactionCol";
import { useFormik } from "formik";
import ReactSelect from "react-select";
import ViewTransaction from "./ViewTransaction";
import Loader from "../../components/Common/Loader";
import { ToastContainer, toast } from "react-toastify";
import { faEye, faDownload, faDollar, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { SelectStyle } from "../../common/data/SelectStyle";
import withRouter from "../../components/Common/withRouter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getApiData, postApiData } from "../../helpers/axiosHelper";
import TableContainer from "../../components/Common/TableContainer";
import RequiredAsterisk from "../../components/Common/RequiredAsterisk";
import { mapStatus, statusOptions } from "../../common/data/StatusLabels";
import { useBranch, useCurrency } from "../../common/data/commonFunction";
import ReceiptModal from "../Transaction/Receipt";
import { formatDateToUserTimeZone } from "../../common/data/FormateDateTime";
import { Tooltip } from "reactstrap";

const TransactionHistory = (props) => {
    document.title = "Transaction History | Crescent Exchange";
    const [error, setError] = useState();
    const [optime, setOptime] = useState([]);
    const { branch, fetchBranch } = useBranch();
    const [editMode, setEditMode] = useState(false);
    const [branchData, setBranchData] = useState();
    const [modal1, setModal1] = useState(false);
    const [columnName, setColumnName] = useState();
    const { currency, getCurrency } = useCurrency();
    const [filterType, setFilterType] = useState('');
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(null);
    const [commentData, setCommentData] = useState([]);
    const [branchID, setBranchID] = useState("");
    const [filterValue, setFilterValue] = useState(null);
    const [totalRecord, setTotalRecords] = useState(null);
    const [transactionID, setTransactionID] = useState("");
    const [modal_filter, setmodal_filter] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [transactionHistoryData, setTransactionHistoryData] = useState([]);
    //const [selectedPageCountOption, setSelectedPageCountOption] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [receiptData, setReceiptData] = useState({});
    const [triggerDownload, setTriggerDownload] = useState(false);
    const [transactionData, setTransactionData] = useState([]);
    const [sendTransactionDetails, setSendTransactiondetails] = useState(null);
    const [currencyFlags, setCurrencyFlags] = useState({});
    const [chargesData, setChargesData] = useState(null);
    const [transactionType, setTransactionType] = useState(null);

    const fetchCurrencyFlags = async () => {
        try {
            const response = await getApiData('api/Currency/GetAllCurrencies');
            const flags = {};
            response.data.forEach(currency => {
                flags[currency.currencyCode] = currency.currencyFlag;
            });
            setCurrencyFlags(flags);
        } catch (error) {
            console.error('Failed to load currency flags', error);
        }
    };


    const handleHelpClick = () => {
        window.open('https://sitapi.crescentexchange.com/help/customer/Transaction Histroy.pdf');
    };
    useEffect(() => {
        fetchCurrencyFlags();
    }, []);



    const toggleViewModal = () => setModal1(!modal1);

    const handleShow = (data, download) => {
        setReceiptData(data);
        setTriggerDownload(download);
        setShowModal(true);
    };


    const TransactionId = (cell) => {
        return cell.value ? cell.value : '';
    };

    const handleClose = () => {
        setShowModal(false);
        setTriggerDownload(false);
    };

    const Days = {
        0: "AlL Days",
        1: "Sunday",
        2: "Monday",
        3: "Tuesday",
        4: "Wednesday",
        5: "Thursday",
        6: "Friday",
        7: "Saturday",

    };

    const transactionTypeMap = {
        2: { label: "Buy", color: "success" },
        1: { label: "Sell", color: "danger" },
        3: { label: "Transfer", color: "primary" },
    };

    const convertTo12HourFormat = (time24) => {
        const [hours, minutes] = time24.split(":");
        const meridiem = hours >= 12 ? "PM" : "AM";
        const hours12 = hours % 12 || 12;
        return `${hours12}:${minutes} ${meridiem}`;
    };

    const ViewTransactionDetails = (cellProps) => {
        const { transactionId, branchId } = cellProps;

        setTransactionID(transactionId);
        setBranchID(branchId);

        const getTransactionDetails = async () => {
            try {
                // Fetch transaction details
                const transactionResponse = await getApiData(
                    `api/Transaction/GetTransaction?TransactionID=${transactionId}`
                );

                // Fetch branch details
                const branchResponse = await getApiData(
                    `api/BranchMaster/GetBranchWithOperation?branchId=${branchId}`
                );

                // Map operational times to 12-hour format
                const operationalTimes = branchResponse.data.operationalTime.map((item) => ({
                    day: Days[item.day],
                    startTime: convertTo12HourFormat(item.startTime),
                    endTime: convertTo12HourFormat(item.endTime),
                }));

                setBranchData(branchResponse.data.branch);
                setOptime(operationalTimes);
                setSendTransactiondetails(transactionResponse);
                setCommentData(transactionResponse.comments);
                setTransactionType(transactionResponse.transactionType);

                // Fetch transaction charges
                const { transactionType, fromRateHistoryId, toRateHistoryId } = transactionResponse;

                await fetchTransactionCharges(transactionType, fromRateHistoryId, toRateHistoryId);
            } catch (error) {
                console.error("Error occurred:", error);
            }
        };

        getTransactionDetails();
        toggleViewModal();
    };
    const fetchTransactionCharges = async (transactionType, fromRateHistoryId, toRateHistoryId) => {
        try {
            const rateHistoryId = transactionType === 2 ? toRateHistoryId : fromRateHistoryId;

            const chargesResponse = await getApiData(
                `api/RateMaster/GetTransactionCharges?rateHistoryId=${rateHistoryId}`
            );

            if (chargesResponse.success) {
                setChargesData(chargesResponse);
            } else {
                console.error("Failed to fetch charges:", chargesResponse.message);
            }
        } catch (error) {
            console.error("Error fetching transaction charges:", error);
        }
    };

    const [state, setState] = useState({
        pageSize: 10,
        status: null,
        currentPage: 1,
        currency: null,
        branchName: null,
        submitting: false,
        transactionDate: null,
        clearFiltersClicked: false,
    })
    const clearFilters = () => {
        return (
            <Button
                type="button"
                color="danger"
                data-toggle="modal"
                data-target="#myModal"
                onClick={resetFormValues}
            >
                Clear Filters
            </Button>
        );
    };
    function tog_addFilters() {
        resetForm();
        setmodal_filter(prevState => !prevState);
    }
    const resetFormValues = () => {
        setFilterType('')
        setColumnName(null)
        validation.resetForm();
        setSelectedOption(null);
        //setSelectedPageCountOption(null);
        setState({
            pageSize: 10,
            status: null,
            currentPage: 1,
            currency: null,
            branchName: null,
            submitting: false,
            transactionDate: null,
        })
        setFilterValue(null)
        setState((prevState) => ({ ...prevState, clearFiltersClicked: true }))
        validation.handleSubmit()
    }

    const resetForm = () => {
        setFilterType('')
        setColumnName(null)
        validation.resetForm();
        setSelectedOption(null);
        //setSelectedPageCountOption(null);
        setState({
            pageSize: 10,
            status: null,
            currentPage: 1,
            currency: null,
            branchName: null,
            submitting: false,
            transactionDate: null,
        })
        setFilterValue(null)
        setState((prevState) => ({ ...prevState, clearFiltersClicked: true }))
        // validation.handleSubmit()
    }

    const columnList = [
        { label: "Status", value: "status" },
        { label: "Branch", value: "branchName" },
        { label: "Currency", value: "fromCurrType" },
        //{ label: "Transaction Date", value: "transactionDate" },
    ]

    const calculateConversionRate = (toCurrValue, fromCurrValue) => {
        const from = parseFloat(fromCurrValue);
        const to = parseFloat(toCurrValue);
        return (from / to).toFixed(3);
    };

    const mapResponse = (data) => {
        return data.map((item, index) => ({
            transactionId: item.transactionId,
            branchId: item.fromBranchId,
            index: index + 1,
            name: item.name,
            fromRateHistoryId: item.fromRateHistoryId,
            toRateHistoryId: item.toRateHistoryId,
            branch: item.branchName,
            transactionType: item.transactionType,
            iDandBranch: item.transactionId + "-" + item.branchName,
            date: formatDateToUserTimeZone(item.transactionDate),
            fromCurrType: item.fromCurrType,
            toCurrType: item.toCurrType,
            fromCurrValue: item.fromCurrValue,
            toCurrValue: item.toCurrValue,
            status: mapStatus(item.status),
            exchangeRate: item.exchangeRate,
            pickup: item.pickup ? formatDateToUserTimeZone(item.pickup) : 'No Pickup Date',
            transactingCurrency: item.toCurrType,
        }));

    }


    const getTransactionHistory = async () => {
        try {
            
            setIsLoading(true); 
            const response = await postApiData(
                `api/Transaction/GetTransactionByCustomerPagination`,
                JSON.stringify({ pagenumber: 1, pagecount: 10 })
            );

            if (response?.success === true) {
                if (response.model.length === 0) {
                    setTransactionHistoryData([]);
                } else {
                    const mappedData = mapResponse(response.model);
                    setTransactionData(mappedData);
                    setTransactionHistoryData(mappedData);
                    setTotalPages(response.totalPage);
                    setTotalRecords(response.totalRecord);
                }
            } else {
                setTransactionHistoryData([]); 
            }
        } catch (error) {
            console.error('Failed to load transactions', error);
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        getTransactionHistory();
    }, []);

    useEffect(() => {
        fetchBranch()
        getCurrency()
    }, []);

    const columns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "transactionId",
                filterable: false,
                disableFilters: true,
                Cell: cellProps => {
                    return <TransactionId {...cellProps} />;
                },
            },
            {
                Header: "Branch",
                accessor: "branch",
                filterable: false,
                disableFilters: true,
                Cell: cellProps => {
                    return <Branch {...cellProps} />;
                },
            },
            {
                Header: "Currency",
                accessor: "transactingCurrency",
                disableFilters: true,
                Cell: (cellProps) => {
                    const { transactionType, fromCurrType, toCurrType } = cellProps.row.original;
                    const transactingCurrency = transactionType === 1 ? fromCurrType : toCurrType;
                    const { label, color } = transactionTypeMap[transactionType] || {};
                    const flagIcon = currencyFlags[transactingCurrency];

                    // Special rendering for transactionType === 3
                    if (transactionType === 3) {
                        const fromFlagIcon = currencyFlags[fromCurrType];
                        const toFlagIcon = currencyFlags[toCurrType];

                        return (
                            <div>

                                {fromFlagIcon ? (
                                    <img src={`data:image/png;base64,${fromFlagIcon}`} alt="" style={{ width: '20px', marginRight: '5px' }} />
                                ) : (
                                    <FontAwesomeIcon icon={faDollar} />
                                )}
                                <span>{fromCurrType}</span>
                                <Badge className={`font-size-11 badge-soft-${color}`}>
                                    {label}
                                </Badge>
                                {toFlagIcon ? (
                                    <img src={`data:image/png;base64,${toFlagIcon}`} alt="" style={{ width: '20px', marginLeft: '5px' }} />
                                ) : (
                                    <FontAwesomeIcon icon={faDollar} />
                                )}
                                <span>{toCurrType}</span>
                            </div>
                        );
                    }

                    // Default rendering
                    return (
                        <div>
                            <Badge className={`font-size-11 badge-soft-${color}`}>
                                {label}
                            </Badge>
                            {flagIcon ? (
                                <img src={`data:image/png;base64,${flagIcon}`} alt="" style={{ width: '20px', marginLeft: '5px' }} />
                            ) : (
                                <FontAwesomeIcon icon={faDollar} />
                            )}
                            <span>{transactingCurrency}</span>
                        </div>
                    );
                },
            },

            {
                Header: "From Currency",
                accessor: "toCurrValue",
                filterable: false,
                disableFilters: true,
                Cell: (cellProps) => {
                    const { transactionType, fromCurrValue, toCurrValue, fromCurrType } = cellProps.row.original;
                    const displayValue = transactionType === 1 ? fromCurrValue : toCurrValue;

                    return <span>{displayValue}  <strong style={{ color: "#556ee6" }}> {fromCurrType} </strong></span>;
                },
            },
            {
                Header: "Rate",
                accessor: "exchangeRate",
                disableFilters: true,
            },
            {
                Header: "To Currency",
                accessor: "fromCurrValue",
                filterable: false,
                disableFilters: true,
                Cell: (cellProps) => {
                    const { transactionType, fromCurrValue, toCurrValue, toCurrType } = cellProps.row.original;
                    const displayValue = transactionType === 1 ? toCurrValue : fromCurrValue;

                    return <span>{displayValue}   <strong style={{ color: "#556ee6" }}>{toCurrType}</strong></span>;
                },
            },
            {
                Header: "Booked Date",
                accessor: "date",
                disableFilters: true,
            },
            {
                Header: "Pickup Date",
                accessor: "pickup",
                disableFilters: true,
                Cell: ({ value }) => {
                    return value ? value : "No Pickup Date";
                },
            },

            {
                Header: "Status",
                accessor: "status",
                disableFilters: true,
                Cell: ({ row: { original: { status } } }) => (
                    <Badge className={`font-size-11 badge-soft-${status.color}`}>
                        {status.label}
                    </Badge>
                ),
            },

            {
                Header: "View Details",
                disableFilters: true,
                Cell: (cellProps) => {
                    const isDownloadable = cellProps.row.original.status === mapStatus(13);
                    const { transactionId, date, name, fromCurrValue, fromCurrType, toCurrType, toCurrValue, branch, fromRateHistoryId, toRateHistoryId, transactionType, exchangeRate } = cellProps.row.original;

                    const receiptData = {
                        transactionId, date, name, fromCurrValue, fromCurrType, toCurrType, toCurrValue, branch, toRateHistoryId, fromRateHistoryId, transactionType, exchangeRate

                    };
                    return (
                        <div className="d-flex align-items-center">
                            <Button
                                type="button"
                                color="primary"
                                className="btn-sm btn-rounded ms-4"
                                onClick={(e) => {
                                    setEditMode(true);
                                    e.preventDefault();
                                    ViewTransactionDetails(cellProps.row.original)
                                }}
                            >
                                <FontAwesomeIcon icon={faEye} />
                            </Button>
                            {isDownloadable && (
                                <Button
                                    type="button"
                                    style={{ backgroundColor: "#34c38f", border: 'none' }}
                                    className="btn-sm btn-rounded me-2 ms-2"
                                    onClick={() => handleShow(receiptData, true)}
                                >
                                    <FontAwesomeIcon icon={faDownload} />
                                </Button>

                            )}
                        </div>
                    );
                }
            }
        ],
        [currencyFlags]
    );

    const handleChange = (pageSize, currentPage) => {
        setState((prevState) => ({ ...prevState, currentPage: currentPage, pageSize: pageSize }))
        validation.setValues((values) => ({
            ...values,
            pagecount: pageSize,
            pagenumber: currentPage
        }))
        validation.handleSubmit()
    }

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            pagenumber: state.currentPage,
            pagecount: state.pageSize,
        },

        onSubmit: async (values) => {
            setState((prevState) => ({ ...prevState, submitting: true }))
            if (modal_filter) {
                values.pagenumber = 1
                values.pagecount = 10
                setState((prevState) => ({ ...prevState, currentPage: 1, pageSize: 10 }))
            }
            let combinedValues = { ...values };
            if (columnName) {
                if (filterValue === null) {
                    setError("Please Enter the Value");
                    return
                }
                else if (columnName && filterType === 'Customer Name') {
                    combinedValues = {
                        ...combinedValues,
                        filterColumns: [
                            {
                                columnName: columnName,
                                filterValue: state.customerName !== '' && state.customerName,
                            },
                        ],
                    };
                }
                else {
                    combinedValues = {
                        ...combinedValues,
                        filterColumns: [
                            {
                                columnName: columnName,
                                filterValue:
                                    ((state.branchName !== null) && state.branchName?.label) ||
                                    ((state.currency !== null) && state.currency?.label) ||
                                    ((state.status !== null) && `${state.status?.value}`) ||
                                    ((state.transactionDate !== null) && `${state.transactionDate}`),
                            },
                        ],
                    };
                }
            }
            const response = await postApiData(`api/Transaction/GetTransactionByCustomerPagination`, JSON.stringify(combinedValues))
            if (response.success === true) {
                setTransactionHistoryData(mapResponse(response.model))
                setTotalPages(response.totalPage)
                setTotalRecords(response.totalRecord)
                if (modal_filter) {
                    tog_addFilters()
                }
                setState((prevState) => ({ ...prevState, submitting: false }))
            }
            else if (response.success === false) {
                toast.error(`${response.message}`, {
                    position: 'top-right',
                    autoClose: 3000,
                    closeButton: false,
                    onClose: () => {
                        setState((prevState) => ({ ...prevState, submitting: false }))
                    }
                })
            }
        },
    });

    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            status: null,
            currency: null,
            branchName: null,
            transactionDate: null,
        }))
    }, [filterType])

    useEffect(() => {
        console.log(validation.values)
        console.log(validation.errors)
    }, [validation.values, validation.errors])

    return (
        <React.Fragment>
            <ToastContainer closeButton={false} limit={1} />
            {showModal && (
            <ReceiptModal
                show={showModal}
                handleClose={handleClose}
                receiptData={receiptData}
                triggerDownload={triggerDownload}
            />
            )}
            <ViewTransaction
                optime={optime}
                isOpen={modal1}
                showAction={true}
                branchID={branchID}
                transactionType={transactionType}
                chargesData={chargesData}
                editMode={editMode}
                branchData={branchData}
                toggle={toggleViewModal}
                commentData={commentData}
                transactionID={transactionID}
                sendTransactionDetails={sendTransactionDetails}
            />

            <div className="page-content">
                <Container fluid>
                    <Card>
                        <CardBody>
                            <div className="d-flex justify-content-between align-items-center mb-4">
                                <h4 className="card-title mb-4">Transaction History</h4>
                                <div className="ms-3">
                                    <FontAwesomeIcon
                                        icon={faQuestionCircle}
                                        onClick={handleHelpClick}
                                        color="#556ee6"
                                        id="helpIcon"
                                        style={{ fontSize: '1.2em', cursor: "pointer" }}
                                    />
                                    <Tooltip
                                        placement="top"
                                        isOpen={tooltipOpen}
                                        target="helpIcon"
                                        toggle={() => setTooltipOpen(!tooltipOpen)}
                                    >
                                        Help
                                    </Tooltip>
                                </div>
                            </div>
                          
                            <Modal
                                size="md"
                                isOpen={modal_filter}
                                toggle={() => {
                                    tog_addFilters();
                                }}
                            >
                                <div className="modal-header">
                                    <h5
                                        className="modal-title mt-0"
                                        id="mySmallModalLabel"
                                    >
                                        Select Filters
                                    </h5>
                                    <button
                                        onClick={() => {
                                            setmodal_filter(false);
                                            validation.resetForm();
                                            setFilterType('')
                                            setError(null)
                                        }}
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <Row>
                                        <Col lg="12">
                                            <div className="mb-2">
                                                <Col lg="12">
                                                    <Label for="filterBy">Filter By</Label>
                                                </Col>
                                                <ReactSelect
                                                    options={columnList.map(column => ({ value: column.value, label: column.label }))}
                                                    value={selectedOption}
                                                    styles={SelectStyle}
                                                    onChange={(selected) => {
                                                        setSelectedOption(selected);
                                                        setFilterType(selected.label);
                                                        setColumnName(selected.value)
                                                    }}
                                                    placeholder="Select Filter"
                                                />
                                            </div>
                                        </Col>
                                        {filterType === 'Transaction Date' ? (
                                            <Col lg="12">
                                                <div className="mb-3">
                                                    <Label for="filterValue">Select {filterType}</Label>
                                                    <Input
                                                        type="date"
                                                        className="form-control"
                                                        id="filterValue"
                                                        placeholder={`Enter ${filterType}`}
                                                        onChange={(e) => {
                                                            setState((prevState) => ({ ...prevState, transactionDate: e.target.value }))
                                                            setFilterValue("e")
                                                        }}
                                                        value={state.transactionDate}
                                                    />
                                                </div>
                                            </Col>
                                        ) : filterType === 'Customer Name' ? (
                                            (
                                                <Col lg="12">
                                                    <div className="mb-3">
                                                        <Label for="filterValue">Enter {filterType}</Label>
                                                        <RequiredAsterisk />
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="filterValue"
                                                            placeholder={`Enter ${filterType}`}
                                                            onChange={(e) => {
                                                                const inputValue = e.target.value;
                                                                setState((prevState) => ({ ...prevState, customerName: inputValue }))
                                                                setError(inputValue === "" ? "Please Enter the Value" : null);
                                                                setFilterValue("e")
                                                            }}
                                                            value={state.customerName}
                                                            invalid={error ? true : false}
                                                        />
                                                        {
                                                            error ? (
                                                                <span className="text-danger" style={{ "font-size": "80%" }}>{error}</span>
                                                            ) : null
                                                        }
                                                    </div>
                                                </Col>
                                            )
                                        ) :
                                            filterType === 'Branch' ? (
                                                <Col lg="12">
                                                    <div className="mb-3">
                                                        <Label for="filterValue">{`Select ${filterType}`}</Label>
                                                        <RequiredAsterisk />
                                                        <ReactSelect
                                                            options={branch}
                                                            value={state.branchName}
                                                            styles={SelectStyle}
                                                            onChange={(e) => {
                                                                setState((prevState) => ({ ...prevState, branchName: e }))
                                                                setFilterValue("e")
                                                            }}
                                                            placeholder="Select Branch..."
                                                        />
                                                    </div>
                                                </Col>
                                            ) :
                                                filterType === 'Currency' ? (
                                                    <Col lg="12">
                                                        <div className="mb-3">
                                                            <Label for="filterValue">{`Select ${filterType}`}</Label>
                                                            <RequiredAsterisk />
                                                            <ReactSelect
                                                                options={currency}
                                                                value={state.currency}
                                                                styles={SelectStyle}
                                                                onChange={(e) => {
                                                                    setState((prevState) => ({ ...prevState, currency: e }))
                                                                    setFilterValue("e")
                                                                }}
                                                                placeholder="Select Currency..."
                                                            />
                                                        </div>
                                                    </Col>
                                                ) : filterType === 'Status' ? (
                                                    <Col lg="12">
                                                        <div className="mb-3">
                                                            <Label for="filterValue">{`Select ${filterType}`}</Label>
                                                            <RequiredAsterisk />
                                                            <ReactSelect
                                                                options={statusOptions}
                                                                value={state.status}
                                                                styles={SelectStyle}
                                                                onChange={(e) => {
                                                                    setState((prevState) => ({ ...prevState, status: e }))
                                                                    setFilterValue("e")
                                                                }}
                                                                placeholder="Select Status..."
                                                            />
                                                        </div>
                                                    </Col>
                                                ) : null}
                                    </Row>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={validation.handleSubmit}
                                        disabled={state.submitting}
                                    >
                                        Apply Filters
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-light"
                                        onClick={resetForm}
                                        disabled={state.submitting}
                                    >
                                        Clear
                                    </button>
                                </div>
                            </Modal>
                            <div className="mb-2 d-flex justify-content-start gap-1 flex-wrap">
                                <Button
                                    type="button"
                                    onClick={tog_addFilters}
                                    color="primary"
                                    data-toggle="modal"
                                    data-target="#myModal"
                                >
                                    Add Filters
                                </Button>
                                {clearFilters()}
                            </div>
                            {isLoading ? (
                                <Loader />
                            ) : transactionHistoryData.length > 0 ? (
                                <TableContainer
                                    columns={columns}
                                    data={transactionHistoryData}
                                    customPageSize={10}
                                    onPageChange={handleChange}
                                    totalPage={totalPages}
                                    totalRecord={totalRecord}
                                    currentPage={state.currentPage}
                                />
                            ) : (
                                <div className="text-muted d-flex justify-content-center align-items-center" style={{ minHeight: "100px" }}>
                                    <h3>No Records to Show</h3>
                                </div>
                            )}
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default React.memo(withRouter(TransactionHistory));