import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
import { FaDownload } from "react-icons/fa";
import { Row, Col, Container, Card } from 'react-bootstrap';
import logo from "../../../images/logo-dark.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faEquals, faX , faDivide } from '@fortawesome/free-solid-svg-icons';

import Footer from '../../components/SuperAdminLayout/Footer';
import { getApiData } from "../../helpers/axiosHelper";

const ReceiptModal = ({ show, handleClose, receiptData }) => {
   
    const [chargesData, setChargesData] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (receiptData) {
            fetchCharges();
        }
    }, [receiptData]); 

    const fetchCharges = async () => {
        setLoading(true);
        const rateHistoryId = receiptData.transactionType === 2
            ? receiptData.toRateHistoryId
            : receiptData.fromRateHistoryId;

        try {
            const response = await getApiData(`api/RateMaster/GetTransactionCharges?rateHistoryId=${rateHistoryId}`);
          
            
            if (response.success) {
                
                setChargesData(response.data);
            } else {
                console.error('Failed to fetch charges:', response.message);
            }
        } catch (error) {
            console.error('Error fetching charges:', error);
        } finally {
            setLoading(false);
        }
    };
    const generatePDF = async () => {
        const modalContent = document.getElementById('modal-print-content');
        if (!modalContent) return;

        const pdfWidth = 210; // A4 width in mm
        const pdfHeight = 297; // A4 height in mm
        const margin = 10; // Margins in mm

        try {
            const canvas = await html2canvas(modalContent, {
                scale: 2, // Increases quality
                useCORS: true,
            });

            const imgData = canvas.toDataURL('image/png');
            const imgWidth = pdfWidth - margin * 2; // Account for margins
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            const doc = new jsPDF('p', 'mm', 'a4');
            let y = margin;

            if (imgHeight > pdfHeight - margin * 2) {
                // If content exceeds one page, split into multiple pages
                const pageHeight = pdfHeight - margin * 2;
                let remainingHeight = imgHeight;

                while (remainingHeight > 0) {
                    doc.addImage(imgData, 'PNG', margin, y, imgWidth, pageHeight);
                    remainingHeight -= pageHeight;
                    if (remainingHeight > 0) doc.addPage();
                    y = margin; // Reset y for the next page
                }
            } else {
                // Single-page content
                doc.addImage(imgData, 'PNG', margin, y, imgWidth, imgHeight);
            }

            doc.save('receipt.pdf');
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };

    const handlePrint = () => {
        const modalContent = document.getElementById('modal-print-content');
        if (!modalContent) return;

        const printWindow = window.open('', '_blank', 'width=800,height=600');
        const modalStyles = Array.from(document.styleSheets)
            .map((styleSheet) => {
                try {
                    return Array.from(styleSheet.cssRules)
                        .map((rule) => rule.cssText)
                        .join('\n');
                } catch {
                    return '';
                }
            })
            .join('\n');

        printWindow.document.write(`
        <html>
            <head>
                <title>Transaction Receipt</title>
                <style>
                    ${modalStyles}
                    body {
                        font-family: Arial, sans-serif;
                        padding: 20px;
                    }
                    .table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    .table th, .table td {
                        border: 1px solid #ddd;
                        padding: 8px;
                    }
                    .text-center {
                        text-align: center;
                    }
                </style>
            </head>
            <body>
                ${modalContent.outerHTML}
            </body>
        </html>
    `);

        printWindow.document.close();
        printWindow.focus();

        printWindow.onload = () => {
            printWindow.print();
            printWindow.close();
        };
    };

 

    const calculateTotal = () => {
        if (!chargesData || !receiptData) return { charges: 0, total: 0 };

        const { agentCharges } = chargesData;
        const { fromCurrValue, exchangeRate, transactionType } = receiptData;

        if (transactionType === 1) {
            // Sell transaction
            const charges = agentCharges * exchangeRate || 0;
            const total = ((fromCurrValue || 0) - charges)
            const final = total * exchangeRate || 0
            return { charges, total, final };
        } else if (transactionType === 2) {
            // Buy transaction
            const charges = parseFloat(agentCharges || 0);
            const total = ((fromCurrValue || 0) - charges) 
            const final = total / exchangeRate || 0
            return { charges, total, final };
        }

        return { charges: 0, total: 0 };
    };

    const { charges, total, final } = calculateTotal();

    return (
        <Modal show={show} onHide={handleClose} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title className="text-center w-100"></Modal.Title>
            </Modal.Header>
            <Modal.Body id="modal-print-content">
                <div className="text-center mb-4">
                    <img src={logo} alt="Logo" className="img-fluid" style={{ maxHeight: '60px', maxWidth: '200px' }} />
                </div>
                <h3 className="text-center">Transaction Receipt</h3>
                <p><strong>Transaction ID:</strong> {receiptData.transactionId}</p>
                <p><strong>Transaction Date:</strong> {receiptData.date}</p>
                <p><strong>Branch Name:</strong> {receiptData.branch}</p>
                <p><strong>Customer Name:</strong> {receiptData.name}</p>
                <hr />

                <Container fluid className="justify-content-center ">
                    <div>
                        <h4 className="text-center">Currency Exchange</h4>
                        <Card className="mt-4 text-center" >
                            <Row className="mb-2">
                                <Col xs={6} md={4}>
                                    <strong>{receiptData.fromCurrType} Currency:</strong>
                                </Col>
                                <Col xs={6} md={8}>
                                    {receiptData.fromCurrValue}
                                </Col>
                            </Row>

                            {/* Charges */}
                            <Row className="mb-2">
                                <Col xs={6} md={4}>
                                    <strong>Charges:</strong>
                                </Col>
                                <Col xs={6} md={8}>
                                    <FontAwesomeIcon icon={faMinus} className="me-1" /> {(charges || 0).toFixed(2)}{" "}
                                    {receiptData?.fromCurrType || ""}
                                </Col>
                            </Row>
                            <hr />
                            {/* Total */}
                            <Row className="mb-2">
                                <Col xs={6} md={4}>
                                    <strong>Total:</strong>
                                </Col>
                                <Col xs={6} md={8}>
                                    <FontAwesomeIcon icon={faEquals} className="me-1" /> {(total || 0).toFixed(2)}{" "}
                                    {receiptData?.toCurrType || ""}
                                </Col>
                            </Row>

                            {/* Exchange Rate */}
                            <Row className="mb-2">
                                <Col xs={6} md={4}>
                                    <strong>Exchange Rate:</strong>
                                </Col>
                                <Col xs={6} md={8}>
                                    <FontAwesomeIcon
                                        icon={receiptData.transactionType === 1 ? faX : faDivide}
                                        className="me-1"
                                    />
                                    {receiptData.exchangeRate}
                                </Col>
                            </Row>
                            <hr />
                            {/* To Currency */}
                            <Row className="mb-2">
                                <Col xs={6} md={4}>
                                    <strong>{receiptData.toCurrType} Currency:</strong>
                                </Col>

                                <Col xs={6} md={8}>
                                    <FontAwesomeIcon icon={faEquals} className="me-1" /> {(final || 0).toFixed(2)}{" "}
                                    {receiptData?.toCurrType || ""}
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Container>



                <hr />
                <p className="text-center mt-5">Thank you for using Crescent Exchange</p>
                <div className="mt-5">
                    <Footer />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={generatePDF}><FaDownload /> PDF</Button>
                <Button variant="primary" onClick={handlePrint}>Print</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ReceiptModal;
